import React, { useEffect, useState } from "react";
import NameAndAddress from "./checkOutComponents/NameAndAddress";
import { check_mail, creatCartItem, signIn, signUp } from "../../../api/api";
import { useDispatch, useSelector } from "react-redux";
import { addToken, addUser } from "../../../features/User/userSlice";
import Cookies from "js-cookie";
import { useUserContext } from "./UserContext";
import { v4 as uuidv4 } from "uuid";
import { Link } from "react-router-dom";

const SignInRegisterSection = ({ nextPage }) => {
  const { userData, updateUserData } = useUserContext();

  const userCart = useSelector((state) => state?.basket?.basket);

  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    email: "",
    name: "",
    password: "",
  });

  const [formDataError, setFormDataError] = useState({
    email: "",
    name: "",
    password: "",
  });

  const [signInOrRegister, setSignInOrRegister] = useState("");

  const [signInError, setSignInError] = useState("");

  const [isLoggedInOrRegistered, setIsLoggedInOrRegistered] = useState(false);

  const handleSignInRegisterClick = async (e, val) => {
    e.preventDefault();
    let newErrors = {};

    if (!formData.email) {
      newErrors = {
        ...newErrors,
        email: "Please enter your email",
      };
    } else if (!/\S+@\S+\.\S+/.test(formData.email.trim())) {
      newErrors = {
        ...newErrors,
        email: "Please enter a valid email address",
      };
    } else {
      updateUserData({
        ...userData,
        signInOrRegister: val,
      });

      try {
        const response = await check_mail({ email: formData.email });
        if (response.data.message === "Email is already registered") {
          setSignInOrRegister("Sign In");
        } else if (response.data.message === "Email is not registered") {
          setSignInOrRegister("Register");
        }
      } catch (error) {
        console.log("error", error);
      }

      // if (val === "Register") {
      //   if (!formData.name) {
      //     newErrors = {
      //       ...newErrors,
      //       name: "Please enter your full name",
      //     };
      //   }
      //   if (!formData.password) {
      //     newErrors = {
      //       ...newErrors,
      //       password: "Please enter your password",
      //     };
      //   }
      // }

      // if (val === "Sign In") {
      //   newErrors = {
      //     ...newErrors,
      //     name: "",
      //   };
      //   if (!formData.password) {
      //     newErrors = {
      //       ...newErrors,
      //       password: "Please enter your password",
      //     };
      //   }
      // }
    }
    setFormDataError((prevErrors) => ({
      ...prevErrors,
      ...newErrors,
    }));

    if (formDataError.email === "" && formDataError.password === "") {
      if (signInOrRegister === "Sign In") {
        try {
          const response = await signIn({
            email: formData.email,
            password: formData.password,
          });
          if (
            response?.data?.user?.name !== "Admin" &&
            response?.data?.user?.name !== "admin"
          ) {
            dispatch(addUser(response?.data?.user));
            dispatch(addToken(response?.data?.token));
            Cookies.set("token", response?.data?.token);
            localStorage.setItem("user", JSON.stringify(response?.data?.user));
            setIsLoggedInOrRegistered(true);
            // nextPage();
          } else {
            setSignInError("Admin cannot log in.");
          }
        } catch (error) {
          console.log("error", error);
          setFormDataError({
            ...formDataError,
            password: "Please check your email and password.",
          });
        }
      }
      if (signInOrRegister === "Register") {
        if (formDataError.name === "") {
          try {
            const response = await signUp({
              email: formData.email,
              password: formData.password,
              name: formData.name,
            });
            dispatch(addUser(response?.data?.user));
            Cookies.set("token", response?.data?.access_token);
            const userToken = response?.data?.token;
            setIsLoggedInOrRegistered(true);

            try {
              const formData = new FormData();

              formData.append("uid", userCart[0]?.uid);
              formData.append(
                "variation_id",
                userCart[0]?.product_variation?.id
              );
              formData.append("item_price", userCart[0]?.item_price);
              formData.append("item_qty", "1");
              formData.append("delivery_date", userCart[0]?.delivery_date);

              const response = await creatCartItem(formData, userToken);
            } catch (error) {
              console.log("error", error);
            }

            if (response) {
              // nextPage();
            }
          } catch (error) {
            console.log("error", error);
          }
        }
      }
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    setFormDataError({
      ...formDataError,
      [name]: "",
    });

    // update conntext
    let newData = {
      ...userData,
      [name]: value,
    };
    // updateUserData(newData);
  };

  useEffect(() => {
    // setFormData({
    //   ...formData,
    //   email: userData?.email,
    //   name: userData?.name,
    //   password: userData?.password,
    // });
    // setSignInOrRegister(userData?.signInOrRegister);
  }, [userData]);
  return (
    <>
      <section id="details" class="F8D7tEzAQUW7EsIPEric">
        <div class="oHnwpRWvAoTpJxVhZDQV">
          {/* <div class="DYTlo07Z45T0dTGhQndh">
          <h4>Sign in or register to gain these benefits:</h4>
          <div class="SaMbPERn4V9VyRPMdnUG">
            <div class="ubem5KbpMkI0Dp7PxKr4">
              <img
                src="https://media.bunches.co.uk/icons/icon_PP_white.svg"
                alt="Posy Points"
              />
              <div class="T0TO7oxCX1LcaX_cEN4k">
                <p>Earn Posy Points</p>
              </div>
            </div>
            <div class="ubem5KbpMkI0Dp7PxKr4">
              <img
                src="https://media.bunches.co.uk/icons/icon__delivery.svg"
                alt="Address Book"
              />
              <div class="T0TO7oxCX1LcaX_cEN4k">
                <p>
                  Amend your <br />
                  orders online
                </p>
              </div>
            </div>
            <div class="ubem5KbpMkI0Dp7PxKr4">
              <img
                src="https://media.bunches.co.uk/icons/clock.svg"
                alt="Reminder Service"
              />
              <div class="T0TO7oxCX1LcaX_cEN4k">
                <p>
                  Create Reminders <br />
                  for Special Occasions
                </p>
              </div>
            </div>
          </div>
        </div> */}
          <div class="AsKTp6QdJseDhts4YzYm">
            <form onSubmit={(e) => handleSignInRegisterClick(e, "Sign In")}>
              <div class="VnBpn0GY4tQEXz8hKHDQ">
                <label for="email">Email</label>
                <input
                  id="email"
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                />
                {formDataError.email && (
                  <div style={{ color: "red" }} className="error">
                    {formDataError.email}
                  </div>
                )}
              </div>

              {signInOrRegister === "Register" && (
                <>
                  <div class="VnBpn0GY4tQEXz8hKHDQ">
                    <label for="password">Name</label>
                    <input
                      id="name"
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleInputChange}
                    />
                    {formDataError.name && (
                      <div style={{ color: "red" }} className="error">
                        {formDataError.name}
                      </div>
                    )}
                  </div>

                  <div className="VnBpn0GY4tQEXz8hKHDQ">
                    <label htmlFor="password">Password</label>
                    <input
                      id="password"
                      type="password"
                      name="password"
                      value={formData.password}
                      onChange={handleInputChange}
                    />
                    {formDataError.password && (
                      <div style={{ color: "red" }} className="error">
                        {formDataError.password}
                      </div>
                    )}
                  </div>
                </>
              )}

              {signInOrRegister === "Sign In" && (
                <div className="VnBpn0GY4tQEXz8hKHDQ">
                  <label htmlFor="password">Password</label>
                  <input
                    id="password"
                    type="password"
                    name="password"
                    value={formData.password}
                    onChange={handleInputChange}
                  />
                  {formDataError.password && (
                    <div style={{ color: "red" }} className="error">
                      {formDataError.password}
                    </div>
                  )}
                </div>
              )}
              {signInError && <div style={{ color: "red" }}>{signInError}</div>}
              <p class="text-center mb-3">
                <Link to="/forgot-password">Forgot password?</Link>
              </p>
              <div class="Wl8JvB7Efaj1yzPVhaXD">
                <button
                  onClick={(e) => handleSignInRegisterClick(e, "Sign In")}
                  type="button"
                  class="VRZl5T_cEakrM5LuPOBb"
                  value="1"
                >
                  Sign In
                </button>
                {/* <button
                  onClick={() => handleSignInRegisterClick("Register")}
                  type="button"
                  class="VRZl5T_cEakrM5LuPOBb"
                  value="2"
                >
                  Register
                </button> */}
              </div>
              {/* <div class="g51zAcKNQMIXn17VXjov">
                <div>Or</div>
              </div>
              <button
                class="e7QvJMPhPw6Pbciz9UHc YLxdcrrIEpFGSqqBDTXs"
                type="button"
                disabled=""
              >
                Email me a sign in link
              </button> */}
              <div class="n0ywmAu4ItA_sj7LzbYw">
                {/* <button
                class="e7QvJMPhPw6Pbciz9UHc YLxdcrrIEpFGSqqBDTXs"
                type="button"
              >
                <i class="fa fa-facebook-square"></i>
                <span>Sign in with Facebook</span>
              </button> */}
              </div>
              {/* <button
                  onClick={handleCheckOutAsGuest}
                  type="button"
                  class="iUzczyH0XvOxURb2xRgy"
                  value="3"
                >
                  Checkout as Guest
                </button> */}
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default SignInRegisterSection;
