import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

export const signIn = async (data) => {
  try {
    const response = await axios.post(`${apiUrl}/signin`, data);

    return response;
  } catch (error) {
    console.error("Error occurred during signIn API call:", error);
    throw error;
  }
};

export const signUp = async (data) => {
  try {
    const response = await axios.post(`${apiUrl}/signup`, data);
    return response;
  } catch (error) {
    console.error("Error occurred during signIn API call:", error);
    throw error;
  }
};

export const allCategories = async () => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${
          document.cookie
            .split("; ")
            ?.find((row) => row.startsWith("token"))
            ?.split("=")[1]
        }`,
      },
    };
    const response = await axios.get(`${apiUrl}/all_categories`);
    return response;
  } catch (error) {
    console.error("Error occurred during API call:", error);
    throw error;
  }
};

export const allProducts = async () => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${
          document.cookie
            .split("; ")
            ?.find((row) => row.startsWith("token"))
            ?.split("=")[1]
        }`,
      },
    };
    const response = await axios.get(`${apiUrl}/all_products`);
    return response;
  } catch (error) {
    console.error("Error occurred during API call:", error);
    throw error;
  }
};

export const getProductsById = async (id) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${
          document.cookie
            .split("; ")
            ?.find((row) => row.startsWith("token"))
            ?.split("=")[1]
        }`,
      },
    };
    const response = await axios.get(`${apiUrl}/show_product/${id}`);
    return response;
  } catch (error) {
    console.error("Error occurred during createCategories API call:", error);
    throw error;
  }
};

export const getProductsByCategory = async (id) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${
          document.cookie
            .split("; ")
            ?.find((row) => row.startsWith("token"))
            ?.split("=")[1]
        }`,
      },
    };
    const response = await axios.get(`${apiUrl}/products_by_cat/${id}`);
    return response;
  } catch (error) {
    console.error("Error occurred during createCategories API call:", error);
    throw error;
  }
};

export const AllCartItems = async () => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${
          document.cookie
            .split("; ")
            ?.find((row) => row.startsWith("token"))
            ?.split("=")[1]
        }`,
      },
    };
    const response = await axios.get(`${apiUrl}/user/cart`, config);
    return response;
  } catch (error) {
    console.error("Error occurred during createCategories API call:", error);
    throw error;
  }
};

export const creatCartItem = async (data, userToken) => {
  let tokenToSend;
  if (userToken) {
    tokenToSend = `Bearer ${userToken}`;
  } else {
    tokenToSend = `Bearer ${
      document.cookie
        .split("; ")
        ?.find((row) => row.startsWith("token"))
        ?.split("=")[1]
    }`;
  }

  try {
    const config = {
      headers: {
        Authorization: tokenToSend,
      },
    };
    const response = await axios.post(`${apiUrl}/user/cart`, data, config);
    return response;
  } catch (error) {
    console.error("Error occurred during createCategories API call:", error);
    throw error;
  }
};

export const deleteCartItem = async (id) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${
          document.cookie
            .split("; ")
            ?.find((row) => row.startsWith("token"))
            ?.split("=")[1]
        }`,
      },
    };
    const response = await axios.delete(`${apiUrl}/user/cart/${id}`, config);
    return response;
  } catch (error) {
    console.error("Error occurred during createCategories API call:", error);
    throw error;
  }
};

export const searchProducts = async (data) => {
  try {
    const response = await axios.post(`${apiUrl}/search_products`, data);
    return response;
  } catch (error) {
    console.error("Error occurred during createCategories API call:", error);
    throw error;
  }
};

export const storeMultipleItemsToBasket = async (data) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${
          document.cookie
            .split("; ")
            ?.find((row) => row.startsWith("token"))
            ?.split("=")[1]
        }`,
      },
    };
    const response = await axios.post(
      `${apiUrl}/user/store_multiple_items`,
      data,
      config
    );
    return response;
  } catch (error) {
    console.error("Error occurred during createCategories API call:", error);
    throw error;
  }
};

export const RemoveMultipleItemsToBasket = async (data) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${
          document.cookie
            .split("; ")
            ?.find((row) => row.startsWith("token"))
            ?.split("=")[1]
        }`,
      },
    };
    const response = await axios.post(
      `${apiUrl}/user/delete_multiple_items`,
      data,
      config
    );
    return response;
  } catch (error) {
    console.error("Error occurred during createCategories API call:", error);
    throw error;
  }
};

export const getAllOrders = async () => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${
          document.cookie
            .split("; ")
            ?.find((row) => row.startsWith("token"))
            ?.split("=")[1]
        }`,
      },
    };
    const response = await axios.get(`${apiUrl}/user/order`, config);
    return response;
  } catch (error) {
    console.error("Error occurred during createCategories API call:", error);
    throw error;
  }
};

export const changePassword = async (data) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${
          document.cookie
            .split("; ")
            ?.find((row) => row.startsWith("token"))
            ?.split("=")[1]
        }`,
      },
    };
    const response = await axios.post(
      `${apiUrl}/change-password`,
      data,
      config
    );
    return response;
  } catch (error) {
    console.error("Error occurred during createCategories API call:", error);
    throw error;
  }
};

export const updateProfileData = async (data) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${
          document.cookie
            .split("; ")
            ?.find((row) => row.startsWith("token"))
            ?.split("=")[1]
        }`,
      },
    };
    const response = await axios.post(
      `${apiUrl}/update_profile_data`,
      data,
      config
    );
    return response;
  } catch (error) {
    console.error("Error occurred during createCategories API call:", error);
    throw error;
  }
};

export const checkCoupon = async (data) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${
          document.cookie
            .split("; ")
            ?.find((row) => row.startsWith("token"))
            ?.split("=")[1]
        }`,
      },
    };
    const response = await axios.post(`${apiUrl}/check_coupon`, data, config);
    return response;
  } catch (error) {
    console.error("Error occurred during createCategories API call:", error);
    throw error;
  }
};

export const createOrder = async (data) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${
          document.cookie
            .split("; ")
            ?.find((row) => row.startsWith("token"))
            ?.split("=")[1]
        }`,
      },
    };
    const response = await axios.post(`${apiUrl}/user/order`, data, config);
    return response;
  } catch (error) {
    console.error("Error occurred during createCategories API call:", error);
    throw error;
  }
};

export const chargePayment = async (data) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${
          document.cookie
            .split("; ")
            ?.find((row) => row.startsWith("token"))
            ?.split("=")[1]
        }`,
      },
    };
    const response = await axios.post(`${apiUrl}/charge_payment`, data, config);
    return response;
  } catch (error) {
    console.error("Error occurred during createCategories API call:", error);
    throw error;
  }
};

export const change_delivery_date_by_order_id = async (data) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${
          document.cookie
            .split("; ")
            ?.find((row) => row.startsWith("token"))
            ?.split("=")[1]
        }`,
      },
    };
    const response = await axios.post(
      `${apiUrl}/user/change_delivery_date`,
      data,
      config
    );
    return response;
  } catch (error) {
    console.error("Error occurred during createCategories API call:", error);
    throw error;
  }
};

export const change_delivery_date_by_cart_id = async (data) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${
          document.cookie
            .split("; ")
            ?.find((row) => row.startsWith("token"))
            ?.split("=")[1]
        }`,
      },
    };
    const response = await axios.post(
      `${apiUrl}/user/change_delivery_date_by_cart`,
      data,
      config
    );
    return response;
  } catch (error) {
    console.error("Error occurred during createCategories API call:", error);
    throw error;
  }
};

export const getSingleOrderById = async (id) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${
          document.cookie
            .split("; ")
            ?.find((row) => row.startsWith("token"))
            ?.split("=")[1]
        }`,
      },
    };
    const response = await axios.get(`${apiUrl}/user/order/${id}`, config);
    return response;
  } catch (error) {
    console.error("Error occurred during createCategories API call:", error);
    throw error;
  }
};

export const change_message_by_order_id = async (data) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${
          document.cookie
            .split("; ")
            ?.find((row) => row.startsWith("token"))
            ?.split("=")[1]
        }`,
      },
    };
    const response = await axios.post(
      `${apiUrl}/user/change_message`,
      data,
      config
    );
    return response;
  } catch (error) {
    console.error("Error occurred during createCategories API call:", error);
    throw error;
  }
};

export const change_recipient_info_by_order_id = async (data) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${
          document.cookie
            .split("; ")
            ?.find((row) => row.startsWith("token"))
            ?.split("=")[1]
        }`,
      },
    };
    const response = await axios.post(
      `${apiUrl}/user/change_recipient_info`,
      data,
      config
    );
    return response;
  } catch (error) {
    console.error("Error occurred during createCategories API call:", error);
    throw error;
  }
};

export const cancel_order = async (data) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${
          document.cookie
            .split("; ")
            ?.find((row) => row.startsWith("token"))
            ?.split("=")[1]
        }`,
      },
    };
    const response = await axios.post(
      `${apiUrl}/user/cancel_order`,
      data,
      config
    );
    return response;
  } catch (error) {
    console.error("Error occurred during createCategories API call:", error);
    throw error;
  }
};

export const profile_data = async () => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${
          document.cookie
            .split("; ")
            ?.find((row) => row.startsWith("token"))
            ?.split("=")[1]
        }`,
      },
    };
    const response = await axios.get(`${apiUrl}/profile_data`, config);
    return response;
  } catch (error) {
    console.error("Error occurred during createCategories API call:", error);
    throw error;
  }
};

export const random_products = async () => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${
          document.cookie
            .split("; ")
            ?.find((row) => row.startsWith("token"))
            ?.split("=")[1]
        }`,
      },
    };
    const response = await axios.get(`${apiUrl}/random_products`, config);
    return response;
  } catch (error) {
    console.error("Error occurred during createCategories API call:", error);
    throw error;
  }
};

export const next_delivery_date = async () => {
  try {
    const response = await axios.get(`${apiUrl}/next_delivery_date`);
    return response;
  } catch (error) {
    console.error("Error occurred during createCategories API call:", error);
    throw error;
  }
};

export const subscribe_form = async (data) => {
  try {
    const response = await axios.post(`${apiUrl}/subscribe_form`, data);
    return response;
  } catch (error) {
    console.error("Error occurred during createCategories API call:", error);
    throw error;
  }
};

export const contact_form = async (data) => {
  try {
    const response = await axios.post(`${apiUrl}/contact_form`, data);
    return response;
  } catch (error) {
    console.error("Error occurred during createCategories API call:", error);
    throw error;
  }
};

export const home_slider = async () => {
  try {
    const response = await axios.get(`${apiUrl}/home_slider`);
    return response;
  } catch (error) {
    console.error("Error occurred during createCategories API call:", error);
    throw error;
  }
};

export const vat_tax = async () => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${
          document.cookie
            .split("; ")
            ?.find((row) => row.startsWith("token"))
            ?.split("=")[1]
        }`,
      },
    };
    const response = await axios.get(`${apiUrl}/vat_tax`, config);
    return response;
  } catch (error) {
    console.error("Error occurred during createCategories API call:", error);
    throw error;
  }
};

export const shipping_charges = async () => {
  try {
    const response = await axios.get(`${apiUrl}/shipping_charges`);
    return response;
  } catch (error) {
    console.error("Error occurred during createCategories API call:", error);
    throw error;
  }
};

export const check_mail = async (data) => {
  try {
    const response = await axios.post(`${apiUrl}/check_mail`, data);
    return response;
  } catch (error) {
    console.error("Error occurred during createCategories API call:", error);
    throw error;
  }
};

export const sendForgetMail = async (data) => {
  try {
    const response = await axios.post(`${apiUrl}/send-forget-mail`, data);
    return response;
  } catch (error) {
    console.error("Error occurred during createCategories API call:", error);
    throw error;
  }
};

export const verifyOtp = async (data) => {
  try {
    const response = await axios.post(`${apiUrl}/verify-otp`, data);
    return response;
  } catch (error) {
    console.error("Error occurred during createCategories API call:", error);
    throw error;
  }
};

export const updatePassword = async (data) => {
  try {
    const response = await axios.post(`${apiUrl}/update-password`, data);
    return response;
  } catch (error) {
    console.error("Error occurred during createCategories API call:", error);
    throw error;
  }
};
