import React, { useEffect, useRef, useState } from "react";
import ProductSingleItem from "./ProductSingleItem";
import { useSelector } from "react-redux";

const Product = ({ categoryId }) => {
  const productState = useSelector((state) => state.products);
  const category = useSelector((state) => state.category);
  const [categoryIds, setCategoryIds] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);

  const sliderRef = useRef(null);
  let startX;
  let scrollLeft;

  useEffect(() => {
    if (productState && categoryIds) {
      const filtered = productState?.products?.filter((product) => {
        return product.product_categories.some((cat) =>
          categoryIds.includes(cat.category_id)
        );
      });
      setFilteredProducts(filtered);
    }
  }, [productState, categoryIds]);

  useEffect(() => {
    if (category && categoryId) {
      const filteredCategory = category?.category?.filter((cat) => {
        return (
          Number(cat?.parent_category?.id) === Number(categoryId) ||
          Number(cat?.id) === Number(categoryId)
        );
      });
      setCategoryIds(filteredCategory.map((cat) => cat.id));
    }
  }, [category, categoryId]);

  const handleMouseDown = (e) => {
    e.preventDefault();
    startX = e.pageX - sliderRef.current.offsetLeft;
    scrollLeft = sliderRef.current.scrollLeft;
    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseup", handleMouseUp);
  };

  const handleMouseMove = (e) => {
    e.preventDefault();
    const x = e.pageX - sliderRef.current.offsetLeft;
    const walk = (x - startX) * 1.5;
    sliderRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleMouseUp = () => {
    window.removeEventListener("mousemove", handleMouseMove);
    window.removeEventListener("mouseup", handleMouseUp);
  };

  return (
    <div
      className="items"
      style={{ overflowX: "auto" }}
      onMouseDown={handleMouseDown}
      ref={sliderRef}
    >
      {filteredProducts?.length > 0 &&
        filteredProducts.map((product) => {
          return <ProductSingleItem product={product} />;
        })}

      {/* <ProductSingleItem />
      <ProductSingleItem />
      <ProductSingleItem />
      <ProductSingleItem />
      <ProductSingleItem />
      <ProductSingleItem />
      <ProductSingleItem />
      <ProductSingleItem />
      <ProductSingleItem />
      <ProductSingleItem />
      <ProductSingleItem />
      <ProductSingleItem /> */}
    </div>
  );
};

export default Product;
