import React from "react";
import CheckOutCard from "../CheckOutCard";
import SelectedCardFromCardModalSettings from "../CardSettings/SelectedCardFromCardModalSettings";

const Card = ({
  toggleModal,
  nextPage,
  sendTextEditorTextToParent,
  onUserDataChange,
}) => {
  const handleContinueCardClick = () => {};
  return (
    <>
      {/* <section id="card" class="F8D7tEzAQUW7EsIPEric">
        <div class="uFVyqFA2q0wui8oMMOdo">
          <p class="l9jIoVk6WeGo4A7i7L_f">
            With every gift we enclose your message on a small free card.
            For an
            extra special touch you can upgrade to a premium greetings card from
            just £2.25, simply select a delightful design below.
          </p>
          <div class="E4hIbmavMUWH4WAzB2Lk">
            <div class="UxExtkYnHDIF6cIz_f_r">
              <CheckOutCard toggleModal={toggleModal} />
              <CheckOutCard toggleModal={toggleModal} />
              <CheckOutCard toggleModal={toggleModal} />
              <CheckOutCard toggleModal={toggleModal} />
              <CheckOutCard toggleModal={toggleModal} />
              <CheckOutCard toggleModal={toggleModal} />
              <CheckOutCard toggleModal={toggleModal} />
              <CheckOutCard toggleModal={toggleModal} />
              <CheckOutCard toggleModal={toggleModal} />
              <CheckOutCard toggleModal={toggleModal} />
              <CheckOutCard toggleModal={toggleModal} />
              <CheckOutCard toggleModal={toggleModal} />
            </div>
          </div>
          <button class="VRZl5T_cEakrM5LuPOBb YLxdcrrIEpFGSqqBDTXs">
            Choose a free or premium card
          </button>
        </div>
      </section> */}
      <div class="uFVyqFA2q0wui8oMMOdo">
        <p class="l9jIoVk6WeGo4A7i7L_f">
          With every gift we enclose your message on a small free card.
        </p>
      </div>
      {
        <SelectedCardFromCardModalSettings
          nextPage={nextPage}
          sendTextEditorTextToParent={sendTextEditorTextToParent}
          onUserDataChange={onUserDataChange}
        />
      }
    </>
  );
};

export default Card;
