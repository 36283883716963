import React, { useEffect, useState } from "react";
import "./CheckOutView.css";
import { useDispatch, useSelector } from "react-redux";
import { toggleBasket } from "../../../features/menuBar/menubarSlice";
import integrityLogo from "../../../assets/logos/vite.png";
import SignInRegisterSection from "./SignInRegisterSection";
import DeliveryFinalSection from "./DeliveryFinalSection";
import CheckOntProducts from "./CheckOutProducts";
import CheckOutCard from "./CheckOutCard";
import CheckOutCardModal from "./CheckOutCardModal";
import CheckOutProductsModal from "./CheckOutProductsModal";
import SelectedCardFromCardModal from "./CardSettings/SelectedCardFromCardModalSettings";
import { addBasket } from "../../../features/basket/basket";
import Gift from "./checkOutComponents/Gift";
import Card from "./checkOutComponents/Card";
import NameAndAddress from "./checkOutComponents/NameAndAddress";
import { UserProvider } from "./UserContext";
import Cookies from "js-cookie";

const CheckoutView = () => {
  const token = Cookies.get("token");
  const basketCount = useSelector((state) => state?.basket?.basketCount);
  const [currentPage, setCurrentPage] = useState(1);
  const [isClickedCard, setIsClickedCard] = useState(false);
  const [isClickedProduct, setIsClickedProduct] = useState(false);

  const [userToken, setUserToken] = useState(null);

  const basketState = useSelector((state) => state?.basket?.basket);

  const dispatch = useDispatch();
  const handleToggleBasket = () => {
    dispatch(toggleBasket());
  };

  useEffect(() => {
    setUserToken(token);
  }, [token]);

  const [basketTotalPayment, setBasketTotalPayment] = useState();

  const [checkOutUserData, setCheckOutUserData] = useState(null);
  // useEffect(() => {
  //   if (basketState.length === 0 || basketState === null) {
  //     const userCart = localStorage.getItem("user-cart");
  //     if (userCart) {
  //       dispatch(addBasket(JSON.parse(userCart)));
  //     }
  //   }
  // }, [basketState]);

  useEffect(() => {
    if (!basketState) return;
    let count = 0;
    basketState.map((item) => {
      count = count + item?.item_price;
    });
    setBasketTotalPayment(count);
  }, [basketState]);

  const toggleModal = () => {
    setIsClickedCard(!isClickedCard);
  };

  const toggleProductModal = () => {
    setIsClickedProduct(!isClickedProduct);
  };

  const nextPage = (val) => {
    setCurrentPage(currentPage + 1);
  };

  const sendTextEditorTextToParent = () => {};

  const handleUserDataChange = (name, value) => {
    let userData = {};

    userData = {
      ...userData,
      [name]: value,
    };

    setCheckOutUserData(userData);
  };

  return (
    <main id="main-container" class="container fluid-container">
      <CheckOutProductsModal
        isClickedProduct={isClickedProduct}
        toggleProductModal={toggleProductModal}
      />
      <CheckOutCardModal
        isClickedCard={isClickedCard}
        toggleModal={toggleModal}
      />
      <div class="nav-blackout"></div>
      <div class="esiT130a4JX0J5OF7gig">
        <div class="container dFjppR914o8D9lt5tmI2">
          <a class="lq5mwQ2mDBNSFR55r_ya" href="/">
            <img
              style={{ position: "relative", top: "7px" }}
              src={integrityLogo}
              alt="Bunches' logo"
            />
          </a>
          <div class="ipC3vq6Y8H8Ug_O0XCme">
            {userToken === undefined ? (
              <>
                {currentPage === 1 && <h1>Add a card</h1>}
                {currentPage === 2 && <h1>login</h1>}
                {currentPage === 3 && <h1>Delivery Details</h1>}
                {currentPage === 4 && <h1>Checkout</h1>}
              </>
            ) : (
              <>
                {currentPage === 1 && <h1>Add a card</h1>}
                {currentPage === 2 && <h1>Delivery Details</h1>}
                {currentPage === 3 && <h1>Checkout</h1>}
              </>
            )}

            <div
              class="CiYvZuAUZ90ZSrKEL88o"
              role="progressbar"
              aria-valuenow="2"
            >
              <div class="iSINcJN0KpluPgJeV_54 UUpWKLAeP8wEwzRWxACv">
                <div
                  onClick={() => setCurrentPage(1)}
                  class="NWL0kb3yAsN1z8r46_Qa"
                  aria-current="false"
                >
                  1
                </div>
              </div>
              <div
                class={`iSINcJN0KpluPgJeV_54 ${
                  currentPage >= 2 ? "UUpWKLAeP8wEwzRWxACv" : ""
                }`}
              >
                <div
                  onClick={() => setCurrentPage(2)}
                  class="NWL0kb3yAsN1z8r46_Qa"
                  aria-current="true"
                >
                  2
                </div>
              </div>
              <div
                class={`iSINcJN0KpluPgJeV_54 ${
                  currentPage >= 3 ? "UUpWKLAeP8wEwzRWxACv" : ""
                }`}
              >
                <div
                  onClick={() => setCurrentPage(3)}
                  class={`NWL0kb3yAsN1z8r46_Qa`}
                  aria-current="false"
                >
                  3
                </div>
              </div>
              {userToken === undefined && (
                <div
                  class={`iSINcJN0KpluPgJeV_54 ${
                    currentPage >= 4 ? "UUpWKLAeP8wEwzRWxACv" : ""
                  }`}
                >
                  <div
                    onClick={() => setCurrentPage(4)}
                    class="NWL0kb3yAsN1z8r46_Qa"
                    aria-current="false"
                  >
                    4
                  </div>
                </div>
              )}
            </div>
          </div>
          <div class="mWGmYTPxubz0jCaJTMuy fbVRd81EtT1J5uKU81Pm">
            {/* <img
              onClick={handleToggleBasket}
              src="https://media.bunches.co.uk/products/586x586/fpkcr-1.jpg"
              width="54"
              height="54"
              alt="Basket"
            /> */}
            <svg
              width="30"
              height="35"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              aria-label="Basket Icon"
            >
              <path
                d="M7.00008 1.16675C7.92834 1.16675 8.81858 1.5355 9.47496 2.19187C10.1313 2.84825 10.5001 3.73849 10.5001 4.66675V5.25008H12.8334V6.41675H12.1527L11.7111 11.7152C11.6989 11.8609 11.6325 11.9968 11.5249 12.0959C11.4173 12.195 11.2764 12.25 11.1301 12.2501H2.87008C2.72381 12.25 2.5829 12.195 2.47529 12.0959C2.36768 11.9968 2.30122 11.8609 2.28908 11.7152L1.84691 6.41675H1.16675V5.25008H3.50008V4.66675C3.50008 3.73849 3.86883 2.84825 4.52521 2.19187C5.18158 1.5355 6.07182 1.16675 7.00008 1.16675ZM7.58341 7.58342H6.41675V9.91675H7.58341V7.58342ZM5.25008 7.58342H4.08341V9.91675H5.25008V7.58342ZM9.91675 7.58342H8.75008V9.91675H9.91675V7.58342ZM7.00008 2.33341C6.40144 2.33341 5.8257 2.5635 5.39194 2.97609C4.95818 3.38867 4.6996 3.95219 4.66966 4.55008L4.66675 4.66675V5.25008H9.33341V4.66675C9.33342 4.06811 9.10333 3.49237 8.69074 3.05861C8.27816 2.62485 7.71464 2.36626 7.11675 2.33633L7.00008 2.33341Z"
                fill="#596267"
              ></path>
            </svg>
            <div onClick={handleToggleBasket} class="xYOW0PJz9qRgS06GAwgR">
              {/* <svg
                width="15"
                height="15"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                aria-label="Basket Icon"
              >
                <path
                  d="M7.00008 1.16675C7.92834 1.16675 8.81858 1.5355 9.47496 2.19187C10.1313 2.84825 10.5001 3.73849 10.5001 4.66675V5.25008H12.8334V6.41675H12.1527L11.7111 11.7152C11.6989 11.8609 11.6325 11.9968 11.5249 12.0959C11.4173 12.195 11.2764 12.25 11.1301 12.2501H2.87008C2.72381 12.25 2.5829 12.195 2.47529 12.0959C2.36768 11.9968 2.30122 11.8609 2.28908 11.7152L1.84691 6.41675H1.16675V5.25008H3.50008V4.66675C3.50008 3.73849 3.86883 2.84825 4.52521 2.19187C5.18158 1.5355 6.07182 1.16675 7.00008 1.16675ZM7.58341 7.58342H6.41675V9.91675H7.58341V7.58342ZM5.25008 7.58342H4.08341V9.91675H5.25008V7.58342ZM9.91675 7.58342H8.75008V9.91675H9.91675V7.58342ZM7.00008 2.33341C6.40144 2.33341 5.8257 2.5635 5.39194 2.97609C4.95818 3.38867 4.6996 3.95219 4.66966 4.55008L4.66675 4.66675V5.25008H9.33341V4.66675C9.33342 4.06811 9.10333 3.49237 8.69074 3.05861C8.27816 2.62485 7.71464 2.36626 7.11675 2.33633L7.00008 2.33341Z"
                  fill="#596267"
                ></path>
              </svg> */}
              <p>{basketCount}</p>
            </div>
          </div>
        </div>
      </div>
      <UserProvider>
        {userToken === undefined ? (
          <div class="container">
            {currentPage === 1 && (
              <Card
                toggleModal={toggleModal}
                nextPage={nextPage}
                sendTextEditorTextToParent={sendTextEditorTextToParent}
                onUserDataChange={handleUserDataChange}
              />
            )}
            {currentPage === 2 && <SignInRegisterSection nextPage={nextPage} />}
            {currentPage === 3 && <NameAndAddress nextPage={nextPage} />}
            {currentPage === 4 && (
              <DeliveryFinalSection
                basketTotalPayment={basketTotalPayment}
                setCurrentPage={setCurrentPage}
                userToken={userToken}
              />
            )}
          </div>
        ) : (
          <div class="container">
            {currentPage === 1 && (
              <Card
                toggleModal={toggleModal}
                nextPage={nextPage}
                sendTextEditorTextToParent={sendTextEditorTextToParent}
                onUserDataChange={handleUserDataChange}
              />
            )}
            {currentPage === 2 && <NameAndAddress nextPage={nextPage} />}
            {currentPage === 3 && (
              <DeliveryFinalSection
                basketTotalPayment={basketTotalPayment}
                setCurrentPage={setCurrentPage}
                userToken={userToken}
              />
            )}
          </div>
        )}
      </UserProvider>
      {/* <div class="nNqqvogSvFbvG377pZv3" aria-hidden="true">
        <div class="vtCxq60cLjEt7M10Ce19">
          <div class="x24XZ1iRzHSpivGgbxyw" role="alert">
            You have successfully signed in.
          </div>
          <div class="lO095Dqnj2g4I9mjey4B"></div>
        </div>
      </div> */}
    </main>
  );
};

export default CheckoutView;

// old content

// <div class="container">
//             {/* {currentPage === 1 && (
//           <Gift
//             setCurrentPage={setCurrentPage}
//             toggleProductModal={toggleProductModal}
//           />
//         )} */}
//             {currentPage === 1 && (
//               <Card
//                 toggleModal={toggleModal}
//                 nextPage={nextPage}
//                 sendTextEditorTextToParent={sendTextEditorTextToParent}
//                 onUserDataChange={handleUserDataChange}
//               />
//             )}

//             {/* <section id="message" class="F8D7tEzAQUW7EsIPEric">
//           <div class="d6R_oyo9dU87mkxDKc8p">
//             <div class="QQrPOAbvEa3jsuHKml7v">
//               <div class="CcWg2etX6ebbML0Jn5n9">
//                 <p class="a7IvPmdksZvWIOI4oYyS">
//                   Please select a free or premium card
//                 </p>
//                 <p>
//                   Every order is sent with a card, please click the button below
//                   to select a card to personalise it. If you'd prefer not to
//                   write a message, you can leave the message space blank on the
//                   next step.
//                 </p>
//               </div>
//             </div>
//             <button class="VRZl5T_cEakrM5LuPOBb YLxdcrrIEpFGSqqBDTXs">
//               Select a Card
//             </button>
//           </div>
//         </section> */}
//             {currentPage === 2 && <SignInRegisterSection nextPage={nextPage} />}
//             {currentPage === 3 && <NameAndAddress nextPage={nextPage} />}

//             {currentPage === 4 && (
//               <DeliveryFinalSection
//                 basketTotalPayment={basketTotalPayment}
//                 setCurrentPage={setCurrentPage}
//               />
//             )}
//           </div>
