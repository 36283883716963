import React, { useEffect, useState } from "react";
import CategoryFilter from "../CategoryFilter";
import Product from "../CategoryFlowers/Product";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

const SubCategoryFlowers = () => {
  const categoryName = useSelector((state) => state.category.clickedCategory);
  const category = useSelector((state) => state?.category?.category);
  const products = useSelector((state) => state?.products.products);
  const [categoryIds, setCategoryIds] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState(null);
  const [isInfo, setIsInfo] = useState(true);
  const { id } = useParams();

  useEffect(() => {
    if (products && categoryIds) {
      const filtered = products.filter((product) => {
        return product.product_categories.some((cat) =>
          categoryIds.includes(cat.category_id)
        );
      });

      // sort default low to high
      const sortedProducts = filtered.slice().sort((a, b) => {
        const priceA = parseFloat(a?.product_variation[0]?.variation_price);
        const priceB = parseFloat(b?.product_variation[0]?.variation_price);

        if (!isNaN(priceA) && !isNaN(priceB)) {
          return priceA - priceB;
        } else {
          return 0;
        }
      });
      setFilteredProducts(sortedProducts);

      // setFilteredProducts(filtered);
    }
  }, [products, categoryIds]);

  useEffect(() => {
    if (category && id) {
      const filteredCategory = category.filter((cat) => {
        return (
          Number(cat?.parent_category?.id) === Number(id) ||
          Number(cat?.id) === Number(id)
        );
      });
      setCategoryIds(filteredCategory.map((cat) => cat.id));
    }
  }, [category, id]);

  const handleInfoClick = () => {
    setIsInfo(!isInfo);
  };

  const handleFilterChange = (e) => {
    if (e.target.value === "priceasc") {
      const sortedProducts = filteredProducts.slice().sort((a, b) => {
        const priceA = parseFloat(a?.product_variation[0]?.variation_price);
        const priceB = parseFloat(b?.product_variation[0]?.variation_price);

        if (!isNaN(priceA) && !isNaN(priceB)) {
          return priceA - priceB;
        } else {
          return 0;
        }
      });
      setFilteredProducts(sortedProducts);
    } else if (e.target.value === "pricedesc") {
      const sortedProducts = filteredProducts.slice().sort((a, b) => {
        const priceA = parseFloat(a?.product_variation[0]?.variation_price);
        const priceB = parseFloat(b?.product_variation[0]?.variation_price);

        if (!isNaN(priceA) && !isNaN(priceB)) {
          return priceB - priceA;
        } else {
          return 0;
        }
      });
      setFilteredProducts(sortedProducts);
    } else if (e.target.value === "nameasc") {
      const sortedProducts = filteredProducts.slice().sort((a, b) => {
        const nameA = a?.product_name.toLowerCase();
        const nameB = b?.product_name.toLowerCase();
        return nameA.localeCompare(nameB);
      });
      setFilteredProducts(sortedProducts);
    } else if (e.target.value === "namedesc") {
      const sortedProducts = filteredProducts.slice().sort((a, b) => {
        const nameA = a?.product_name.toLowerCase();
        const nameB = b?.product_name.toLowerCase();
        return nameB.localeCompare(nameA);
      });
      setFilteredProducts(sortedProducts);
    }
  };

  return (
    <main id="main-container" class="container top-margin fluid-container">
      <div class="nav-blackout"></div>
      <div class="container category-container">
        <div class="row">
          <div class="col-12">
            <ul class="breadcrumb">
              <li>
                <a href="/">Home</a>
              </li>
              <li>Flowers</li>
            </ul>
          </div>
        </div>
        <div class="row no-gutters">
          {/* <CategoryFilter /> */}
          {/* col-lg-10       removed class form bellow div with filter */}
          <div class="col-12 right-panel">
            <div class="title-and-description">
              <h2 onClick={handleInfoClick} class="title">
                Flowers
                <i class="description-info">{isInfo ? "Less" : "More"} info</i>
              </h2>
              {isInfo && (
                <div class="description">
                  Shop Integrity Flowers' entire selection of flower
                  arrangements & gifts for delivery. From traditional rose
                  bouquets to modern arrangements, plants to hampers, our
                  offering of gifts is truly unmatched.
                </div>
              )}
            </div>
            <div class="row no-gutters mobile-sort-and-filters hidden-lg-up">
              <div class="col-6 pr-1">
                <select
                  aria-label="Sort by"
                  class="mobile-sort-by sort-options"
                >
                  <option value="priceasc">Price (Low to High)</option>
                  <option value="pricedesc">Price (High to Low)</option>
                  <option value="nameasc">Name: A to Z</option>
                  <option value="namedesc">Name: Z to A</option>
                </select>
              </div>
              <div class="col-6 pl-1">
                <div class="mobile-filters" role="button">
                  Filter By
                </div>
              </div>
            </div>
            <div class="selected-filters hidden-lg-up"></div>
            <div class="count-and-sorting">
              <div class="product-count">{filteredProducts?.length} items</div>
              <div class="product-sorting hidden-md-down">
                <span>Sort by: </span>
                <div class="bn-select__wrapper--sm">
                  <div class="bn-select__caret">
                    <i class="fa fa-angle-down"></i>
                  </div>
                  <select onClick={handleFilterChange} aria-label="Sort by">
                    <option value="priceasc">Price (Low to High)</option>
                    <option value="pricedesc">Price (High to Low)</option>
                    <option value="nameasc">Name: A to Z</option>
                    <option value="namedesc">Name: Z to A</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="products">
              {filteredProducts &&
                filteredProducts.map((product) => {
                  return <Product product={product} />;
                })}
            </div>
          </div>
        </div>
        <div class="hidden-lg-up">
          <div class="filters-overlay" role="dialog" aria-label="Attribute">
            <div class="content">
              <div class="filters-header sticky-top">
                <div class="filters-title">Attribute</div>
                <div class="filters-clear">Clear All</div>
                <div class="filters-close">Done</div>
              </div>
              <div class="attributes">
                <div class="filter-parent">
                  <div class="filter-top-level available-soonest active">
                    <div class="option">
                      <span class="label">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="none"
                        >
                          <path
                            fill="#596267"
                            stroke="#fff"
                            strokeWidth=".5"
                            d="M4.256 2.75h.25V.937c0-.37.315-.687.732-.687s.732.317.732.688V2.75h6.393V.937c0-.37.315-.687.732-.687s.732.317.732.688V2.75h1.887c1.318 0 2.37 1.02 2.37 2.25v12.5c0 1.23-1.052 2.25-2.37 2.25H2.62C1.301 19.75.25 18.73.25 17.5V5c0-1.23 1.051-2.25 2.369-2.25h1.637Zm-2.292 4.5h-.25V17.5c0 .493.418.875.905.875h13.095c.487 0 .905-.382.905-.875V7.25H1.964Z"
                          ></path>
                        </svg>
                        Available Soonest
                      </span>
                      <span hidden="">
                        <i
                          class="fa fa-times"
                          aria-label="Deselect available soonest"
                        ></i>
                      </span>
                    </div>
                  </div>
                </div>
                <div class="filter-parent">
                  <div class="filter-top-level">
                    Flower Type<i class="fa fa-angle-down"></i>
                  </div>
                  <div class="filters">
                    <div class="filter-children" hidden="">
                      <div
                        class="filter"
                        role="button"
                        aria-label="Alstroemeria"
                      >
                        <input
                          id="attribute-Alstroemeria"
                          type="checkbox"
                          class="filter-attribute"
                        />
                        <label
                          for="attribute-Alstroemeria"
                          class="filter-attribute-label"
                        >
                          <span>
                            <i class="fa fa-times hidden-lg-up"></i>
                          </span>
                          <p>Alstroemeria</p>
                          <i class="fa fa-times hidden-md-down"></i>
                        </label>
                      </div>
                      <div class="filter" role="button" aria-label="Aster">
                        <input
                          id="attribute-Aster"
                          type="checkbox"
                          class="filter-attribute"
                        />
                        <label
                          for="attribute-Aster"
                          class="filter-attribute-label"
                        >
                          <span>
                            <i class="fa fa-times hidden-lg-up"></i>
                          </span>
                          <p>Aster</p>
                          <i class="fa fa-times hidden-md-down"></i>
                        </label>
                      </div>
                      <div
                        class="filter"
                        role="button"
                        aria-label="Calla Lilies"
                      >
                        <input
                          id="attribute-Calla Lilies"
                          type="checkbox"
                          class="filter-attribute"
                        />
                        <label
                          for="attribute-Calla Lilies"
                          class="filter-attribute-label"
                        >
                          <span>
                            <i class="fa fa-times hidden-lg-up"></i>
                          </span>
                          <p>Calla Lilies</p>
                          <i class="fa fa-times hidden-md-down"></i>
                        </label>
                      </div>
                      <div class="filter" role="button" aria-label="Carnations">
                        <input
                          id="attribute-Carnations"
                          type="checkbox"
                          class="filter-attribute"
                        />
                        <label
                          for="attribute-Carnations"
                          class="filter-attribute-label"
                        >
                          <span>
                            <i class="fa fa-times hidden-lg-up"></i>
                          </span>
                          <p>Carnations</p>
                          <i class="fa fa-times hidden-md-down"></i>
                        </label>
                      </div>
                      <div
                        class="filter"
                        role="button"
                        aria-label="Chrysanthemums"
                      >
                        <input
                          id="attribute-Chrysanthemums"
                          type="checkbox"
                          class="filter-attribute"
                        />
                        <label
                          for="attribute-Chrysanthemums"
                          class="filter-attribute-label"
                        >
                          <span>
                            <i class="fa fa-times hidden-lg-up"></i>
                          </span>
                          <p>Chrysanthemums</p>
                          <i class="fa fa-times hidden-md-down"></i>
                        </label>
                      </div>
                      <div class="filter" role="button" aria-label="Eucalyptus">
                        <input
                          id="attribute-Eucalyptus"
                          type="checkbox"
                          class="filter-attribute"
                        />
                        <label
                          for="attribute-Eucalyptus"
                          class="filter-attribute-label"
                        >
                          <span>
                            <i class="fa fa-times hidden-lg-up"></i>
                          </span>
                          <p>Eucalyptus</p>
                          <i class="fa fa-times hidden-md-down"></i>
                        </label>
                      </div>
                      <div class="filter" role="button" aria-label="Freesias">
                        <input
                          id="attribute-Freesias"
                          type="checkbox"
                          class="filter-attribute"
                        />
                        <label
                          for="attribute-Freesias"
                          class="filter-attribute-label"
                        >
                          <span>
                            <i class="fa fa-times hidden-lg-up"></i>
                          </span>
                          <p>Freesias</p>
                          <i class="fa fa-times hidden-md-down"></i>
                        </label>
                      </div>
                      <div class="filter" role="button" aria-label="Germini">
                        <input
                          id="attribute-Germini"
                          type="checkbox"
                          class="filter-attribute"
                        />
                        <label
                          for="attribute-Germini"
                          class="filter-attribute-label"
                        >
                          <span>
                            <i class="fa fa-times hidden-lg-up"></i>
                          </span>
                          <p>Germini</p>
                          <i class="fa fa-times hidden-md-down"></i>
                        </label>
                      </div>
                      <div class="filter" role="button" aria-label="Gypsophila">
                        <input
                          id="attribute-Gypsophila"
                          type="checkbox"
                          class="filter-attribute"
                        />
                        <label
                          for="attribute-Gypsophila"
                          class="filter-attribute-label"
                        >
                          <span>
                            <i class="fa fa-times hidden-lg-up"></i>
                          </span>
                          <p>Gypsophila</p>
                          <i class="fa fa-times hidden-md-down"></i>
                        </label>
                      </div>
                      <div class="filter" role="button" aria-label="Iris">
                        <input
                          id="attribute-Iris"
                          type="checkbox"
                          class="filter-attribute"
                        />
                        <label
                          for="attribute-Iris"
                          class="filter-attribute-label"
                        >
                          <span>
                            <i class="fa fa-times hidden-lg-up"></i>
                          </span>
                          <p>Iris</p>
                          <i class="fa fa-times hidden-md-down"></i>
                        </label>
                      </div>
                      <div class="filter" role="button" aria-label="Lilies">
                        <input
                          id="attribute-Lilies"
                          type="checkbox"
                          class="filter-attribute"
                        />
                        <label
                          for="attribute-Lilies"
                          class="filter-attribute-label"
                        >
                          <span>
                            <i class="fa fa-times hidden-lg-up"></i>
                          </span>
                          <p>Lilies</p>
                          <i class="fa fa-times hidden-md-down"></i>
                        </label>
                      </div>
                      <div class="filter" role="button" aria-label="Lisianthus">
                        <input
                          id="attribute-Lisianthus"
                          type="checkbox"
                          class="filter-attribute"
                        />
                        <label
                          for="attribute-Lisianthus"
                          class="filter-attribute-label"
                        >
                          <span>
                            <i class="fa fa-times hidden-lg-up"></i>
                          </span>
                          <p>Lisianthus</p>
                          <i class="fa fa-times hidden-md-down"></i>
                        </label>
                      </div>
                      <div class="filter" role="button" aria-label="Orchids">
                        <input
                          id="attribute-Orchids"
                          type="checkbox"
                          class="filter-attribute"
                        />
                        <label
                          for="attribute-Orchids"
                          class="filter-attribute-label"
                        >
                          <span>
                            <i class="fa fa-times hidden-lg-up"></i>
                          </span>
                          <p>Orchids</p>
                          <i class="fa fa-times hidden-md-down"></i>
                        </label>
                      </div>
                      <div class="filter" role="button" aria-label="Phlox">
                        <input
                          id="attribute-Phlox"
                          type="checkbox"
                          class="filter-attribute"
                        />
                        <label
                          for="attribute-Phlox"
                          class="filter-attribute-label"
                        >
                          <span>
                            <i class="fa fa-times hidden-lg-up"></i>
                          </span>
                          <p>Phlox</p>
                          <i class="fa fa-times hidden-md-down"></i>
                        </label>
                      </div>
                      <div class="filter" role="button" aria-label="Roses">
                        <input
                          id="attribute-Roses"
                          type="checkbox"
                          class="filter-attribute"
                        />
                        <label
                          for="attribute-Roses"
                          class="filter-attribute-label"
                        >
                          <span>
                            <i class="fa fa-times hidden-lg-up"></i>
                          </span>
                          <p>Roses</p>
                          <i class="fa fa-times hidden-md-down"></i>
                        </label>
                      </div>
                      <div class="filter" role="button" aria-label="Solidago">
                        <input
                          id="attribute-Solidago"
                          type="checkbox"
                          class="filter-attribute"
                        />
                        <label
                          for="attribute-Solidago"
                          class="filter-attribute-label"
                        >
                          <span>
                            <i class="fa fa-times hidden-lg-up"></i>
                          </span>
                          <p>Solidago</p>
                          <i class="fa fa-times hidden-md-down"></i>
                        </label>
                      </div>
                      <div
                        class="filter"
                        role="button"
                        aria-label="Spray Carnations"
                      >
                        <input
                          id="attribute-Spray Carnations"
                          type="checkbox"
                          class="filter-attribute"
                        />
                        <label
                          for="attribute-Spray Carnations"
                          class="filter-attribute-label"
                        >
                          <span>
                            <i class="fa fa-times hidden-lg-up"></i>
                          </span>
                          <p>Spray Carnations</p>
                          <i class="fa fa-times hidden-md-down"></i>
                        </label>
                      </div>
                      <div class="filter" role="button" aria-label="Stocks">
                        <input
                          id="attribute-Stocks"
                          type="checkbox"
                          class="filter-attribute"
                        />
                        <label
                          for="attribute-Stocks"
                          class="filter-attribute-label"
                        >
                          <span>
                            <i class="fa fa-times hidden-lg-up"></i>
                          </span>
                          <p>Stocks</p>
                          <i class="fa fa-times hidden-md-down"></i>
                        </label>
                      </div>
                      <div
                        class="filter"
                        role="button"
                        aria-label="Sweet William"
                      >
                        <input
                          id="attribute-Sweet William"
                          type="checkbox"
                          class="filter-attribute"
                        />
                        <label
                          for="attribute-Sweet William"
                          class="filter-attribute-label"
                        >
                          <span>
                            <i class="fa fa-times hidden-lg-up"></i>
                          </span>
                          <p>Sweet William</p>
                          <i class="fa fa-times hidden-md-down"></i>
                        </label>
                      </div>
                      <div class="filter" role="button" aria-label="Tulips">
                        <input
                          id="attribute-Tulips"
                          type="checkbox"
                          class="filter-attribute"
                        />
                        <label
                          for="attribute-Tulips"
                          class="filter-attribute-label"
                        >
                          <span>
                            <i class="fa fa-times hidden-lg-up"></i>
                          </span>
                          <p>Tulips</p>
                          <i class="fa fa-times hidden-md-down"></i>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="filter-parent">
                  <div class="filter-top-level">
                    Occasion<i class="fa fa-angle-down"></i>
                  </div>
                  <div class="filters">
                    <div class="filter-children" hidden="">
                      <div
                        class="filter"
                        role="button"
                        aria-label="Anniversary"
                      >
                        <input
                          id="attribute-Anniversary"
                          type="checkbox"
                          class="filter-attribute"
                        />
                        <label
                          for="attribute-Anniversary"
                          class="filter-attribute-label"
                        >
                          <span>
                            <i class="fa fa-times hidden-lg-up"></i>
                          </span>
                          <p>Anniversary</p>
                          <i class="fa fa-times hidden-md-down"></i>
                        </label>
                      </div>
                      <div class="filter" role="button" aria-label="Birthday">
                        <input
                          id="attribute-Birthday"
                          type="checkbox"
                          class="filter-attribute"
                        />
                        <label
                          for="attribute-Birthday"
                          class="filter-attribute-label"
                        >
                          <span>
                            <i class="fa fa-times hidden-lg-up"></i>
                          </span>
                          <p>Birthday</p>
                          <i class="fa fa-times hidden-md-down"></i>
                        </label>
                      </div>
                      <div
                        class="filter"
                        role="button"
                        aria-label="Congratulations"
                      >
                        <input
                          id="attribute-Congratulations"
                          type="checkbox"
                          class="filter-attribute"
                        />
                        <label
                          for="attribute-Congratulations"
                          class="filter-attribute-label"
                        >
                          <span>
                            <i class="fa fa-times hidden-lg-up"></i>
                          </span>
                          <p>Congratulations</p>
                          <i class="fa fa-times hidden-md-down"></i>
                        </label>
                      </div>
                      <div class="filter" role="button" aria-label="Easter">
                        <input
                          id="attribute-Easter"
                          type="checkbox"
                          class="filter-attribute"
                        />
                        <label
                          for="attribute-Easter"
                          class="filter-attribute-label"
                        >
                          <span>
                            <i class="fa fa-times hidden-lg-up"></i>
                          </span>
                          <p>Easter</p>
                          <i class="fa fa-times hidden-md-down"></i>
                        </label>
                      </div>
                      <div class="filter" role="button" aria-label="Engagement">
                        <input
                          id="attribute-Engagement"
                          type="checkbox"
                          class="filter-attribute"
                        />
                        <label
                          for="attribute-Engagement"
                          class="filter-attribute-label"
                        >
                          <span>
                            <i class="fa fa-times hidden-lg-up"></i>
                          </span>
                          <p>Engagement</p>
                          <i class="fa fa-times hidden-md-down"></i>
                        </label>
                      </div>
                      <div
                        class="filter"
                        role="button"
                        aria-label="Get Well Soon"
                      >
                        <input
                          id="attribute-Get Well Soon"
                          type="checkbox"
                          class="filter-attribute"
                        />
                        <label
                          for="attribute-Get Well Soon"
                          class="filter-attribute-label"
                        >
                          <span>
                            <i class="fa fa-times hidden-lg-up"></i>
                          </span>
                          <p>Get Well Soon</p>
                          <i class="fa fa-times hidden-md-down"></i>
                        </label>
                      </div>
                      <div class="filter" role="button" aria-label="Graduation">
                        <input
                          id="attribute-Graduation"
                          type="checkbox"
                          class="filter-attribute"
                        />
                        <label
                          for="attribute-Graduation"
                          class="filter-attribute-label"
                        >
                          <span>
                            <i class="fa fa-times hidden-lg-up"></i>
                          </span>
                          <p>Graduation</p>
                          <i class="fa fa-times hidden-md-down"></i>
                        </label>
                      </div>
                      <div
                        class="filter"
                        role="button"
                        aria-label="Just Because"
                      >
                        <input
                          id="attribute-Just Because"
                          type="checkbox"
                          class="filter-attribute"
                        />
                        <label
                          for="attribute-Just Because"
                          class="filter-attribute-label"
                        >
                          <span>
                            <i class="fa fa-times hidden-lg-up"></i>
                          </span>
                          <p>Just Because</p>
                          <i class="fa fa-times hidden-md-down"></i>
                        </label>
                      </div>
                      <div
                        class="filter"
                        role="button"
                        aria-label="Mother's Day"
                      >
                        <input
                          id="attribute-Mother's Day"
                          type="checkbox"
                          class="filter-attribute"
                        />
                        <label
                          for="attribute-Mother's Day"
                          class="filter-attribute-label"
                        >
                          <span>
                            <i class="fa fa-times hidden-lg-up"></i>
                          </span>
                          <p>Mother's Day</p>
                          <i class="fa fa-times hidden-md-down"></i>
                        </label>
                      </div>
                      <div class="filter" role="button" aria-label="New Baby">
                        <input
                          id="attribute-New Baby"
                          type="checkbox"
                          class="filter-attribute"
                        />
                        <label
                          for="attribute-New Baby"
                          class="filter-attribute-label"
                        >
                          <span>
                            <i class="fa fa-times hidden-lg-up"></i>
                          </span>
                          <p>New Baby</p>
                          <i class="fa fa-times hidden-md-down"></i>
                        </label>
                      </div>
                      <div class="filter" role="button" aria-label="New Home">
                        <input
                          id="attribute-New Home"
                          type="checkbox"
                          class="filter-attribute"
                        />
                        <label
                          for="attribute-New Home"
                          class="filter-attribute-label"
                        >
                          <span>
                            <i class="fa fa-times hidden-lg-up"></i>
                          </span>
                          <p>New Home</p>
                          <i class="fa fa-times hidden-md-down"></i>
                        </label>
                      </div>
                      <div class="filter" role="button" aria-label="Retirement">
                        <input
                          id="attribute-Retirement"
                          type="checkbox"
                          class="filter-attribute"
                        />
                        <label
                          for="attribute-Retirement"
                          class="filter-attribute-label"
                        >
                          <span>
                            <i class="fa fa-times hidden-lg-up"></i>
                          </span>
                          <p>Retirement</p>
                          <i class="fa fa-times hidden-md-down"></i>
                        </label>
                      </div>
                      <div class="filter" role="button" aria-label="Romantic">
                        <input
                          id="attribute-Romantic"
                          type="checkbox"
                          class="filter-attribute"
                        />
                        <label
                          for="attribute-Romantic"
                          class="filter-attribute-label"
                        >
                          <span>
                            <i class="fa fa-times hidden-lg-up"></i>
                          </span>
                          <p>Romantic</p>
                          <i class="fa fa-times hidden-md-down"></i>
                        </label>
                      </div>
                      <div class="filter" role="button" aria-label="Sorry">
                        <input
                          id="attribute-Sorry"
                          type="checkbox"
                          class="filter-attribute"
                        />
                        <label
                          for="attribute-Sorry"
                          class="filter-attribute-label"
                        >
                          <span>
                            <i class="fa fa-times hidden-lg-up"></i>
                          </span>
                          <p>Sorry</p>
                          <i class="fa fa-times hidden-md-down"></i>
                        </label>
                      </div>
                      <div class="filter" role="button" aria-label="Spring">
                        <input
                          id="attribute-Spring"
                          type="checkbox"
                          class="filter-attribute"
                        />
                        <label
                          for="attribute-Spring"
                          class="filter-attribute-label"
                        >
                          <span>
                            <i class="fa fa-times hidden-lg-up"></i>
                          </span>
                          <p>Spring</p>
                          <i class="fa fa-times hidden-md-down"></i>
                        </label>
                      </div>
                      <div class="filter" role="button" aria-label="Summer">
                        <input
                          id="attribute-Summer"
                          type="checkbox"
                          class="filter-attribute"
                        />
                        <label
                          for="attribute-Summer"
                          class="filter-attribute-label"
                        >
                          <span>
                            <i class="fa fa-times hidden-lg-up"></i>
                          </span>
                          <p>Summer</p>
                          <i class="fa fa-times hidden-md-down"></i>
                        </label>
                      </div>
                      <div class="filter" role="button" aria-label="Sympathy">
                        <input
                          id="attribute-Sympathy"
                          type="checkbox"
                          class="filter-attribute"
                        />
                        <label
                          for="attribute-Sympathy"
                          class="filter-attribute-label"
                        >
                          <span>
                            <i class="fa fa-times hidden-lg-up"></i>
                          </span>
                          <p>Sympathy</p>
                          <i class="fa fa-times hidden-md-down"></i>
                        </label>
                      </div>
                      <div class="filter" role="button" aria-label="Thank You">
                        <input
                          id="attribute-Thank You"
                          type="checkbox"
                          class="filter-attribute"
                        />
                        <label
                          for="attribute-Thank You"
                          class="filter-attribute-label"
                        >
                          <span>
                            <i class="fa fa-times hidden-lg-up"></i>
                          </span>
                          <p>Thank You</p>
                          <i class="fa fa-times hidden-md-down"></i>
                        </label>
                      </div>
                      <div
                        class="filter"
                        role="button"
                        aria-label="Valentine's Day"
                      >
                        <input
                          id="attribute-Valentine's Day"
                          type="checkbox"
                          class="filter-attribute"
                        />
                        <label
                          for="attribute-Valentine's Day"
                          class="filter-attribute-label"
                        >
                          <span>
                            <i class="fa fa-times hidden-lg-up"></i>
                          </span>
                          <p>Valentine's Day</p>
                          <i class="fa fa-times hidden-md-down"></i>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="filter-parent">
                  <div class="filter-top-level">
                    Colour<i class="fa fa-angle-down"></i>
                  </div>
                  <div class="filters">
                    <div class="filter-children has-swatches" hidden="">
                      <div class="filter" role="button" aria-label="Blue">
                        <input
                          id="attribute-Blue"
                          type="checkbox"
                          class="filter-attribute"
                        />
                        <label
                          for="attribute-Blue"
                          class="filter-attribute-label"
                        >
                          <span>
                            <i class="fa fa-times hidden-lg-up"></i>
                          </span>
                          <i class="fa fa-times hidden-md-down"></i>
                        </label>
                      </div>
                      <div
                        class="filter"
                        role="button"
                        aria-label="Cream"
                        style={{ background: "rgb(247, 243, 233)" }}
                      >
                        <input
                          id="attribute-Cream"
                          type="checkbox"
                          class="filter-attribute"
                        />
                        <label
                          for="attribute-Cream"
                          class="filter-attribute-label"
                        >
                          <span>
                            <i class="fa fa-times hidden-lg-up"></i>
                          </span>
                          <i class="fa fa-times hidden-md-down"></i>
                        </label>
                      </div>
                      <div
                        class="filter"
                        role="button"
                        aria-label="Mixed"
                        style={{
                          background:
                            "linear-gradient(0.9turn, rgb(248, 110, 110), rgb(188, 168, 219))",
                        }}
                      >
                        <input
                          id="attribute-Mixed"
                          type="checkbox"
                          class="filter-attribute"
                        />
                        <label
                          for="attribute-Mixed"
                          class="filter-attribute-label"
                        >
                          <span>
                            <i class="fa fa-times hidden-lg-up"></i>
                          </span>
                          <i class="fa fa-times hidden-md-down"></i>
                        </label>
                      </div>
                      <div
                        class="filter"
                        role="button"
                        aria-label="Orange"
                        style={{ background: "rgb(255, 221, 175)" }}
                      >
                        <input
                          id="attribute-Orange"
                          type="checkbox"
                          class="filter-attribute"
                        />
                        <label
                          for="attribute-Orange"
                          class="filter-attribute-label"
                        >
                          <span>
                            <i class="fa fa-times hidden-lg-up"></i>
                          </span>
                          <i class="fa fa-times hidden-md-down"></i>
                        </label>
                      </div>
                      <div
                        class="filter"
                        role="button"
                        aria-label="Pink"
                        style={{ background: "rgb(252, 234, 234)" }}
                      >
                        <input
                          id="attribute-Pink"
                          type="checkbox"
                          class="filter-attribute"
                        />
                        <label
                          for="attribute-Pink"
                          class="filter-attribute-label"
                        >
                          <span>
                            <i class="fa fa-times hidden-lg-up"></i>
                          </span>
                          <i class="fa fa-times hidden-md-down"></i>
                        </label>
                      </div>
                      <div
                        class="filter"
                        role="button"
                        aria-label="Purple"
                        style={{ background: "rgb(188, 168, 219)" }}
                      >
                        <input
                          id="attribute-Purple"
                          type="checkbox"
                          class="filter-attribute"
                        />
                        <label
                          for="attribute-Purple"
                          class="filter-attribute-label"
                        >
                          <span>
                            <i class="fa fa-times hidden-lg-up"></i>
                          </span>
                          <i class="fa fa-times hidden-md-down"></i>
                        </label>
                      </div>
                      <div
                        class="filter"
                        role="button"
                        aria-label="Red"
                        style={{ background: "rgb(248, 110, 110)" }}
                      >
                        <input
                          id="attribute-Red"
                          type="checkbox"
                          class="filter-attribute"
                        />
                        <label
                          for="attribute-Red"
                          class="filter-attribute-label"
                        >
                          <span>
                            <i class="fa fa-times hidden-lg-up"></i>
                          </span>
                          <i class="fa fa-times hidden-md-down"></i>
                        </label>
                      </div>
                      <div
                        class="filter"
                        role="button"
                        aria-label="White"
                        style={{
                          background: "rgb(255, 255, 255)",
                        }}
                      >
                        <input
                          id="attribute-White"
                          type="checkbox"
                          class="filter-attribute"
                        />
                        <label
                          for="attribute-White"
                          class="filter-attribute-label"
                        >
                          <span>
                            <i class="fa fa-times hidden-lg-up"></i>
                          </span>
                          <i class="fa fa-times hidden-md-down"></i>
                        </label>
                      </div>
                      <div
                        class="filter"
                        role="button"
                        aria-label="Yellow"
                        style={{
                          background: "rgb(255, 245, 201)",
                        }}
                      >
                        <input
                          id="attribute-Yellow"
                          type="checkbox"
                          class="filter-attribute"
                        />
                        <label
                          for="attribute-Yellow"
                          class="filter-attribute-label"
                        >
                          <span>
                            <i class="fa fa-times hidden-lg-up"></i>
                          </span>
                          <i class="fa fa-times hidden-md-down"></i>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="filter-parent">
                  <div class="filter-top-level">
                    Style<i class="fa fa-angle-down"></i>
                  </div>
                  <div class="filters">
                    <div class="filter-children" hidden="">
                      <div
                        class="filter"
                        role="button"
                        aria-label="Dried Flowers"
                      >
                        <input
                          id="attribute-Dried Flowers"
                          type="checkbox"
                          class="filter-attribute"
                        />
                        <label
                          for="attribute-Dried Flowers"
                          class="filter-attribute-label"
                        >
                          <span>
                            <i class="fa fa-times hidden-lg-up"></i>
                          </span>
                          <p>Dried Flowers</p>
                          <i class="fa fa-times hidden-md-down"></i>
                        </label>
                      </div>
                      <div
                        class="filter"
                        role="button"
                        aria-label="Flower Gift Sets"
                      >
                        <input
                          id="attribute-Flower Gift Sets"
                          type="checkbox"
                          class="filter-attribute"
                        />
                        <label
                          for="attribute-Flower Gift Sets"
                          class="filter-attribute-label"
                        >
                          <span>
                            <i class="fa fa-times hidden-lg-up"></i>
                          </span>
                          <p>Flower Gift Sets</p>
                          <i class="fa fa-times hidden-md-down"></i>
                        </label>
                      </div>
                      <div class="filter" role="button" aria-label="Hand Tied">
                        <input
                          id="attribute-Hand Tied"
                          type="checkbox"
                          class="filter-attribute"
                        />
                        <label
                          for="attribute-Hand Tied"
                          class="filter-attribute-label"
                        >
                          <span>
                            <i class="fa fa-times hidden-lg-up"></i>
                          </span>
                          <p>Hand Tied</p>
                          <i class="fa fa-times hidden-md-down"></i>
                        </label>
                      </div>
                      <div
                        class="filter"
                        role="button"
                        aria-label="Letterbox Flowers"
                      >
                        <input
                          id="attribute-Letterbox Flowers"
                          type="checkbox"
                          class="filter-attribute"
                        />
                        <label
                          for="attribute-Letterbox Flowers"
                          class="filter-attribute-label"
                        >
                          <span>
                            <i class="fa fa-times hidden-lg-up"></i>
                          </span>
                          <p>Letterbox Flowers</p>
                          <i class="fa fa-times hidden-md-down"></i>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default SubCategoryFlowers;
