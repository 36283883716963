import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import Cookies from "js-cookie";
import { Link, useNavigate } from "react-router-dom";

const Account = () => {
  const navigate = useNavigate();
  const userData = localStorage.getItem("user");
  const user = JSON.parse(userData);

  useEffect(() => {
    isLogin();
  }, []);

  const isLogin = () => {
    const token = Cookies.get("token");
    if (!token) {
      navigate("/login");
    }
  };

  return (
    <main id="main-container" class="container top-margin">
      <div class="nav-blackout"></div>
      <div class="account-page">
        <div class="row">
          <div class="col-12">
            <div class="row">
              <div class="col-12">
                <ul class="breadcrumb">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>Your Account</li>
                </ul>
              </div>
            </div>
            <div class="header-title">Welcome {user?.name}</div>
            {/* <div class="recent-orders">
              <div class="border-box">
                <div class="orders">
                  <h2 class="heading">Recent Orders</h2>
                  <div class="content">
                    <div class="item">
                      <div class="section">
                        <h3 class="header">Order Number</h3>
                        <span>
                          <Link to="/account/order-details/12346738">
                            12346738
                          </Link>
                        </span>
                      </div>
                      <div class="section">
                        <h3 class="header">Order Date</h3>
                        <span>11th April 2024</span>
                      </div>
                      <div class="section large-column">
                        <h3 class="header">Recipient</h3>
                        <span>Koonj Parekh</span>
                      </div>
                      <div class="section small-column">
                        <h3 class="header">Total</h3>
                        <span>£20.87</span>
                      </div>
                      <div class="section with-buttons">
                        <span>
                          <Link
                            class="bn-button view-button bn-button--secondary"
                            role="button"
                            to="/account/order-details/12346738"
                          >
                            Edit
                          </Link>
                        </span>
                        <span class="show-breakdown"></span>
                      </div>
                    </div>
                    <div
                      class="subscription-sub-menu"
                      hidden=""
                      aria-hidden="true"
                      aria-expanded="false"
                    ></div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
        <div class="information">
          {/* <div class="posy-points">
            <img
              src="https://media.bunches.co.uk/posy-points-icon.png"
              alt="Posy Points icon"
            />
            <p>
              You currently have 0 Posy Points™ in your account worth a total of
              £0.00 off your next order!
            </p>
          </div> */}
          <p class="welcome-text">
            Use the menu below to navigate the features available relating to
            your account and don't forget to log out when you have finished
            shopping, especially when using a shared computer.
          </p>
        </div>
        <div class="row">
          <div class="col-12 col-sm-6 account-links">
            <div class="row">
              <div class="col-2 text-center">
                <Link to="/account/order-history">
                  <i class="fa fa-shopping-basket fa-2x"></i>
                </Link>
              </div>
              <div class="col-10">
                <Link class="link" to="/account/order-history">
                  Order History
                </Link>
                <p>View the details of your previous orders</p>
              </div>
            </div>
          </div>
          <div class="refer-friend">
            {/* <div class="referral-banner">
              <img
                src="https://media.bunches.co.uk/content_pages/rafbanner.png"
                alt="Image of smiling woman holding flowers"
              />
              <div class="right-column">
                <p>
                  <span class="bold">Give £5, get £5</span> when you refer a
                  friend
                </p>
                <span>
                  <button class="bn-button referral-button">
                    Find out more
                  </button>
                </span>
              </div>
            </div> */}
          </div>
          <div class="col-12 col-sm-6 account-links">
            <div class="row">
              <div class="col-2 text-center">
                <Link to="/account/change-password">
                  <i class="fa fa-key fa-2x"></i>
                </Link>
              </div>
              <div class="col-10">
                <Link class="link" to="/account/change-password">
                  Change Your Password
                </Link>
                <p>Change your account password here</p>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-6 account-links">
            <div class="row">
              <div class="col-2 text-center">
                <Link to="/account/edit-details">
                  <i class="fa fa-user fa-2x"></i>
                </Link>
              </div>
              <div class="col-10">
                <Link class="link" to="/account/edit-details">
                  Edit Your Details
                </Link>
                <p>Update your personal details</p>
              </div>
            </div>
          </div>
          {/* <div class="col-12 col-sm-6 account-links">
            <div class="row">
              <div class="col-2 text-center">
                <Link to="/account/reminder-service">
                  <i class="fa fa-calendar fa-2x"></i>
                </Link>
              </div>
              <div class="col-10">
                <Link class="link" to="/account/reminder-service">
                  Reminder Service
                </Link>
                <p>Never forget an important occasion again</p>
              </div>
            </div>
          </div> */}
          {/* <div class="col-12 col-sm-6 account-links">
            <div class="row">
              <div class="col-2 text-center">
                <Link to="/account/address-book">
                  <i class="fa fa-address-book fa-2x"></i>
                </Link>
              </div>
              <div class="col-10">
                <Link class="link" to="/account/address-book">
                  Address Book
                </Link>
                <p>Manage your delivery addresses</p>
              </div>
            </div>
          </div> */}
          {/* <div class="col-12 col-sm-6 account-links">
            <div class="row">
              <div class="col-2 text-center">
                <Link to="/account/payment-methods">
                  <i class="fa fa-credit-card fa-2x"></i>
                </Link>
              </div>
              <div class="col-10">
                <Link class="link" to="/account/payment-methods">
                  Manage Your Payment Methods
                </Link>
                <p>Manage your saved payment methods</p>
              </div>
            </div>
          </div> */}
          {/* <div class="col-12 col-sm-6 account-links">
            <div class="row">
              <div class="col-2 text-center">
                <Link to="/account/signin-methods">
                  <i class="fa fa-sign-in fa-2x"></i>
                </Link>
              </div>
              <div class="col-10">
                <Link class="link" to="/account/signin-methods">
                  Your Sign-In Methods
                </Link>
                <p>Manage how you sign in to your account</p>
              </div>
            </div>
          </div> */}
          <div class="col-12 col-sm-6 account-links">
            <div class="row">
              <div class="col-2 text-center">
                <Link to="/contact-us">
                  <i class="fa fa-phone fa-2x"></i>
                </Link>
              </div>
              <div class="col-10">
                <Link class="link" to="/contact-us">
                  Contact Us
                </Link>
                <p>Do you have a question or suggestion?</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Account;
