import { configureStore } from "@reduxjs/toolkit";
import menubarReducer from "../features/menuBar/menubarSlice";
import categoryReducer from "../features/Category/CategorySlice";
import productsReducer from "../features/Products/ProductSlice";
import BasketReducer from "../features/basket/basket";
import useReducer from "../features/User/userSlice";
import searchReducer from "../features/search/searchSlice";
import couponReducer from "../features/checkout/CouponSlice";
import checkOutReducer from "../features/checkout/checkOutSlice";

export const store = configureStore({
  reducer: {
    user: useReducer,
    menubar: menubarReducer,
    category: categoryReducer,
    products: productsReducer,
    basket: BasketReducer,
    search: searchReducer,
    coupon: couponReducer,
    checkout: checkOutReducer,
  },
});
