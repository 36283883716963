import React from "react";

const PrivacyPolicy = () => {
  return (
    <main id="main-container" class="container top-margin">
      <div class="nav-blackout"></div>
      <main class="row static-page about-us-page">
        <section class="col-md-12">
          <div role="tablist" aria-multiselectable="true">
            <article class="card" hidden="">
              <header class="card-header" role="tab">
                <h2 class="card-title">Privacy Policy</h2>
              </header>
              <div hidden="">
                <div class="card-block" role="tabpanel">
                  <p style={{ fontSize: "20px" }}>
                    <span style={{ fontWeight: "bold" }}>
                      Integrity Flowers
                    </span>{" "}
                    are committed to protecting your privacy. We will only use
                    the information that we collect about you lawfully (in
                    accordance with the Data Protection Act 1998 & subsequent
                    revisions thereof). We collect information about you for two
                    reasons: to process your order and to provide you with the
                    best possible service.
                  </p>
                  <p style={{ fontSize: "20px" }}>
                    We will not e-mail you in the future unless you have given
                    us your consent (except to confirm orders or ask for a
                    review).
                  </p>
                  <p style={{ fontSize: "20px" }}>
                    We will give you the chance to refuse any marketing e-mail
                    from us.
                  </p>
                  <p style={{ fontSize: "20px" }}>
                    <p>
                      The type of information we will collect about you
                      includes:
                    </p>
                    <ul>
                      <li>They must be above 18 to be employed</li>
                      <li>Social security coverage is guaranteed</li>
                      <li>They are paid above the local minimum wage</li>
                      <li>Women's rights are protected</li>
                    </ul>
                  </p>
                  <p style={{ fontSize: "20px" }}>
                    We will never collect sensitive information about you
                    without your explicit consent.
                  </p>
                  <p style={{ fontSize: "20px" }}>
                    The information we hold will be accurate and up to date. It
                    is possible to obtain a copy of the information that we hold
                    about you. If you find any inaccuracies we will delete or
                    correct it promptly. Should you require a copy of such
                    information, please write to us at:
                  </p>

                  <p style={{ fontSize: "20px", fontWeight: "bold" }}>
                    Integrity Flowers
                  </p>
                  <p style={{ fontSize: "20px" }}>
                    163 Western Road London UB2 5HP
                  </p>
                  <p style={{ fontSize: "20px" }}>
                    The personal information which we hold will be held securely
                    in accordance with our internal security policy and English
                    law.
                  </p>
                  <p style={{ fontSize: "20px" }}>
                    We may use technology to track the patterns of behaviour of
                    visitors to our site. This can include using a "cookie"
                    which would be stored on your browser. You can usually
                    modify your browser to prevent this happening (for example,
                    using Internet Explorer select 'internet options' from the
                    'tools' menu, then click 'security', followed by 'custom
                    level' and select 'disable cookies').
                  </p>
                  <p style={{ fontSize: "20px" }}>
                    The information collected in this way can be used to
                    identify you unless you modify your browser settings.
                  </p>
                  <p style={{ fontSize: "20px" }}>
                    Cookies are also used as a further means of ensuring private
                    and secure purchase sessions are operated within the site.
                  </p>
                  <p style={{ fontSize: "20px" }}>
                    Every time you connect to our website we store a log of your
                    visit which shows the unique number your machine uses when
                    it is connected to the internet (IP address). This tells us
                    what you looked at; whether the page request was successful
                    or not and which browser you use to view the pages. The use
                    of this data is strictly for statistical purposes only. This
                    helps us to understand which areas of the site are of
                    particular interest and also which pages are not being
                    requested. It also tells how many people are visiting the
                    website in total.
                  </p>
                  <p style={{ fontSize: "20px" }}>
                    We will use your personal data for the following purposes:
                  </p>
                  <p style={{ fontSize: "20px" }}>To fulfil your order:</p>
                  <p style={{ fontSize: "20px" }}>
                    To process payment including payment authorisation via
                    payment card, cheque, gift card or other third party payment
                    processor
                  </p>
                  <p style={{ fontSize: "20px" }}>
                    To carry out identity verification and fraud prevention
                    checks when purchasing with a payment card and validating
                    personal data you provide via a third party payment
                    processor
                  </p>
                  <p style={{ fontSize: "20px" }}>
                    To provide data to any third party supplier or vendor who
                    may fulfil your order on our behalf
                  </p>
                  <p style={{ fontSize: "20px" }}>
                    To provide order confirmation, delivery status notifications
                    on any order placed with us
                  </p>
                  <p style={{ fontSize: "20px", marginTop: "20px" }}>
                    For customer care purposes:
                  </p>
                  <p style={{ fontSize: "20px" }}>
                    To address any customer care issues that occur either with
                    respect to a product or service, delivery, or a complaint or
                    enquiry that you or your recipient raise with us
                  </p>
                  <p style={{ fontSize: "20px" }}>
                    To contact you with any changes, cancellation or other
                    issues with your order
                  </p>
                  <p style={{ fontSize: "20px" }}>
                    To manage any registered accounts you have through the
                    Websites
                  </p>
                  <p style={{ fontSize: "20px" }}>
                    To obtain customer feedback with respect to an order either
                    from you or your recipient (to the extent permitted under
                    the applicable law)
                  </p>
                  <p style={{ fontSize: "20px" }}>
                    NOTE: Please note that you will receive communications with
                    respect to fulfilling your order or for customer care
                    purposes even if you opt out of receiving marketing
                    communications. This communication could be from Integrity
                    Flower Customer Care, courier or the supplier fulfilling
                    your order.
                  </p>
                  <p style={{ fontSize: "20px" }}>
                    We may amend this policy from time to time. If we make any
                    substantial changes in the way we use your personal
                    information we will notify you by email or an announcement
                    on the website.
                  </p>
                  <p style={{ fontSize: "20px" }}>
                    If you have any questions/comments about privacy, you should
                    email us.
                  </p>
                </div>
              </div>
            </article>

            {/* <article class="card" hidden="">
              <header class="card-header" role="tab">
                <h2 class="card-title">Ethical Flowers</h2>
              </header>
              <div hidden="">
                <div class="card-block" role="tabpanel">
                  <p>
                    Whilst delivering happiness is our number one priority, we
                    want to accompany this with our commitment to making a
                    difference, to both the world we live in and the people in
                    it.
                  </p>
                  <p>
                    We feel as a business, we have a responsibility to ensure
                    that we work with farms who adopt the best agricultural
                    practices, monitor costs and savings, and improve processes.
                    This helps us manage the environmental impacts of flower
                    farming, as well as ensuring working standards remain high.
                  </p>
                  <img
                    src="https://media.bunches.co.uk/content_pages/florverde_logo.gif"
                    alt="Florverde's logo"
                    class="charity--logo"
                  />
                  <p>
                    Many of our blooms are sourced from
                    <em>
                      <a
                        href="https://florverde.org/"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        Florverde Sustainable Flowers (FSF)
                      </a>
                    </em>
                    certified farms in regions found in parts of the developing
                    world. This provides us with the opportunity to support
                    members of these communities through trade.
                  </p>
                  <p>
                    Wherever possible we source our Colombian flowers from FSF
                    certified farms. This unique, voluntary scheme aims to
                    improve workers' quality of life whilst ensuring our growers
                    meet environmental standards, lessening the impact these
                    farms have on our lovely planet.
                  </p>
                  <p>
                    Florverde farms not only use sustainable resources, but are
                    also mindful of water consumption and emissions, as well as
                    using organic methods of fertilisation and disposing of any
                    waste responsibly.
                  </p>
                  Workers at FSF farms also benefit:
                  <ul>
                    <li>They must be above 18 to be employed</li>
                    <li>Social security coverage is guaranteed</li>
                    <li>They are paid above the local minimum wage</li>
                    <li>Women's rights are protected</li>
                  </ul>
                  Growers are encouraged to support the local community through
                  projects such as:
                  <ul>
                    <li>Food security programmes</li>
                    <li>Day care centres</li>
                    <li>Housing subsidies</li>
                    <li>Reforestation activities</li>
                  </ul>
                  <p>
                    Here at Bunches, we choose to be mindful every day of our
                    impact on the planet. Our support of FSF certified flower
                    farms is simply another aspect of our commitment to making a
                    difference.
                  </p>
                  <div class="row">
                    <div class="col-12 col-md-6 col-lg-3">
                      <img
                        alt="Picked flowers in Colombia"
                        src="https://media.bunches.co.uk/content_pages/ethicalpage_1.jpg"
                        class="charity-images"
                      />
                    </div>
                    <div class="col-12 col-md-6 col-lg-3">
                      <img
                        alt="Flowers growing in Colombia"
                        src="https://media.bunches.co.uk/content_pages/ethicalpage_2.jpg"
                        class="charity-images"
                      />
                    </div>
                    <div class="col-12 col-md-6 col-lg-3">
                      <img
                        alt="Flowers just sprouting in a Colombian greenhouse"
                        src="https://media.bunches.co.uk/content_pages/ethicalpage_3.jpg"
                        class="charity-images"
                      />
                    </div>
                    <div class="col-12 col-md-6 col-lg-3">
                      <img
                        alt="Flowers being picked and inspected in Colombia"
                        src="https://media.bunches.co.uk/content_pages/ethicalpage_4.jpg"
                        class="charity-images"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </article> */}
            {/* 
            <article class="card" hidden="">
              <header class="card-header" role="tab">
                <h2 class="card-title">Third Party Cookies</h2>
              </header>
              <div hidden="">
                <div class="card-block" role="tabpanel">
                  <p style={{ fontSize: "20px" }}>
                    We may someitmes embed widgets on a webpage such as media
                    players or promotional links, for instance YouTube videos
                    and Skype buttons. If you go on to a web page that contains
                    embedded content, you may be sent cookies from those
                    websites.
                  </p>
                  <p style={{ fontSize: "20px" }}>
                    You may also see tools to 'share' to your friends through
                    social networks - such as Facebook and Twitter. You may be
                    sent cookies from these websites as well.
                  </p>
                  <p style={{ fontSize: "20px" }}>
                    Since we do not control these cookies, we suggest you check
                    these third party websites for information on their cookies
                    and how to manage them.
                  </p>
                </div>
              </div>
            </article> */}
          </div>
        </section>
      </main>
    </main>
  );
};

export default PrivacyPolicy;
