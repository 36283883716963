import React from "react";

const TermsAndConditions = () => {
  return (
    <main id="main-container" class="container top-margin">
      <div class="nav-blackout"></div>
      <main class="row static-page about-us-page">
        <section class="col-md-12">
          <div role="tablist" aria-multiselectable="true">
            <article class="card" hidden="">
              <header class="card-header" role="tab">
                <h2 class="card-title">Products</h2>
              </header>
              <div hidden="">
                <div class="card-block" role="tabpanel">
                  <p style={{ fontSize: "20px" }}>
                    1.1 All Products on this website (which may vary from time
                    to time) are available in{" "}
                    <span style={{ fontWeight: "bold" }}>UK</span>.
                  </p>
                  <p style={{ fontSize: "20px" }}>
                    1.2 Certain Products may be delivered by us in bud to ensure
                    longer life.
                  </p>
                  <p style={{ fontSize: "20px" }}>
                    1.3 Some Products, Flowers and Plants may be harmful or
                    poisonous, if you require further information before
                    submitting an order please contact us using the contact
                    details set out in Section 6 below.
                  </p>
                  <p style={{ fontSize: "20px" }}>
                    1.4 All Products are subject to availability. In the event
                    of any supply difficulties, we reserve the right to
                    substitute a Product of equivalent value and quality without
                    notice.
                  </p>
                  <p style={{ fontSize: "20px" }}>
                    1.5 In the event that we are unable to supply the Product or
                    any substitute product to you at all, we shall notify you as
                    soon as is reasonably possible and shall reimburse your
                    payment in full and in any event no later than 30 days after
                    the intended delivery date.
                  </p>
                  <p style={{ fontSize: "20px", marginTop: "20px" }}>
                    Within our alcoholic gift range:
                  </p>
                  <p style={{ fontSize: "20px" }}>
                    1.6.1 We reserve the right to replace brands shown to equal
                    value and quality.
                  </p>
                  <p style={{ fontSize: "20px" }}>
                    1.6.2 Under the Licensing Act of 1964 (UK) and 1976
                    (Scotland) it is an offence for any person under 18 years to
                    buy, or attempt to buy, alcoholic liquor. It is an offence
                    under sections 32 and 33 of the intoxicating Liquor Act 1988
                    (Ireland) for any person under 18 to buy, or attempt to buy,
                    intoxicating liquor. It is an offence under Licensing
                    (Northern Ireland) Order 1990 for any person under 18 to
                    buy, or attempt to buy, intoxicating liquor.
                  </p>
                </div>
              </div>
            </article>
            <article class="card" hidden="">
              <header class="card-header" role="tab">
                <h2 class="card-title">Prices</h2>
              </header>
              <div hidden="">
                <div class="card-block" role="tabpanel">
                  <p style={{ fontSize: "20px" }}>
                    2.1 Prices listed within the Service are valid for a maximum
                    of 14 days from submitting your order.
                    <span style={{ fontWeight: "bold" }}>UK</span>.
                  </p>
                  <p style={{ fontSize: "20px" }}>2.2 Prices include VAT.</p>
                </div>
              </div>
            </article>

            <article class="card" hidden="">
              <header class="card-header" role="tab">
                <h2 class="card-title">Order / Payments</h2>
              </header>
              <div hidden="">
                <div class="card-block" role="tabpanel">
                  <p style={{ fontSize: "20px" }}>
                    3.1 During order submission you shall be required to provide
                    us with your personal details, including accurate postcode,
                    together with those of the intended recipient and all
                    necessary payment details. We accept payment by most major
                    credit and debit cards. By clicking on the 'complete order’
                    button on the Order Form, you are consenting to be bound by
                    our terms and conditions contained in these Terms and
                    Conditions and appearing anywhere on the Online site.
                  </p>
                  <p style={{ fontSize: "20px" }}>
                    3.2 Submitting your Order Form is subject to our acceptance
                    of this offer and we will not consider ourselves bound by a
                    contract with you unless we have issued you a confirmation
                    of order delivery by email. This is not the email sent on
                    submission of an order.
                  </p>
                  <p style={{ fontSize: "20px" }}>
                    3.3 We cannot accept Order Forms from customers under the
                    age of eighteen (18) years.
                  </p>
                  <p style={{ fontSize: "20px" }}>
                    3.4 Only one promotion can be used at a time. Unfortunately
                    we cannot combine multiple offers in an order.
                  </p>
                </div>
              </div>
            </article>

            <article class="card" hidden="">
              <header class="card-header" role="tab">
                <h2 class="card-title">Delivery</h2>
              </header>
              <div hidden="">
                <div class="card-block" role="tabpanel">
                  <p style={{ fontSize: "20px" }}>
                    4.1 Our delivery service is within the
                    <span style={{ fontWeight: "bold" }}>UK</span>.
                  </p>
                  <p style={{ fontSize: "20px" }}>
                    4.2 On acceptance by us of your Order Form we will advise
                    you by e-mail confirmation of the intended delivery date. We
                    will process Order Forms received on Sundays or Public
                    Holidays the next working day. We do not deliver on Sundays
                    or public holidays but in this case we will deliver the
                    Product you request on the next working day.
                  </p>
                  <p style={{ fontSize: "20px" }}>
                    4.3 Anomalies in your submitted personal details may lead to
                    problems or delays in delivery, so before placing your
                    order, please ensure that you have included the full address
                    details, including accurate postcode of the intended
                    recipient and your daytime contact telephone number or
                    e-mail address so that we can notify you in the event that
                    any delivery problems are encountered.
                  </p>
                  <p style={{ fontSize: "20px" }}>
                    4.4 Whilst we agree to use all reasonable endeavours to
                    ensure that delivery will be on the requested delivery date
                    you acknowledge that in very occasional circumstances
                    delivery on the requested terms will not be possible. In
                    such circumstances you will be given prior notice wherever
                    possible and we shall either make alternative arrangements
                    or shall reimburse your payment in full.
                  </p>
                  <p style={{ fontSize: "20px" }}>
                    4.5 In case of delivery to certain locations where a third
                    party is involved, such as offices, hospitals, airports,
                    hotels, ships and other business locations, the signature of
                    any person authorised to accept delivery on behalf of the
                    organisation shall be accepted as proof of delivery to your
                    chosen recipient. Please be advised that we are unable to
                    deliver wines, and plants to Funeral Directors, Hospitals,
                    airports or ships.
                  </p>
                  <p style={{ fontSize: "20px" }}>
                    4.6 Please note that we are only responsible for delivering
                    against signature to the address you quote (or an imminent
                    neighbour if no one is at home) we regret that where goods
                    have been correctly delivered we can not accept
                    responsibility if the intended recipient has moved, or lives
                    elsewhere, and the actual received refuses or fails to
                    return item(s).
                  </p>
                  <p style={{ fontSize: "20px" }}>
                    4.7 Where the carrier is unable to find someone to accept
                    delivery they will either deliver to a neighbour or leave a
                    card at the address for the recipient to make contact.
                  </p>
                  <p style={{ fontSize: "20px" }}>
                    4.8 It is possible to select a preferred delivery time
                    within the order process in the Delivery Notes field. This
                    option should be used only for orders requesting delivery to
                    funerals or funeral parlours. Whilst every effort will be
                    made to ensure your order is delivered prior to the time
                    selected, no guarantee for delivery by the time specified
                    can be given. To assist us in meeting your preference,
                    please ensure your order is placed at least one working day
                    prior to the preferred delivery time.
                  </p>
                  <p style={{ fontSize: "20px" }}>
                    4.9 In the instance of there being difficulties in
                    delivering your order to the intended recipient we reserve
                    the right to contact the recipient using the contact details
                    you provide on the Order Form.
                  </p>
                  <p style={{ fontSize: "20px" }}>
                    4.10 When placing your order you are able to specify further
                    delivery information using the 'Delivery Notes' field
                    provided. This is intended to provide further guidance to
                    the party delivering your order which isn't included within
                    your address information, for example to specify floor
                    number, or hospital ward. We reserve the right to not action
                    any delivery requests specified in this field which we deem
                    unacceptable and will use other elements of address
                    information provided elsewhere on the order form to fulfill
                    your order.
                  </p>
                </div>
              </div>
            </article>

            <article class="card" hidden="">
              <header class="card-header" role="tab">
                <h2 class="card-title">Cancellation</h2>
              </header>
              <div hidden="">
                <div class="card-block" role="tabpanel">
                  <p style={{ fontSize: "20px" }}>
                    5.1 Orders may be amended or cancelled up to 48 hours before
                    the intended delivery date. This may not be applicable
                    during peak times like Christmas, Valentine's Day & Mother's
                    Day. We may not be able to make any changes during these
                    times.
                  </p>
                  <p style={{ fontSize: "20px" }}>
                    5.2 To amend or cancel your order, please contact us using
                    the contact details set out in Section 6 below.
                  </p>
                  <p style={{ fontSize: "20px" }}>
                    5.3 You are entitled to cancel any payment at any time where
                    fraudulent use has been made of your credit or debit card by
                    another person not acting on behalf of you or as your agent
                    and to be re-credited by us to the extent that such sums are
                    not reimbursed by the card issuer in such circumstances.
                  </p>
                </div>
              </div>
            </article>

            <article class="card" hidden="">
              <header class="card-header" role="tab">
                <h2 class="card-title">Customer Services</h2>
              </header>
              <div hidden="">
                <div class="card-block" role="tabpanel">
                  <p style={{ fontSize: "20px" }}>
                    6.1 In the event that you are not satisfied with the Online
                    Service any complaints should be addressed to the Manager -
                    help@integrityflowers.co.uk
                  </p>
                  <p style={{ fontSize: "20px" }}>
                    6.2 Because of the perishable nature of Products and in
                    order to assist us in resolving any complaints quickly, we
                    advise you to make any complaint within 1 working day of the
                    date of delivery or intended delivery of your purchase.
                  </p>
                </div>
              </div>
            </article>

            <article class="card" hidden="">
              <header class="card-header" role="tab">
                <h2 class="card-title">Disclaimer</h2>
              </header>
              <div hidden="">
                <div class="card-block" role="tabpanel">
                  <p style={{ fontSize: "20px" }}>
                    7.1 Whilst we agree to use our reasonable endeavours to
                    ensure that this Online Site and/or the Online Service is
                    fully operational and error-free we cannot guarantee this
                    and, therefore, accept no responsibility for any defects
                    and/or interruption of the Online Site and/or the Online
                    Service and shall be released from our obligations under
                    these Terms and Conditions in the event of any cause beyond
                    our reasonable control which renders the provision of the
                    Online Site and/or the Online Service impossible or
                    impractical.
                  </p>
                  <p style={{ fontSize: "20px" }}>
                    7.2 (a) We accept liability for death or personal injury
                    arising from our negligence.
                  </p>
                  <p style={{ fontSize: "20px" }}>
                    (b) Subject to clause 7.2(a), our liability in contract,
                    tort (including negligence and breach of statutory duty) or
                    otherwise arising by reason of or in connection with your
                    Order Form with us shall be limited to a multiple of 3 times
                    the price you have paid for the Products.
                  </p>
                  <p style={{ fontSize: "20px" }}>
                    (c) Subject to clauses 7.2(a) and 7.2(b) above we exclude
                    all liability for any claims, losses, demands and damages,
                    including without limitation, any costs, loss of profits,
                    loss of contracts or business opportunity, loss of data and
                    any other consequential, incidental, special or punitive
                    damages, even if we have been advised of the possibility of
                    such damages, arising directly or indirectly out of or in
                    any way connected with your use or inability to access the
                    Online Site and/or the Online Service, whether arising in
                    contract, tort (including negligence), under statute or
                    otherwise PROVIDED THAT nothing contained in these Terms and
                    Conditions affects or will affect your or the recipient's
                    statutory rights in relation to the quality, fitness or
                    description of the Products supplied.
                  </p>
                  <p style={{ fontSize: "20px" }}>
                    (c) Subject to clauses 7.2(a) and 7.2(b) above we exclude
                    all liability for any claims, losses, demands and damages,
                    including without limitation, any costs, loss of profits,
                    loss of contracts or business opportunity, loss of data and
                    any other consequential, incidental, special or punitive
                    damages, even if we have been advised of the possibility of
                    such damages, arising directly or indirectly out of or in
                    any way connected with your use or inability to access the
                    Online Site and/or the Online Service, whether arising in
                    contract, tort (including negligence), under statute or
                    otherwise PROVIDED THAT nothing contained in these Terms and
                    Conditions affects or will affect your or the recipient's
                    statutory rights in relation to the quality, fitness or
                    description of the Products supplied.
                  </p>
                </div>
              </div>
            </article>

            <article class="card" hidden="">
              <header class="card-header" role="tab">
                <h2 class="card-title">General</h2>
              </header>
              <div hidden="">
                <div class="card-block" role="tabpanel">
                  <p style={{ fontSize: "20px" }}>
                    8.1 We reserve the right to supplement and amend the Terms
                    and Conditions on which you are permitted access to the
                    Online Site and/or the Online Service from time to time. We
                    will post any changes on the Site and it is your
                    responsibility as a customer to review the Terms and
                    Conditions on each occasion you access the Online Service or
                    Online Site. Changes will be effective five (2) hours after
                    the posting of any such change and all subsequent dealings
                    between you and us shall be on the new terms and conditions.
                  </p>
                  <p style={{ fontSize: "20px" }}>
                    8.2 Additionally, we reserve the right to suspend, restrict
                    or terminate access to the Online Site and/or the Online
                    Services for any reason at any time.
                  </p>
                  <p style={{ fontSize: "20px" }}>
                    8.3 These Terms and Conditions shall be deemed to include
                    all other notices, policies, disclaimers and other terms
                    contained in the Online Site, provided that in the event of
                    a conflict between any such other notices, policies,
                    disclaimers and other terms, these Terms and Conditions
                    shall prevail. If any of these Terms and Conditions is held
                    to be invalid or unenforceable, the remaining provisions
                    shall remain in full force and effect.
                  </p>
                  <p style={{ fontSize: "20px" }}>
                    8.4 We shall ensure that we comply with the requirement of
                    all current data protection legislation including, without
                    limitation, the Data Protection Act 1998 (as replaced,
                    modified or re-enacted from time to time). We shall only use
                    personal data received from you for the purpose of
                    fulfilling our obligations under these Terms and Conditions
                    and as stated in our Privacy Policy.
                  </p>
                  <p style={{ fontSize: "20px" }}>
                    8.5 A person who is not a party to the agreement between you
                    and us has no rights under the Contracts (Rights of Third
                    Parties) Act 1999 to enforce any of the Terms and Conditions
                    but this does not affect any right or remedy which exists
                    apart from that Act.
                  </p>
                  <p style={{ fontSize: "20px" }}>
                    8.6 Save in respect of fraudulent misrepresentation, this
                    agreement (including any documents and instruments referred
                    to herein) supersedes all prior representations,
                    arrangements, understandings, and agreements between you and
                    us (whether written or oral) and sets forth the entire
                    agreement and understanding between you and us relating to
                    the subject matter hereof.
                  </p>
                  <p style={{ fontSize: "20px" }}>
                    8.7 Your purchase will be deemed to have occurred in the UK.
                    These Terms and Conditions shall be governed by and
                    construed in accordance with English Law and the parties
                    agree to submit to the exclusive jurisdiction of the English
                    courts.
                  </p>
                </div>
              </div>
            </article>
          </div>
        </section>
      </main>
    </main>
  );
};

export default TermsAndConditions;
