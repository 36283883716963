import React, { useState } from "react";
import "./ContectUs.css";
import { contact_form } from "../../../api/api";
import { Link } from "react-router-dom";

const ContactUs = () => {
  const [userData, setUserData] = useState({});
  const [userDataError, setUserDataError] = useState({});

  const [formSuccess, setFormSuccess] = useState(false);

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    let validationErrors = {};
    const formData = new FormData();

    if (!userData.email) {
      validationErrors = {
        ...validationErrors,
        email: "Email is a required field",
      };
    } else if (!/\S+@\S+\.\S+/.test(userData.email)) {
      validationErrors = {
        ...validationErrors,
        email: "Invalid email format",
      };
    } else {
      formData.append("email", userData.email);
    }

    if (!userData.tel_num) {
      validationErrors = {
        ...validationErrors,
        tel_num: "Phone is a required field",
      };
    } else if (!/^\d+$/.test(userData.tel_num)) {
      validationErrors = {
        ...validationErrors,
        tel_num: "Phone must contain only numbers",
      };
    } else {
      formData.append("tel_num", userData.tel_num);
    }

    const fieldsToValidate = [
      "name",
      "order_num",
      "postcode",
      "subject",
      "message",
    ];

    fieldsToValidate.forEach((field) => {
      if (!userData[field] || userData[field] === "") {
        validationErrors = {
          ...validationErrors,
          [field]: `${field.replace("_", " ")} is a required field`,
        };
      } else {
        formData.append(field, userData[field]);
      }
    });

    setUserDataError(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      try {
        const response = await contact_form(userData);
        if (response?.data?.message === "Contact form created successfully") {
          setFormSuccess(true);
        }
      } catch (error) {
        console.log("error", error);
      }
    }
  };
  return (
    <main id="main-container" class="container top-margin">
      <div class="nav-blackout"></div>
      <div class="static-page">
        <div class="zvTOk2V0UJanoNB9dSfh">
          <h1>We're here to help</h1>
          <img
            alt="Chat icon"
            src="https://media.bunches.co.uk/icons/chat.svg"
          />
        </div>
        <article class="card" hidden="">
          <div hidden="">
            <div class="card-block" role="tabpanel">
              <p style={{ fontSize: "20px" }}>
                Have a question, a special request, or simply want to say hello?
                We'd love to hear from you! Reach out to us and let our
                passionate team of floral enthusiasts assist you. Whether you're
                dreaming up a custom arrangement, seeking expert advice, or just
                want to share your floral inspiration, we're here to make your
                experience with Integrity Flowers as delightful as a bouquet in
                full bloom. Connect with us today and let's bring your floral
                visions to life!
              </p>
            </div>
          </div>
        </article>
        {/* <div class="fKo74OjyrI679xRKQ4Bu">
          <div class="UG7Bl4cIPQ6woiN4MYtk">
            <h2>Delivery</h2>
            <Link to="/faq">Where is my delivery?</Link>
            <Link to="/faq">
              The online tracking says it's been delivered but it has not
              arrived?
            </Link>
          </div>
          <div class="UG7Bl4cIPQ6woiN4MYtk">
            <h2>Quality</h2>
            <Link to="/faq#OurFlowers">My flowers are in poor condition</Link>
            <Link to="/faq#OurFlowers">
              My bouquet looks different to the image online
            </Link>
          </div>
          <div class="UG7Bl4cIPQ6woiN4MYtk">
            <h2>Placing an order</h2>
            <Link to="/faq#Ordering">How do I edit my order?</Link>
            <Link to="/faq#Ordering">How do I cancel my order?</Link>
            <Link to="/faq#Ordering">
              Can I change the image on my personalised card after my order has
              been placed?
            </Link>
            <Link to="/faq#Ordering">Applying discount codes</Link>
            <Link to="/faq#WebsiteIssues">Website Issues</Link>
          </div>
          <div class="UG7Bl4cIPQ6woiN4MYtk">
            <Link class="e7QvJMPhPw6Pbciz9UHc YLxdcrrIEpFGSqqBDTXs" to="/faq">
              See all FAQs
            </Link>
          </div>
        </div> */}
        <main class="KbUUDfXOqoUP1V3vd_bE_">
          <section class="card _QxMBiWT3AtKg5VaaK0e">
            <header class="card-header">
              <h2 class="card-title">Contact Us</h2>
            </header>
            <div class="card-block">
              <p>
                You can contact us on email at{" "}
                <Link
                  style={{ fontSize: "20px" }}
                  to="mailto:flowers@integrityflowers.co.uk"
                >
                  help@integrityflowers.co.uk
                </Link>
              </p>
              {/* <p>You can also write to us at:</p>
              <p>
                Bunches Florapost Ltd.
                <br />
                Unit 19, Hazelford Way
                <br />
                Newstead Village
                <br />
                Nottinghamshire
                <br />
                NG15 0DQ
              </p> */}
              <p>Or fill our online form:</p>
              {!formSuccess && (
                <form onSubmit={handleFormSubmit}>
                  <div class="form-group">
                    <label for="name">Your Name</label>
                    <input
                      onChange={handleChange}
                      type="text"
                      name="name"
                      class="input--full"
                      id="name"
                      placeholder="Name…"
                      autocomplete="name"
                    />
                    {userDataError.name && (
                      <div style={{ color: "red" }}>{userDataError.name}</div>
                    )}
                  </div>
                  <div class="form-group">
                    <label for="email">Email Address</label>
                    <input
                      onChange={handleChange}
                      type="text"
                      name="email"
                      class="input--full"
                      id="email"
                      aria-describedby="emailHelp"
                      placeholder="Email…"
                      autocomplete="email"
                    />
                    {userDataError.email && (
                      <div style={{ color: "red" }}>{userDataError.email}</div>
                    )}
                    <small id="emailHelp" class="form-text text-muted">
                      We'll never share your email with anyone else.
                    </small>
                  </div>
                  <div class="form-group">
                    <label for="tel_num">Your Telephone Number</label>
                    <input
                      onChange={handleChange}
                      type="tel"
                      name="tel_num"
                      class="input--full"
                      id="tel_num"
                      placeholder="Telephone…"
                      autocomplete="tel"
                    />
                    {userDataError.tel_num && (
                      <div style={{ color: "red" }}>
                        {userDataError.tel_num}
                      </div>
                    )}
                  </div>
                  <div class="form-group">
                    <label for="order_num">Order Number</label>
                    <input
                      type="text"
                      onChange={handleChange}
                      name="order_num"
                      class="input--full"
                      id="order_num"
                      placeholder="Order Number…"
                    />
                    {userDataError.order_num && (
                      <div style={{ color: "red" }}>
                        {userDataError.order_num}
                      </div>
                    )}
                  </div>
                  <div class="form-group">
                    <label for="postcode">Delivery Postcode</label>
                    <input
                      onChange={handleChange}
                      type="text"
                      name="postcode"
                      class="input--full"
                      id="postcode"
                      placeholder="Delivery Postcode…"
                    />
                    {userDataError.postcode && (
                      <div style={{ color: "red" }}>
                        {userDataError.postcode}
                      </div>
                    )}
                  </div>
                  <div class="form-group">
                    <label for="subject">You would like to…</label>
                    <div class="select-wrapper">
                      <div class="select-caret">
                        <i class="fa fa-angle-down"></i>
                      </div>
                      <select
                        onChange={handleChange}
                        id="subject"
                        name="subject"
                      >
                        <option value="" selected=""></option>
                        <option value="General Enquiry">
                          Make a general enquiry
                        </option>
                        <option value="Complaint">Make a complaint</option>
                        <option value="Amend Order">Amend my order</option>
                        <option value="Amend Account">
                          Change my account details
                        </option>
                        <option value="Website Problem">
                          Report a problem with the website
                        </option>
                        <option value="B2B">
                          Make a B2B purchase or enquiry
                        </option>
                        <option value="Marketing">
                          Discuss a marketing / advertising opportunity
                        </option>
                        <option value="Thank You">Say thank you</option>
                      </select>
                      {userDataError.subject && (
                        <div style={{ color: "red" }}>
                          {userDataError.subject}
                        </div>
                      )}
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="message">Your message to us</label>
                    <textarea
                      onChange={handleChange}
                      class="input--full"
                      name="message"
                      id="message"
                      rows="3"
                      placeholder="Questions, queries, or comments…"
                    ></textarea>
                    {userDataError.message && (
                      <div style={{ color: "red" }}>
                        {userDataError.message}
                      </div>
                    )}
                  </div>
                  {/* <div class="captcha-container">
                  <div id="captcha" class="mtcaptcha" mtcapwid="captcha-2">
                    <iframe
                      id="captcha-iframe-2"
                      style={{
                        display: "inline-block",
                        border: "0px",
                        padding: "0px",
                        margin: "0px",
                        background: "transparent",
                        height: "178px",
                      }}
                      src="https://service.mtcaptcha.com/mtcv1/client/iframe.html?v=2024-03-24.15.25.36&amp;sitekey=MTPublic-ErfGNFQXC&amp;iframeId=captcha-iframe-2&amp;widgetSize=standard&amp;custom=false&amp;widgetInstance=captcha&amp;challengeType=standard&amp;theme=neowhite&amp;lang=en-GB&amp;action=&amp;autoFadeOuterText=false&amp;host=https%3A%2F%2Fwww.bunches.co.uk&amp;hostname=www.bunches.co.uk&amp;serviceDomain=service.mtcaptcha.com&amp;textLength=0&amp;lowFrictionInvisible=&amp;enableMouseFlow=false"
                      title="mtcaptcha"
                      sandbox=" allow-forms allow-popups allow-same-origin allow-scripts allow-modals allow-popups-to-escape-sandbox"
                      width="100%"
                      height="178px"
                    ></iframe>
                    <input
                      type="hidden"
                      class="mtcaptcha-verifiedtoken"
                      name="mtcaptcha-verifiedtoken"
                      id="captcha-verifiedtoken-2"
                      readonly="readonly"
                      value=""
                    />
                  </div>
                </div> */}
                  <div class="uMc953OVRlNHKqffF4XW">
                    <button
                      type="submit"
                      class="bn-button bn-button--primary flex-center"
                      disabled=""
                    >
                      Submit
                    </button>
                  </div>
                </form>
              )}

              {formSuccess && (
                <div
                  class="mt-2 PpJPYnJ0SxVNDy6CcMkU KmZaPtQ1hSpVpkonQFhV"
                  role="alert"
                >
                  <strong>Thank you!</strong> Our Customer Support Team will get
                  back to you shortly.
                </div>
              )}
            </div>
          </section>
          {/* <section class="card aaU1KAN2ZbbHEX80pqMF">
            <header class="card-header">
              <h2 class="card-title">Live Chat</h2>
            </header>
            <div class="card-block zYvXFWHKZHKbSHU8gmYH">
              <img
                alt="Chat icon"
                src="https://media.bunches.co.uk/icons/chat-blue.svg"
              />
              <span>
                <p>
                  Live chat is <br />
                  currently unavailable
                </p>
              </span>
            </div>
          </section> */}
          {/* <section class="card II43C4fpkvTqqKAgUMJN">
            <header class="card-header">
              <h2 class="card-title">Customer Service</h2>
            </header>
            <div class="card-block">
              <p>
                We're family people, just like many of our customers. And it's
                our family values that inspire us to provide you with customer
                service from a team of people who really care. So, if you want
                to place an order, discuss a query or if things don't work out
                as expected (we're human and that sometimes happens!), we're
                here to help. Simply use the contact details provided on this
                page. We also have web chat available during our opening hours
                if that's more your thing!
              </p>
              <p class="mb-1">Our current opening hours are as follows:</p>
              <ul>
                <li>Monday to Friday: 9am to 5pm</li>
                <li>Saturday: Closed</li>
                <li>Sunday (and bank holidays): Closed</li>
              </ul>
              <p class="font-italic">
                Hours may be extended during Christmas and Mother's Day.
              </p>
              <p class="mb-1">It might also be useful for you to know:</p>
              <ul>
                <li>
                  We aim to respond to any customer contact within one working
                  day
                </li>
                <li>
                  Calls may be monitored for training purposes to help us
                  continually improve the quality of our service
                </li>
                <li>
                  On Mondays between 9am - 11am, you might be waiting a little
                  longer than usual as this is our busiest time of the week
                </li>
                <li>
                  Our average waiting time for live chat is less than 2 minutes
                </li>
              </ul>
              <small>(All times displayed are presented in GMT / BST)</small>
            </div>
          </section> */}
        </main>
      </div>
    </main>
  );
};

export default ContactUs;
