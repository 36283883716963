import React from "react";

const AboutUs = () => {
  return (
    <main id="main-container" class="container top-margin">
      <div class="nav-blackout"></div>
      <main class="row static-page about-us-page">
        {/* <aside class="col-md-3 static-page-side-links">
          <a
            class="nav-link active"
            aria-controls="overview"
            href="/about-us#overview"
          >
            Overview
          </a>
          <a class="nav-link" aria-controls="history" href="/about-us#history">
            Our History
          </a>
          <a class="nav-link" aria-controls="charity" href="/about-us#charity">
            Making a Difference
          </a>
          <a class="nav-link" aria-controls="flowers" href="/about-us#flowers">
            Ethical Flowers
          </a>
        </aside> */}
        <section class="col-md-12">
          <div role="tablist" aria-multiselectable="true">
            {/* <article class="card">
              <header class="card-header" role="tab">
                <h2 class="card-title">Overview</h2>
              </header>
              <div>
                <div class="card-block" role="tabpanel">
                  <div class="aboutus--header"></div>
                  <figure class="embed-responsive embed-responsive-16by9">
                    <iframe
                      class="embed-responsive-item"
                      src="https://www.youtube.com/embed/rKJHq8wZGno?modestbranding=1&amp;rel=0"
                      allowfullscreen=""
                    ></iframe>
                  </figure>
                  <p>We deliver happiness.</p>
                  <figure class="aboutus--content1">
                    <img
                      alt="Bunches' production team"
                      src="https://media.bunches.co.uk/content_pages/aboutus_production.jpg"
                    />
                    <figcaption class="image-caption">
                      The Bunches production team
                    </figcaption>
                  </figure>
                  <p>
                    That might sound a little idealistic but it's the reason we
                    get out of bed in the morning.
                  </p>
                  <p>
                    At Bunches we believe that business can and should have a
                    positive impact. Bunches doesn't exist simply to create
                    profits. We want to make a difference to people and to our
                    world.
                  </p>
                  <p>
                    Our values haven't changed since we started selling flowers
                    under an umbrella back in 1989. Bunches is family-owned, so
                    we're all about relationships, whether that be with our
                    customers, suppliers or our staff. We have the pleasure of
                    selling a product that brings joy to others and aim to do so
                    fairly and to the highest standards.
                  </p>
                  <figure class="aboutus--content2">
                    <img
                      alt="Our Customer Support manager, Jess"
                      src="https://media.bunches.co.uk/content_pages/aboutus_cs.jpg"
                    />
                    <figcaption class="image-caption">
                      Our customer support team are here to take your call
                    </figcaption>
                  </figure>
                  <p>
                    Bunches has always been committed to giving a minimum of 10%
                    of profits to working with the poor either in the UK or
                    largely in the developing world. As far as we're concerned
                    this doesn't just mean signing a cheque and then walking
                    away. We are actively involved in deciding how these funds
                    are managed and providing other, practical support too.
                  </p>
                  <p>
                    We do everything under the same roof, this means all of our
                    staff members are trained to Bunches' high standards, from
                    our production team who are responsible for packing and
                    quality-checking your flowers, to our friendly customer
                    support team who are there to answer any questions you may
                    have. They're all an integral part of the Bunches family!
                  </p>
                  <p>
                    Of course, we're not perfect and we know we're never going
                    to be. It's really important that we understand this because
                    when we're aware that we've not got everything right we can
                    look for ways to improve. We want to get better. Constantly.
                  </p>
                </div>
              </div>
            </article> */}
            {/* <article class="card" hidden="">
              <header class="card-header" role="tab">
                <h2 class="card-title">Our History</h2>
              </header>
              <div hidden="">
                <div class="card-block ourhistory" role="tabpanel">
                  <figure class="ourhistory--content1">
                    <img
                      alt="Sandra arranging flowers in Mansfield shopping centre"
                      src="https://media.bunches.co.uk/content_pages/ourhistory_1.jpg"
                    />
                    <figcaption class="image-caption">
                      Sandra Hoving arranging flowers to sell in a local
                      shopping centre (1989)
                    </figcaption>
                  </figure>
                  <p>
                    Over 10 million bouquets ago, beneath an umbrella in
                    Mansfield, Erik and Sandra Hoving started selling flowers.
                    They knew they wanted to do something unique so started by
                    speaking to customers to find out what they really wanted.
                    It didn't take too long before the idea of postal flowers
                    blossomed.
                  </p>
                  <p>
                    We started working with the Post Office in 1990 dispatching
                    a grand total of 5 bouquets in that first week. We've been
                    sending flowers out by post ever since and still talk to our
                    customers constantly. It's a model that has stood the test
                    of time. Lots of people have since copied us which we find
                    quite flattering really.
                  </p>
                  <figure class="ourhistory--content2">
                    <img
                      alt="Sandra and Erik under an umbrella at the Post Office trade show"
                      src="https://media.bunches.co.uk/content_pages/ourhistory_2.jpg"
                    />
                    <figcaption class="image-caption">
                      Sandra and Erik at the Post Office trade show
                    </figcaption>
                  </figure>
                  <p>
                    Around the turn of the millennium we took the decision to
                    try our hand online and by 2004 it was pretty obvious this
                    was a good decision!
                  </p>
                  <p>
                    Since those first web sales we've built our own HQ and
                    extended it twice, we've won a number of awards and been
                    visited by royalty. We still like to do things a bit
                    differently and all Bunches bouquets are designed and
                    dispatched by our own passionate team. You can also call and
                    speak to us too, or email, or use live chat if you prefer.
                  </p>
                  <p>
                    Today Bunches is run by the next generation of the family
                    (Dani took the reins in 2006, her husband Dan in 2012). Our
                    values haven't changed since those umbrella days though.
                    We're still listening to customers who trust us with their
                    important occasions. We take that responsibility seriously!
                  </p>
                  <div class="ourhistory--footer"></div>
                </div>
              </div>
            </article> */}
            <article class="card" hidden="">
              <header class="card-header" role="tab">
                <h2 class="card-title">About us</h2>
              </header>
              <div hidden="">
                <div class="card-block" role="tabpanel">
                  {/* <div class="charity--header"></div> */}
                  <p style={{ fontSize: "20px" }}>
                    Welcome to Integrity Flowers, where every bloom tells a
                    story of beauty, grace, and heartfelt sentiments. We're more
                    than just a flower shop; we're your companions in
                    celebrating life's most precious moments. With a passion for
                    petals and a commitment to excellence, we handpick each stem
                    to ensure every arrangement reflects the uniqueness of your
                    occasion. Whether it's a joyful celebration, a token of
                    appreciation, or a moment of solace, let us weave nature's
                    delicate wonders into your life's tapestry. Explore our
                    floral treasures and let us help you craft unforgettable
                    memories, one blossom at a time.
                  </p>
                  {/* <p>
                    We believe that Bunches is a blessing, not just a business.
                    For this reason, each year we donate a minimum of 10% of our
                    profits to charitable projects close to our hearts, both in
                    the UK and abroad.
                  </p>
                  <p>
                    For many years our key partner has been
                    <a
                      href="https://www.linksinternational.org.uk/"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Links International
                    </a>
                    - together we have worked to develop farms and medical
                    projects, provide fresh water and support education. While
                    not every project has flourished, we have learned lots of
                    lessons and seen thousands of lives improved along the way.
                  </p>
                  <p>
                    We believe sustainable freedom from poverty is about giving
                    people a hand up, not a handout. Micro-enterprise
                    development is one way we can give such a hand up. We
                    support many micro-enterprise projects in Malawi through our
                    partner
                    <a
                      href="https://www.linksinternational.org.uk/"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Links International
                    </a>
                    .
                  </p>
                  <p>
                    Money is donated to give training to a local community
                    group, who then provide loans to people, enabling them to
                    create their own sustainable mini-businesses. Once the
                    original loan is repaid, the money is recycled, giving
                    others the same opportunity.
                  </p>
                  <p>
                    We have supported nearly 1000 micro-enterprise development
                    projects in Malawi. These projects achieve so much more than
                    simply providing income for food, clothing and education.
                    They empower, restore dignity, and ensure sustainable relief
                    from poverty.
                  </p>
                  <p>
                    <a href="/journal/making-a-difference-the-story-of-moses">
                      Click here to read our story about how one little piggy
                      transformed the life of Moses.
                    </a>
                  </p>
                  <p>
                    Our charity work links closely with our desire to be a
                    sustainable business. Our long-term goal is to be
                    plastic-free, and every day we make a conscious effort to
                    continue to work towards this goal, whether that be
                    researching innovative means of packaging, or working with
                    suppliers who share our values. We choose every day to be
                    mindful of protecting the world we live in and the people in
                    it.
                  </p>
                  <p>
                    <a href="/journal/planet-friendly-and-proud-our-first-plastic-free-range">
                      Click here to read our Planet-Friendly and Proud blog
                      series.
                    </a>
                  </p>
                  <p>
                    Who'd have thought selling flowers could lead to such
                    adventures?
                  </p> */}
                </div>
              </div>
            </article>
            {/* <article class="card" hidden="">
              <header class="card-header" role="tab">
                <h2 class="card-title">Ethical Flowers</h2>
              </header>
              <div hidden="">
                <div class="card-block" role="tabpanel">
                  <p>
                    Whilst delivering happiness is our number one priority, we
                    want to accompany this with our commitment to making a
                    difference, to both the world we live in and the people in
                    it.
                  </p>
                  <p>
                    We feel as a business, we have a responsibility to ensure
                    that we work with farms who adopt the best agricultural
                    practices, monitor costs and savings, and improve processes.
                    This helps us manage the environmental impacts of flower
                    farming, as well as ensuring working standards remain high.
                  </p>
                  <img
                    src="https://media.bunches.co.uk/content_pages/florverde_logo.gif"
                    alt="Florverde's logo"
                    class="charity--logo"
                  />
                  <p>
                    Many of our blooms are sourced from
                    <em>
                      <a
                        href="https://florverde.org/"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        Florverde Sustainable Flowers (FSF)
                      </a>
                    </em>
                    certified farms in regions found in parts of the developing
                    world. This provides us with the opportunity to support
                    members of these communities through trade.
                  </p>
                  <p>
                    Wherever possible we source our Colombian flowers from FSF
                    certified farms. This unique, voluntary scheme aims to
                    improve workers' quality of life whilst ensuring our growers
                    meet environmental standards, lessening the impact these
                    farms have on our lovely planet.
                  </p>
                  <p>
                    Florverde farms not only use sustainable resources, but are
                    also mindful of water consumption and emissions, as well as
                    using organic methods of fertilisation and disposing of any
                    waste responsibly.
                  </p>
                  Workers at FSF farms also benefit:
                  <ul>
                    <li>They must be above 18 to be employed</li>
                    <li>Social security coverage is guaranteed</li>
                    <li>They are paid above the local minimum wage</li>
                    <li>Women's rights are protected</li>
                  </ul>
                  Growers are encouraged to support the local community through
                  projects such as:
                  <ul>
                    <li>Food security programmes</li>
                    <li>Day care centres</li>
                    <li>Housing subsidies</li>
                    <li>Reforestation activities</li>
                  </ul>
                  <p>
                    Here at Bunches, we choose to be mindful every day of our
                    impact on the planet. Our support of FSF certified flower
                    farms is simply another aspect of our commitment to making a
                    difference.
                  </p>
                  <div class="row">
                    <div class="col-12 col-md-6 col-lg-3">
                      <img
                        alt="Picked flowers in Colombia"
                        src="https://media.bunches.co.uk/content_pages/ethicalpage_1.jpg"
                        class="charity-images"
                      />
                    </div>
                    <div class="col-12 col-md-6 col-lg-3">
                      <img
                        alt="Flowers growing in Colombia"
                        src="https://media.bunches.co.uk/content_pages/ethicalpage_2.jpg"
                        class="charity-images"
                      />
                    </div>
                    <div class="col-12 col-md-6 col-lg-3">
                      <img
                        alt="Flowers just sprouting in a Colombian greenhouse"
                        src="https://media.bunches.co.uk/content_pages/ethicalpage_3.jpg"
                        class="charity-images"
                      />
                    </div>
                    <div class="col-12 col-md-6 col-lg-3">
                      <img
                        alt="Flowers being picked and inspected in Colombia"
                        src="https://media.bunches.co.uk/content_pages/ethicalpage_4.jpg"
                        class="charity-images"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </article> */}
          </div>
        </section>
      </main>
    </main>
  );
};

export default AboutUs;
