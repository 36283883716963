import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { updatePassword } from "../../../api/api";

const ForgotPassword2 = () => {
  const navigate = useNavigate();
  const [password, setPassword] = useState(null);
  const [togglePassword, setTogglePassword] = useState("password");
  const [signInError, setSignInError] = useState("");
  const location = useLocation();
  const email = location.state?.email;

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await updatePassword({ email, password });
      setSignInError(response.data.message);
      setTimeout(() => {
        if (response.data.message === "Password Has Been Updated!") {
          navigate(`/`);
        }
      }, 500);
    } catch (error) {
      console.log("error", error);
    }
  };
  return (
    <main id="main-container" class="container top-margin">
      <div class="nav-blackout"></div>
      <div class="login">
        <div class="row">
          <div class="col-12">
            <ul class="breadcrumb">
              <li>
                <a href="/">Home</a>
              </li>
              <li>Reset Password</li>
            </ul>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-6">
            <div class="header-title">Reset Password</div>
            <form onSubmit={handleFormSubmit} class="login-form mb-3">
              <div class="form-group">
                <label for="password" class="bn-label">
                  New Password
                </label>
                <input
                  id="password"
                  name="password"
                  type={togglePassword}
                  autocomplete="current-password"
                  class="form-control"
                  placeholder="New Password"
                  required=""
                  minlength="8"
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                />
                {togglePassword === "password" && (
                  <span
                    onClick={() => setTogglePassword("text")}
                    role="button"
                    aria-label="Show Password"
                    class="fa password-view-icon fa-eye"
                  ></span>
                )}
                {togglePassword === "text" && (
                  <span
                    onClick={() => setTogglePassword("password")}
                    role="button"
                    aria-label="Hide Password"
                    class="fa password-view-icon fa-eye-slash"
                  ></span>
                )}
                <div style={{ color: "red", fontSize: "15px" }}>
                  {signInError}
                </div>
              </div>
              <div class="form-buttons">
                <button type="submit" class="bn-button bn-button--primary">
                  Submit
                </button>
              </div>
            </form>
          </div>
          <div class="col-12 col-lg-6">
            <div class="password-tips">
              <h3 class="password-tips__title">Password Tips</h3>
              <ul class="password-tips__list">
                <li class="mb-2">
                  <strong>Make it difficult to guess</strong>
                  <br />
                  Avoid using names, common words or dates that are personal to
                  you, it might make it easy for someone else to guess.
                </li>
                <li class="mb-2">
                  <strong>Not too short</strong>
                  <br />
                  Whilst we ask for your password to be a minimum of 10
                  characters, the longer it is the harder it will be for someone
                  to guess.
                </li>
                <li class="mb-2">
                  <strong>Don't repeat yourself</strong>
                  <br />
                  Try to use a different password for all your online accounts
                  and make sure your Bunches password isn't one you already use
                  elsewhere.
                </li>
                <li class="mb-2">
                  <strong>Mix it up</strong>
                  <br />
                  Use a combination of letters, numbers, lowercase and uppercase
                  to add an extra level of security.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default ForgotPassword2;
