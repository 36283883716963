import React from "react";
import "./Footer.css";
import integrityFlowerLogo from "../../../src/assets/logos/Integrityflowers Transparent .png";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addCategoryName } from "../../features/Category/CategorySlice";

const Footer = () => {
  const dispatch = useDispatch();
  const categoriesData = useSelector((state) => state.category);
  return (
    <footer id="main-footer">
      <div class="Z_OID24OhybVaGPlHCp6">
        <div class="container pZlQaLelCxw9X2t6QJSJ">
          {/* <img
            alt="integrityFlowerLogo"
            src={integrityFlowerLogo}
            class="lpLY3bt2ftkoqgq_JiWR"
          /> */}
          <div class="Lk44NGmhjCZXd0S6HZ7k">
            <section class="Q003QSC3z5e_fZz9TdqF">
              <h3>About Integrity Flowers</h3>
              <p>
                Welcome to Integrity Flowers, where every bloom tells a story of
                beauty, grace, and heartfelt sentiments. We're more than just a
                flower shop; we're your companions in celebrating life's most
                precious moments. With a passion for petals and a commitment to
                excellence, we handpick each stem to ensure every arrangement
                reflects the uniqueness of your occasion. Whether it's a joyful
                celebration, a token of appreciation, or a moment of solace, let
                us weave nature's delicate wonders into your life's tapestry.
                Explore our floral treasures and let us help you craft
                unforgettable memories, one blossom at a time.
              </p>
              <Link
                to="/about-us"
                onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
              >
                Read more about us
              </Link>
            </section>
            <section class="grHbNkqmrZ_VnUWDfU0Q">
              <h3 class="MuFMpBs9LTYpVFMwxkUE">
                <Link
                  to={"/"}
                  onClick={() =>
                    window.scrollTo({ top: 0, behavior: "smooth" })
                  }
                >
                  Categories
                </Link>
              </h3>
              <nav class="ynPiXLfyFJinsWmRpkIR">
                {categoriesData?.categoryWithParentValue?.map((cat) => {
                  return (
                    <Link
                      key={cat?.id}
                      to={`/category/flowers/${cat?.id}`}
                      onClick={() => {
                        window.scrollTo({ top: 0, behavior: "smooth" });
                        dispatch(addCategoryName(cat?.category_name));
                      }}
                    >
                      {cat?.category_name}
                    </Link>
                  );
                })}
                {/* <Link to="/delivery">Delivery</Link>
                <Link to="/posy-points">Posy Points™</Link>
                <Link to="/refer-a-friend">Refer a Friend</Link>
                <Link to="/faq">FAQ</Link>
                <Link to="/terms">Terms &amp; Privacy</Link>
                <Link to="/affiliates">Become an Affiliate</Link>
                <Link to="/corporate-gifts">Corporate Gifts</Link>
                <Link to="/returns">Returns Policy</Link> */}
              </nav>
            </section>
            <section class="grHbNkqmrZ_VnUWDfU0Q">
              <h3 class="MuFMpBs9LTYpVFMwxkUE">
                <Link
                  to={"/"}
                  onClick={() =>
                    window.scrollTo({ top: 0, behavior: "smooth" })
                  }
                >
                  More Categories
                </Link>
              </h3>
              <nav class="ynPiXLfyFJinsWmRpkIR">
                {categoriesData?.categoryWithParentValue.map(
                  (parentCategory, i) => {
                    return categoriesData?.category.map((cat, i) => {
                      if (parentCategory?.id === cat?.parent_category?.id) {
                        return (
                          <Link
                            key={i}
                            to={`/sub-category/flowers/${cat.id}`}
                            onClick={() => {
                              window.scrollTo({ top: 0, behavior: "smooth" });
                            }}
                          >
                            {cat?.category_name}
                          </Link>
                        );
                      }
                    });
                  }
                )}
                {/* <Link to="/about-us#history">Our History</Link>
                <Link to="/why-choose-bunches">
                  Why Choose Integrity Flowers?
                </Link>
                <Link to="/about-us#flowers">Ethical Flowers</Link>
                <Link to="/about-us#charity">Making a Difference</Link>
                <Link to="/journal">Integrity Flowers' Journal</Link>
                <Link to="/flower-care">Flower Care &amp; Advice</Link>
                <Link to="/plant-care">Plant Care &amp; Advice</Link>
                <Link to="/sitemap">Sitemap</Link> */}
              </nav>
            </section>
            <section class="grHbNkqmrZ_VnUWDfU0Q">
              <h3 class="MuFMpBs9LTYpVFMwxkUE">
                <Link
                  onClick={() =>
                    window.scrollTo({ top: 0, behavior: "smooth" })
                  }
                  to="/about-us"
                >
                  Integrity Flowers
                </Link>
              </h3>
              <nav class="ynPiXLfyFJinsWmRpkIR">
                <Link
                  onClick={() =>
                    window.scrollTo({ top: 0, behavior: "smooth" })
                  }
                  to="/about-us"
                >
                  About Us
                </Link>
                <Link
                  onClick={() =>
                    window.scrollTo({ top: 0, behavior: "smooth" })
                  }
                  to="/contact-us"
                >
                  Contact Us
                </Link>
                <Link
                  onClick={() =>
                    window.scrollTo({ top: 0, behavior: "smooth" })
                  }
                  to="/terms-and-conditions"
                >
                  Terms and Conditions
                </Link>
                <Link
                  onClick={() =>
                    window.scrollTo({ top: 0, behavior: "smooth" })
                  }
                  to="/privacy-policy"
                >
                  Privacy Policy
                </Link>
                <Link
                  onClick={() =>
                    window.scrollTo({ top: 0, behavior: "smooth" })
                  }
                  to="/cookie-policy"
                >
                  Cookie Policy
                </Link>
                {/* <Link to="/category/christmas-flowers">Christmas Flowers</Link>
                <Link to="/category/valentines-flowers">
                  Valentine's Day Flowers
                </Link>
                <Link to="/category/birthday-flowers-and-gifts">
                  Birthday Flowers
                </Link>
                <Link to="/category/hampers">Hamper Delivery</Link>
                <Link to="/category/christmas-hampers">Christmas Hampers</Link> */}
              </nav>
            </section>
            <section>
              <h3 class="MuFMpBs9LTYpVFMwxkUE">
                <Link
                  to="/contact-us"
                  onClick={() =>
                    window.scrollTo({ top: 0, behavior: "smooth" })
                  }
                >
                  Get in Touch
                </Link>
              </h3>
              <nav class="ynPiXLfyFJinsWmRpkIR">
                <Link to="/contact-us">Contact Customer Service</Link>
                <Link rel="nofollow" to="mailto:help@integrityflowers.co.uk">
                  help@integrityflowers.co.uk
                </Link>
                {/* <Link rel="nofollow" to="tel:+44 20 8123 7938">
                  +44 20 8123 7938
                </Link> */}
                <Link rel="nofollow" to="tel:+44 20 8123 7938">
                  +44 20 8123 7938 (International)
                </Link>
              </nav>
              <div class="T5T3rVkXXFyouzwQjBbt">
                <nav class="tewpGBhhumTfB2_NQB6x">
                  <Link
                    aria-label="Facebook"
                    to="#"
                    rel="noopener"
                    style={{ position: "relative", top: "2px" }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      fill="currentColor"
                      class="bi bi-facebook"
                      viewBox="0 0 16 16"
                    >
                      <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951" />
                    </svg>
                  </Link>
                  <Link
                    aria-label="Twitter"
                    to="#"
                    rel="noopener"
                    style={{ position: "relative", top: "2px" }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      fill="currentColor"
                      class="bi bi-twitter"
                      viewBox="0 0 16 16"
                    >
                      <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334q.002-.211-.006-.422A6.7 6.7 0 0 0 16 3.542a6.7 6.7 0 0 1-1.889.518 3.3 3.3 0 0 0 1.447-1.817 6.5 6.5 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.32 9.32 0 0 1-6.767-3.429 3.29 3.29 0 0 0 1.018 4.382A3.3 3.3 0 0 1 .64 6.575v.045a3.29 3.29 0 0 0 2.632 3.218 3.2 3.2 0 0 1-.865.115 3 3 0 0 1-.614-.057 3.28 3.28 0 0 0 3.067 2.277A6.6 6.6 0 0 1 .78 13.58a6 6 0 0 1-.78-.045A9.34 9.34 0 0 0 5.026 15" />
                    </svg>
                  </Link>
                  <Link
                    aria-label="LinkedIn"
                    to="#"
                    rel="noopener"
                    style={{ position: "relative", top: "2px" }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      fill="currentColor"
                      class="bi bi-linkedin"
                      viewBox="0 0 16 16"
                    >
                      <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854zm4.943 12.248V6.169H2.542v7.225zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248S2.4 3.226 2.4 3.934c0 .694.521 1.248 1.327 1.248zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016l.016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225z" />
                    </svg>
                  </Link>
                </nav>
                <nav class="tewpGBhhumTfB2_NQB6x">
                  <Link
                    aria-label="Youtube"
                    to="#"
                    rel="noopener"
                    style={{ position: "relative", top: "2px" }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      fill="currentColor"
                      class="bi bi-youtube"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.01 2.01 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.01 2.01 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31 31 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.01 2.01 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A100 100 0 0 1 7.858 2zM6.4 5.209v4.818l4.157-2.408z" />
                    </svg>
                  </Link>
                  <Link aria-label="Pinterest" to="#" rel="noopener">
                    <svg
                      height="30"
                      width="30"
                      version="1.1"
                      id="Layer_1"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 511.998 511.998"
                      fill="#696969"
                      stroke="#696969"
                    >
                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                        {" "}
                        <path
                          style={{ fill: "currentColor" }}
                          d="M405.017,52.467C369.774,18.634,321.001,0,267.684,0C186.24,0,136.148,33.385,108.468,61.39 c-34.114,34.513-53.675,80.34-53.675,125.732c0,56.993,23.839,100.737,63.76,117.011c2.68,1.098,5.377,1.651,8.021,1.651 c8.422,0,15.095-5.511,17.407-14.35c1.348-5.071,4.47-17.582,5.828-23.013c2.906-10.725,0.558-15.884-5.78-23.353 c-11.546-13.662-16.923-29.817-16.923-50.842c0-62.451,46.502-128.823,132.689-128.823c68.386,0,110.866,38.868,110.866,101.434 c0,39.482-8.504,76.046-23.951,102.961c-10.734,18.702-29.609,40.995-58.585,40.995c-12.53,0-23.786-5.147-30.888-14.121 c-6.709-8.483-8.921-19.441-6.222-30.862c3.048-12.904,7.205-26.364,11.228-39.376c7.337-23.766,14.273-46.213,14.273-64.122 c0-30.632-18.832-51.215-46.857-51.215c-35.616,0-63.519,36.174-63.519,82.354c0,22.648,6.019,39.588,8.744,46.092 c-4.487,19.01-31.153,132.03-36.211,153.342c-2.925,12.441-20.543,110.705,8.618,118.54c32.764,8.803,62.051-86.899,65.032-97.713 c2.416-8.795,10.869-42.052,16.049-62.495c15.817,15.235,41.284,25.535,66.064,25.535c46.715,0,88.727-21.022,118.298-59.189 c28.679-37.02,44.474-88.618,44.474-145.282C457.206,127.983,438.182,84.311,405.017,52.467z"
                        ></path>{" "}
                      </g>
                    </svg>
                  </Link>
                  <Link
                    aria-label="Instagram"
                    to="#"
                    rel="noopener"
                    style={{ position: "relative", top: "2px" }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      fill="currentColor"
                      class="bi bi-instagram"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.9 3.9 0 0 0-1.417.923A3.9 3.9 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.9 3.9 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.9 3.9 0 0 0-.923-1.417A3.9 3.9 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599s.453.546.598.92c.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.5 2.5 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.5 2.5 0 0 1-.92-.598 2.5 2.5 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233s.008-2.388.046-3.231c.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92s.546-.453.92-.598c.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92m-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217m0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334" />
                    </svg>
                  </Link>
                  <Link
                    style={{ position: "relative", top: "2px" }}
                    aria-label="Whatsapp"
                    to="#"
                    rel="noopener"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      fill="currentColor"
                      class="bi bi-whatsapp"
                      viewBox="0 0 16 16"
                    >
                      <path d="M13.601 2.326A7.85 7.85 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.9 7.9 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.9 7.9 0 0 0 13.6 2.326zM7.994 14.521a6.6 6.6 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.56 6.56 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592m3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.73.73 0 0 0-.529.247c-.182.198-.691.677-.691 1.654s.71 1.916.81 2.049c.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232" />
                    </svg>
                  </Link>
                </nav>
              </div>
            </section>
            <section class="KlukaJjU2LRIHv4SJRAZ">
              <div class="reBaywUzy4bXCl9LfrcU">
                <img
                  alt="Visa icon"
                  src="https://media.bunches.co.uk//icons/payment_Visa.svg"
                />
              </div>
              <div class="reBaywUzy4bXCl9LfrcU">
                <img
                  alt="Apple pay icon"
                  src="https://media.bunches.co.uk//icons/payment_ApplePay.svg"
                />
              </div>
              <div class="reBaywUzy4bXCl9LfrcU">
                <img
                  alt="Google pay icon"
                  src="https://media.bunches.co.uk//icons/payment_GooglePay.svg"
                />
              </div>
              <div class="reBaywUzy4bXCl9LfrcU">
                <img
                  alt="Mastercard icon"
                  src="https://media.bunches.co.uk//icons/payment_Mastercard.svg"
                />
              </div>
              <div class="reBaywUzy4bXCl9LfrcU">
                <img
                  alt="PayPal icon"
                  src="https://media.bunches.co.uk//icons/payment_PayPal.svg"
                />
              </div>
            </section>
          </div>
          <div class="M0W95Cj5DyaZXLegR0Kw">
            © Copyright <em>Integrity Flowers</em> All rights reserved.
            {/* <img
              src="https://media.bunches.co.uk/Icthyus_white.svg"
              alt="Ichthys"
              class="lLQwampIpxU6DFCPAZRB"
              width="30"
              height="40"
            /> */}
            <br />
            <Link target="_blank" to={"https://infikeytechnologies.com/"}>
              <div className="">
                Designed and Developed by Infikey Technologies LLC
              </div>
            </Link>
            {/* Registered in England and Wales: 3924234
            <span class="E0ixtcw2iR6I4_ydV8UN"></span> VAT Reg.: 509377820
            <span class="E0ixtcw2iR6I4_ydV8UN"></span> Data Protection Reg.:
            Z4831682 */}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
