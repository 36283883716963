import React, { useEffect, useState } from "react";
import FlowersCategory from "./FlowersCategory/FlowersCategory.js";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { closeMenubar } from "../../../../features/menuBar/menubarSlice.js";
import { addCategoryName } from "../../../../features/Category/CategorySlice.js";

const CategoryList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userToken = useSelector((state) => state.user.token);
  const [token, setToken] = useState(null);
  const menubarView = useSelector((state) => state.menubar.menubarView);
  const categoryState = useSelector((state) => state.category);

  const [isThereSubCat, setIsThereSubCat] = useState(false);
  const [flowers, setFlowers] = useState(false);
  const [housePlants, setHousePlants] = useState(false);
  const [hampers, setHampers] = useState(false);
  const [sendGreetingsCard, setSendGreetingsCard] = useState(false);

  useEffect(() => {
    setToken(userToken);
  }, [userToken]);

  const handleClick = (value) => {
    if (value === "Flowers") {
      setFlowers(!flowers);
    }
    if (value === "House Plants") {
      setHousePlants(!housePlants);
    }
    if (value === "Hampers") {
      setHampers(!hampers);
    }
    if (value === "Send a Greetings Card") {
      setSendGreetingsCard(!sendGreetingsCard);
    }
  };

  const getSubCategory = (parentCategory) => {
    if (!categoryState?.category) {
      return [];
    }
    return categoryState?.category.filter(
      (cat) => parentCategory?.id === cat?.parent_category?.id
    );
  };

  const handleMouseEnter = (parentCategory) => {
    if (!categoryState?.category) {
      return [];
    }
    const categoryFiltered = categoryState?.category.filter(
      (cat) => parentCategory?.id === cat?.parent_category?.id
    );
    if (categoryFiltered.length > 0) {
      setIsThereSubCat(true);
      return categoryFiltered;
    } else {
      setIsThereSubCat(false);
      return [];
    }
  };

  const handleSignOutClick = () => {
    Cookies.remove("token");
    localStorage.removeItem("user");
    window.location.href = "/";
  };

  useEffect(() => {
    const token = Cookies.get("token");
    setToken(token);
  }, []);

  const handleLoginClick = () => {
    dispatch(closeMenubar());
    navigate("/login");
  };

  return (
    <nav class={`navigation ${menubarView === "open" && "active"}`}>
      {categoryState?.categoryWithParentValue &&
        categoryState?.categoryWithParentValue.map((cat, i) => {
          const subCat = getSubCategory(cat);
          if (cat.id === 5) {
            return (
              <div
                key={i}
                onClick={() => {
                  dispatch(closeMenubar());
                  dispatch(addCategoryName(cat?.category_name));
                }}
                class="item has-children"
              >
                <Link
                  onMouseEnter={() => handleMouseEnter(cat)}
                  to={`/category/flowers/${cat.id}`}
                >
                  <span style={{ fontWeight: "bold" }}>
                    {cat?.category_name}
                  </span>
                </Link>
                <span
                  onClick={() => handleClick(cat)}
                  class={`${flowers ? "item-less" : "item-more"}`}
                  aria-label="Toggle sub-menu"
                ></span>
                {subCat.length > 0 && (
                  <FlowersCategory
                    parentCategory={cat}
                    category={categoryState?.category}
                    flowers={flowers}
                  />
                )}
              </div>
            );
          }
        })}

      {categoryState?.categoryWithParentValue &&
        categoryState?.categoryWithParentValue.map((cat, i) => {
          const subCat = getSubCategory(cat);
          if (cat.id === 2) {
            return (
              <div
                key={i}
                onClick={() => {
                  dispatch(closeMenubar());
                  dispatch(addCategoryName(cat?.category_name));
                }}
                class="item has-children"
              >
                <Link
                  onMouseEnter={() => handleMouseEnter(cat)}
                  to={`/category/flowers/${cat.id}`}
                >
                  {cat?.category_name}
                </Link>
                <span
                  onClick={() => handleClick(cat)}
                  class={`${flowers ? "item-less" : "item-more"}`}
                  aria-label="Toggle sub-menu"
                ></span>
                {subCat.length > 0 && (
                  <FlowersCategory
                    parentCategory={cat}
                    category={categoryState?.category}
                    flowers={flowers}
                  />
                )}
              </div>
            );
          }
        })}

      {categoryState?.categoryWithParentValue &&
        categoryState?.categoryWithParentValue.map((cat, i) => {
          const subCat = getSubCategory(cat);
          if (cat.id === 6) {
            return (
              <div
                key={i}
                onClick={() => {
                  dispatch(closeMenubar());
                  dispatch(addCategoryName(cat?.category_name));
                }}
                class="item has-children"
              >
                <Link
                  onMouseEnter={() => handleMouseEnter(cat)}
                  to={`/category/flowers/${cat.id}`}
                >
                  {cat?.category_name}
                </Link>
                <span
                  onClick={() => handleClick(cat)}
                  class={`${flowers ? "item-less" : "item-more"}`}
                  aria-label="Toggle sub-menu"
                ></span>
                {subCat.length > 0 && (
                  <FlowersCategory
                    parentCategory={cat}
                    category={categoryState?.category}
                    flowers={flowers}
                  />
                )}
              </div>
            );
          }
        })}

      {categoryState?.categoryWithParentValue &&
        categoryState?.categoryWithParentValue.map((cat, i) => {
          const subCat = getSubCategory(cat);
          if (cat.id === 4) {
            return (
              <div
                key={i}
                onClick={() => {
                  dispatch(closeMenubar());
                  dispatch(addCategoryName(cat?.category_name));
                }}
                class="item has-children"
              >
                <Link
                  onMouseEnter={() => handleMouseEnter(cat)}
                  to={`/category/flowers/${cat.id}`}
                >
                  {cat?.category_name}
                </Link>
                <span
                  onClick={() => handleClick(cat)}
                  class={`${flowers ? "item-less" : "item-more"}`}
                  aria-label="Toggle sub-menu"
                ></span>
                {subCat.length > 0 && (
                  <FlowersCategory
                    parentCategory={cat}
                    category={categoryState?.category}
                    flowers={flowers}
                  />
                )}
              </div>
            );
          }
        })}

      {categoryState?.categoryWithParentValue &&
        categoryState?.categoryWithParentValue.map((cat, i) => {
          const subCat = getSubCategory(cat);
          if (cat.id === 1) {
            return (
              <div
                key={i}
                onClick={() => {
                  dispatch(closeMenubar());
                  dispatch(addCategoryName(cat?.category_name));
                }}
                class="item has-children"
              >
                <Link
                  onMouseEnter={() => handleMouseEnter(cat)}
                  to={`/category/flowers/${cat.id}`}
                >
                  {cat?.category_name}
                </Link>
                <span
                  onClick={() => handleClick(cat)}
                  class={`${flowers ? "item-less" : "item-more"}`}
                  aria-label="Toggle sub-menu"
                ></span>
                {subCat.length > 0 && (
                  <FlowersCategory
                    parentCategory={cat}
                    category={categoryState?.category}
                    flowers={flowers}
                  />
                )}
              </div>
            );
          }
        })}

      {/* <div class="item">
        <a href="/category/letterbox-flowers">Letterbox Flowers</a>
      </div>
      <div class="item">
        <a href="/category/hand-tied">Hand Tied Bouquets</a>
      </div>
      <div class="item">
        <a href="/category/birthday-flowers-and-gifts">Birthday Flowers</a>
      </div>
      <div class="item has-children">
        <a href="/category/house-plants">House Plants</a>
        <span
          onClick={() => handleClick("House Plants")}
          class={`${housePlants ? "item-less" : "item-more"}`}
          aria-label="Toggle sub-menu"
        ></span>
        <div class={`children pane-a ${housePlants && "active"}`}>
          <HousePlantCategory />
        </div>
      </div>
      <div class="item has-children">
        <a href="/hamper-delivery">Hampers</a>
        <span
          onClick={() => handleClick("Hampers")}
          class={`${hampers ? "item-less" : "item-more"}`}
          aria-label="Toggle sub-menu"
        ></span>
        <div class={`children pane-a ${hampers && "active"}`}>
          <HampersCategory />
        </div>
      </div>
      <div class="item has-children">
        <a href="/greetings-cards">Send a Greetings Card</a>
        <span
          onClick={() => handleClick("Send a Greetings Card")}
          class={`${sendGreetingsCard ? "item-less" : "item-more"}`}
          aria-label="Toggle sub-menu"
        ></span>
        <div class={`children pane-a ${sendGreetingsCard && "active"}`}>
          <SendAGreetingsCard />
        </div>
      </div> */}
      {token && (
        <>
          <div class="item mobile-only">
            <Link to="/account">My Account</Link>
          </div>
          <div onClick={handleSignOutClick} class="item mobile-only">
            <Link to="/">Sign Out</Link>
          </div>
        </>
      )}

      {!token && (
        <div onClick={handleLoginClick} class="item mobile-only">
          <a>Login</a>
        </div>
      )}

      {isThereSubCat && <div class="background"></div>}
    </nav>
  );
};

export default CategoryList;
