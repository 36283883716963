import React from "react";

const CategoryGreetingsCards = () => {
  return (
    <main id="main-container" class="container top-margin fluid-container">
      <div class="nav-blackout"></div>
      <div class="container category-container">
        <div class="row">
          <div class="col-12">
            <ul class="breadcrumb">
              <li>
                <a href="/">Home</a>
              </li>
              <li>Greetings Cards</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="GaD3PutklFLa1Ce7QveA">
        <div class="_4BkD6cjqiDEu0K_gAf8">
          <div class="OUAkuAPi_wDt1Kzk__A5">
            <h2>Greetings Cards</h2>
            <p class="OcOTK_QHladYjPMtyUC_">
              Send a greetings card, delivered directly to your loved ones door.
              £2.25 for an A6 card, £2.95 for an A5 card. UK delivery from £1.
            </p>
            <a class="EDWOI__Y5vInewCoS3bh" href="/category/flowers">
              Click here to send blooms too
            </a>
          </div>
          <div class="nP6Z9L2nkWAy2AFzpxrX">
            <a class="GarNbybP7QAkPSpY0YY1" href="/category/birthday-cards">
              <img
                src="https://media.bunches.co.uk/content_pages/card_categories/card-checkout-icon-bday.jpg"
                width="144"
                height="144"
                alt="Birthday"
              />
              <p>Birthday</p>
            </a>
            <a class="GarNbybP7QAkPSpY0YY1" href="/category/anniversary-cards">
              <img
                src="https://media.bunches.co.uk/content_pages/card_categories/card-checkout-icon-anniversary.jpg"
                width="144"
                height="144"
                alt="Anniversary"
              />
              <p>Anniversary</p>
            </a>
            <a class="GarNbybP7QAkPSpY0YY1" href="/category/personalised-cards">
              <img
                src="https://media.bunches.co.uk/content_pages/card_categories/card-checkout-icon-personalised.jpg"
                width="144"
                height="144"
                alt="Personalised"
              />
              <p>Personalised</p>
            </a>
          </div>
        </div>
      </div>
      <div class="_4vk15NQ3FIPNmomHEOsH">
        <div class="e3lG3qAw9gGCpPono6RY">
          <div class="B55lhTMsuIL3oL7wbArO">
            <input placeholder="Search and filter cards" value="" />
            <span>
              <i class="fa fa-search" aria-hidden="true"></i>
              <svg
                width="13"
                height="15"
                viewBox="0 0 15 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 11C0.585786 11 0.25 11.3358 0.25 11.75C0.25 12.1642 0.585786 12.5 1 12.5V11ZM4 12.5H4.75V11H4V12.5ZM1 12.5H4V11H1V12.5Z"
                  fill="#78858D"
                ></path>
                <circle cx="5.75" cy="11.75" r="1.25" stroke="#78858D"></circle>
                <path
                  d="M13.7002 12.5C14.1144 12.5 14.4502 12.1642 14.4502 11.75C14.4502 11.3358 14.1144 11 13.7002 11L13.7002 12.5ZM7.5 11L6.75 11L6.75 12.5L7.5 12.5L7.5 11ZM13.7002 11L7.5 11L7.5 12.5L13.7002 12.5L13.7002 11Z"
                  fill="#78858D"
                ></path>
                <path
                  d="M1 6C0.585786 6 0.25 6.33579 0.25 6.75C0.25 7.16421 0.585786 7.5 1 7.5V6ZM7.5 7.5H8.25V6H7.5V7.5ZM1 7.5H7.5V6H1V7.5Z"
                  fill="#78858D"
                ></path>
                <circle
                  cx="9.3501"
                  cy="6.75"
                  r="1.25"
                  stroke="#78858D"
                ></circle>
                <path
                  d="M13.7002 7.5C14.1144 7.5 14.4502 7.16421 14.4502 6.75C14.4502 6.33579 14.1144 6 13.7002 6L13.7002 7.5ZM11.5 6L10.75 6L10.75 7.5L11.5 7.5L11.5 6ZM13.7002 6L11.5 6L11.5 7.5L13.7002 7.5L13.7002 6Z"
                  fill="#78858D"
                ></path>
                <path
                  d="M13.7002 2.5C14.1144 2.5 14.4502 2.16421 14.4502 1.75C14.4502 1.33579 14.1144 1 13.7002 1L13.7002 2.5ZM6.1002 1L5.35019 1L5.35019 2.5L6.1002 2.5L6.1002 1ZM13.7002 1L6.1002 1L6.1002 2.5L13.7002 2.5L13.7002 1Z"
                  fill="#78858D"
                ></path>
                <circle
                  cx="4.3501"
                  cy="1.75"
                  r="1.25"
                  transform="rotate(180 4.3501 1.75)"
                  stroke="#78858D"
                ></circle>
                <path
                  d="M1 1C0.585786 1 0.25 1.33579 0.25 1.75C0.25 2.16421 0.585787 2.5 1 2.5L1 1ZM2.6 2.5L3.35 2.5L3.35 1L2.6 1L2.6 2.5ZM1 2.5L2.6 2.5L2.6 1L1 1L1 2.5Z"
                  fill="#78858D"
                ></path>
              </svg>
            </span>
          </div>
        </div>
        <div class="IDlse2PSr3lX2u9MQDSw" aria-hidden="true">
          <div class="ea9bHoSibbeCdcT4Tn_u">
            <div class="RwhUnoApef0jmhuiRq2U">
              <h2>Filter and Search</h2>
              <svg
                width="15"
                height="15"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="iypBTquhLXq6EjU8SQnA"
                role="button"
                aria-label="Close Modal"
                tabindex="0"
              >
                <title>Close Modal</title>
                <path
                  d="M18 1L2 17"
                  stroke="#78858D"
                  strokeWidth="2"
                  strokeLinecap="round"
                ></path>
                <path
                  d="M2 1L18 17"
                  stroke="#78858D"
                  strokeWidth="2"
                  strokeLinecap="round"
                ></path>
              </svg>
            </div>
            <div class="GaD3PutklFLa1Ce7QveA">
              <h3>Popular Occasions</h3>
              <div class="nP6Z9L2nkWAy2AFzpxrX">
                <a class="GarNbybP7QAkPSpY0YY1" href="/category/birthday-cards">
                  <img
                    src="https://media.bunches.co.uk/content_pages/card_categories/card-checkout-icon-bday.jpg"
                    width="144"
                    height="144"
                    alt="Birthday"
                  />
                  <p>Birthday</p>
                </a>
                <a
                  class="GarNbybP7QAkPSpY0YY1"
                  href="/category/anniversary-cards"
                >
                  <img
                    src="https://media.bunches.co.uk/content_pages/card_categories/card-checkout-icon-anniversary.jpg"
                    width="144"
                    height="144"
                    alt="Anniversary"
                  />
                  <p>Anniversary</p>
                </a>
                <a
                  class="GarNbybP7QAkPSpY0YY1"
                  href="/category/personalised-cards"
                >
                  <img
                    src="https://media.bunches.co.uk/content_pages/card_categories/card-checkout-icon-personalised.jpg"
                    width="144"
                    height="144"
                    alt="Personalised"
                  />
                  <p>Personalised</p>
                </a>
              </div>
            </div>
            <div class="pLzRulkPzi0FAI0MqCp_">
              <div class="u9xaw3UiyVSo4yxGhfa9">
                <p>
                  Occasion<i class="fa fa-angle-right"></i>
                </p>
                <div class="KuetYcEHnyki6DohT3e5" hidden="">
                  <div class="hJUdamZF7Xruv1WN61xg" role="button">
                    All Occasions
                  </div>
                  <div class="hJUdamZF7Xruv1WN61xg" role="button">
                    Anniversary
                  </div>
                  <div class="hJUdamZF7Xruv1WN61xg" role="button">
                    Birthday
                  </div>
                  <div class="hJUdamZF7Xruv1WN61xg" role="button">
                    Christmas
                  </div>
                  <div class="hJUdamZF7Xruv1WN61xg" role="button">
                    Congratulations
                  </div>
                  <div class="hJUdamZF7Xruv1WN61xg" role="button">
                    Easter
                  </div>
                  <div class="hJUdamZF7Xruv1WN61xg" role="button">
                    Especially for You
                  </div>
                  <div class="hJUdamZF7Xruv1WN61xg" role="button">
                    Father's Day
                  </div>
                  <div class="hJUdamZF7Xruv1WN61xg" role="button">
                    Friendship
                  </div>
                  <div class="hJUdamZF7Xruv1WN61xg" role="button">
                    Get Well Soon
                  </div>
                  <div class="hJUdamZF7Xruv1WN61xg" role="button">
                    Good Luck
                  </div>
                  <div class="hJUdamZF7Xruv1WN61xg" role="button">
                    Graduation
                  </div>
                  <div class="hJUdamZF7Xruv1WN61xg" role="button">
                    Leaving
                  </div>
                  <div class="hJUdamZF7Xruv1WN61xg" role="button">
                    Missing You
                  </div>
                  <div class="hJUdamZF7Xruv1WN61xg" role="button">
                    Mother's Day
                  </div>
                  <div class="hJUdamZF7Xruv1WN61xg" role="button">
                    New Baby
                  </div>
                  <div class="hJUdamZF7Xruv1WN61xg" role="button">
                    New Home
                  </div>
                  <div class="hJUdamZF7Xruv1WN61xg" role="button">
                    New Job
                  </div>
                  <div class="hJUdamZF7Xruv1WN61xg" role="button">
                    New Year
                  </div>
                  <div class="hJUdamZF7Xruv1WN61xg" role="button">
                    Newly Engaged
                  </div>
                  <div class="hJUdamZF7Xruv1WN61xg" role="button">
                    Retirement
                  </div>
                  <div class="hJUdamZF7Xruv1WN61xg" role="button">
                    Romance
                  </div>
                  <div class="hJUdamZF7Xruv1WN61xg" role="button">
                    Sorry
                  </div>
                  <div class="hJUdamZF7Xruv1WN61xg" role="button">
                    Sympathy
                  </div>
                  <div class="hJUdamZF7Xruv1WN61xg" role="button">
                    Thank You
                  </div>
                  <div class="hJUdamZF7Xruv1WN61xg" role="button">
                    Thinking of You
                  </div>
                  <div class="hJUdamZF7Xruv1WN61xg" role="button">
                    Valentine's Day
                  </div>
                  <div class="hJUdamZF7Xruv1WN61xg" role="button">
                    Wedding
                  </div>
                  <div class="hJUdamZF7Xruv1WN61xg" role="button">
                    Welcome Home
                  </div>
                  <div class="hJUdamZF7Xruv1WN61xg" role="button">
                    Well Done
                  </div>
                </div>
              </div>
              <div class="u9xaw3UiyVSo4yxGhfa9">
                <p>
                  Recipient<i class="fa fa-angle-right"></i>
                </p>
                <div class="KuetYcEHnyki6DohT3e5" hidden="">
                  <div class="hJUdamZF7Xruv1WN61xg" role="button">
                    Auntie
                  </div>
                  <div class="hJUdamZF7Xruv1WN61xg" role="button">
                    Boyfriend
                  </div>
                  <div class="hJUdamZF7Xruv1WN61xg" role="button">
                    Brother
                  </div>
                  <div class="hJUdamZF7Xruv1WN61xg" role="button">
                    Dad
                  </div>
                  <div class="hJUdamZF7Xruv1WN61xg" role="button">
                    Daughter
                  </div>
                  <div class="hJUdamZF7Xruv1WN61xg" role="button">
                    Female Birthday
                  </div>
                  <div class="hJUdamZF7Xruv1WN61xg" role="button">
                    Fiancee
                  </div>
                  <div class="hJUdamZF7Xruv1WN61xg" role="button">
                    Friend
                  </div>
                  <div class="hJUdamZF7Xruv1WN61xg" role="button">
                    Girlfriend
                  </div>
                  <div class="hJUdamZF7Xruv1WN61xg" role="button">
                    Grandparents
                  </div>
                  <div class="hJUdamZF7Xruv1WN61xg" role="button">
                    Husband
                  </div>
                  <div class="hJUdamZF7Xruv1WN61xg" role="button">
                    Male Birthday
                  </div>
                  <div class="hJUdamZF7Xruv1WN61xg" role="button">
                    Mum
                  </div>
                  <div class="hJUdamZF7Xruv1WN61xg" role="button">
                    New Dad
                  </div>
                  <div class="hJUdamZF7Xruv1WN61xg" role="button">
                    New Mum
                  </div>
                  <div class="hJUdamZF7Xruv1WN61xg" role="button">
                    Sister
                  </div>
                  <div class="hJUdamZF7Xruv1WN61xg" role="button">
                    Son
                  </div>
                  <div class="hJUdamZF7Xruv1WN61xg" role="button">
                    Uncle
                  </div>
                  <div class="hJUdamZF7Xruv1WN61xg" role="button">
                    Wife
                  </div>
                </div>
              </div>
              <div class="u9xaw3UiyVSo4yxGhfa9">
                <p>
                  Birthday Ages<i class="fa fa-angle-right"></i>
                </p>
                <div class="KuetYcEHnyki6DohT3e5" hidden="">
                  <div class="hJUdamZF7Xruv1WN61xg" role="button">
                    18th
                  </div>
                  <div class="hJUdamZF7Xruv1WN61xg" role="button">
                    20th
                  </div>
                  <div class="hJUdamZF7Xruv1WN61xg" role="button">
                    21st
                  </div>
                  <div class="hJUdamZF7Xruv1WN61xg" role="button">
                    25th
                  </div>
                  <div class="hJUdamZF7Xruv1WN61xg" role="button">
                    30th
                  </div>
                  <div class="hJUdamZF7Xruv1WN61xg" role="button">
                    40th
                  </div>
                  <div class="hJUdamZF7Xruv1WN61xg" role="button">
                    55th
                  </div>
                  <div class="hJUdamZF7Xruv1WN61xg" role="button">
                    50th
                  </div>
                  <div class="hJUdamZF7Xruv1WN61xg" role="button">
                    60th
                  </div>
                  <div class="hJUdamZF7Xruv1WN61xg" role="button">
                    65th
                  </div>
                  <div class="hJUdamZF7Xruv1WN61xg" role="button">
                    70th
                  </div>
                  <div class="hJUdamZF7Xruv1WN61xg" role="button">
                    75th
                  </div>
                  <div class="hJUdamZF7Xruv1WN61xg" role="button">
                    80th
                  </div>
                  <div class="hJUdamZF7Xruv1WN61xg" role="button">
                    90th
                  </div>
                  <div class="hJUdamZF7Xruv1WN61xg" role="button">
                    100th
                  </div>
                </div>
              </div>
              <div class="u9xaw3UiyVSo4yxGhfa9">
                <p>
                  Anniversaries<i class="fa fa-angle-right"></i>
                </p>
                <div class="KuetYcEHnyki6DohT3e5" hidden="">
                  <div class="hJUdamZF7Xruv1WN61xg" role="button">
                    1st
                  </div>
                  <div class="hJUdamZF7Xruv1WN61xg" role="button">
                    10th
                  </div>
                  <div class="hJUdamZF7Xruv1WN61xg" role="button">
                    15th
                  </div>
                  <div class="hJUdamZF7Xruv1WN61xg" role="button">
                    20th
                  </div>
                  <div class="hJUdamZF7Xruv1WN61xg" role="button">
                    25th
                  </div>
                  <div class="hJUdamZF7Xruv1WN61xg" role="button">
                    30th
                  </div>
                  <div class="hJUdamZF7Xruv1WN61xg" role="button">
                    40th
                  </div>
                  <div class="hJUdamZF7Xruv1WN61xg" role="button">
                    50th
                  </div>
                  <div class="hJUdamZF7Xruv1WN61xg" role="button">
                    60th
                  </div>
                </div>
              </div>
              <div class="u9xaw3UiyVSo4yxGhfa9">
                <p>
                  Style<i class="fa fa-angle-right"></i>
                </p>
                <div class="KuetYcEHnyki6DohT3e5" hidden="">
                  <div class="hJUdamZF7Xruv1WN61xg" role="button">
                    Animals
                  </div>
                  <div class="hJUdamZF7Xruv1WN61xg" role="button">
                    Artistic
                  </div>
                  <div class="hJUdamZF7Xruv1WN61xg" role="button">
                    Cute
                  </div>
                  <div class="hJUdamZF7Xruv1WN61xg" role="button">
                    Humourous
                  </div>
                  <div class="hJUdamZF7Xruv1WN61xg" role="button">
                    Illustrative
                  </div>
                  <div class="hJUdamZF7Xruv1WN61xg" role="button">
                    Photographic
                  </div>
                  <div class="hJUdamZF7Xruv1WN61xg" role="button">
                    Romantic
                  </div>
                  <div class="hJUdamZF7Xruv1WN61xg" role="button">
                    Sympathy
                  </div>
                  <div class="hJUdamZF7Xruv1WN61xg" role="button">
                    Traditional
                  </div>
                  <div class="hJUdamZF7Xruv1WN61xg" role="button">
                    Typographic
                  </div>
                </div>
              </div>
            </div>
            <div class="dU7q7jYSGrP2nuLGUMj7">
              <div class="BCNQlmNsjVdIpTckR2mI">
                <input type="checkbox" />
              </div>
              <label>
                <span>Personalised Cards Only</span>
              </label>
            </div>
            <div class="j5sAWIoh5nXkJzTzlFKQ">
              <p class="VW0gqJP30HEqZt4Qc4xK">
                <span>Clear all filters</span>
              </p>
              <button class="byN5lklEheSE7lsR_rhp">Apply Filters</button>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="UOjuhD8IZsCyoyU3Z6oU">
          <h3>Sorry, we don't seem to have any cards that match!</h3>
          <p>
            Why not try
            <span class="VW0gqJP30HEqZt4Qc4xK">
              <span>clearing your filters</span>
            </span>
            or typing in
            <span class="VW0gqJP30HEqZt4Qc4xK">
              <span>a new search word</span>
            </span>
            .
          </p>
        </div>
      </div>
      <div class="D_x7CrapeCcgj1RyUIeH"></div>
    </main>
  );
};

export default CategoryGreetingsCards;
