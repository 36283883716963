import React, { useEffect, useState } from "react";
import { getAllOrders, shipping_charges } from "../../../api/api";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import CircleLoader from "../../Loader/CircleLoader";

const OrderHistory = () => {
  const navigate = useNavigate();
  const [isThereOrder, setIsThereOrder] = useState(true);
  const [myOrders, setMyOrders] = useState();

  const [isLoading, setIsLoading] = useState(true);
  const [deliveryCharge, setDeliveryCharge] = useState(null);

  useEffect(() => {
    loadOrders();
    isLogin();
    loadDeliveryCharge();
  }, []);

  const loadDeliveryCharge = async () => {
    try {
      const response = await shipping_charges();
      setDeliveryCharge(response?.data?.data?.shipping_charges);
    } catch (error) {
      console.log("error", error);
    }
  };

  const isLogin = () => {
    const token = Cookies.get("token");
    if (!token) {
      navigate("/login");
    }
  };

  const loadOrders = async () => {
    try {
      const response = await getAllOrders();
      if (response?.data?.message === "Success") {
        setMyOrders(response?.data?.data.reverse());
        setIsLoading(false);
        if (response?.data?.data.length === 0) {
          setIsThereOrder(false);
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const formatDate = (dateString) => {
    // Convert dateString to Date object
    const date = new Date(dateString);

    // Define months array for month names
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    // Get day, month, and year from the date object
    const day = date.getDate();
    const month = date.getMonth(); // Month index starts from 0
    const year = date.getFullYear();

    // Define the suffix for the day (st, nd, rd, th)
    const suffixes = ["th", "st", "nd", "rd"];
    const suffix = suffixes[(day - 1) % 10] || suffixes[0];

    // Construct the formatted date string
    const formattedDate = `${day}${suffix} ${months[month]} ${year}`;

    return formattedDate;
  };
  return (
    <main id="main-container" class="container top-margin">
      <div class="nav-blackout"></div>
      <div class="account-page">
        <div class="row">
          <div class="col-12">
            <ul class="breadcrumb">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/account">Your Account</Link>
              </li>
              <li>Order History</li>
            </ul>
          </div>
        </div>
        <div class="header-title">Order History</div>
        <div class="row">
          <div class="col-12">
            <div class="border-box">
              <div class="orders">
                <h2 class="heading">Orders</h2>
                {isLoading && <CircleLoader />}
                {isThereOrder ? (
                  myOrders &&
                  myOrders.map((order) => {
                    if (order.payment_status === "paid") {
                      return (
                        <div class="content">
                          <div
                            style={{ borderBottom: "1px solid gray" }}
                            class="item"
                          >
                            <div class="section">
                              <h3 class="header">Order Number</h3>
                              <span>
                                <Link
                                  to={`/account/order-details/${order?.id}`}
                                >
                                  {order?.order_num}
                                </Link>
                              </span>
                            </div>
                            <div class="section">
                              <h3 class="header">Order Date</h3>
                              <span>{formatDate(order?.created_at)}</span>
                            </div>
                            <div class="section large-column">
                              <h3 class="header">Recipient</h3>
                              <span>{order?.recipient_name}</span>
                            </div>

                            {order?.cart_detail[0]?.item_sale_price !== null ? (
                              <div class="section small-column">
                                <h3 class="header">Total</h3>
                                {deliveryCharge && (
                                  <span>
                                    £
                                    {(
                                      order?.cart_detail[0]?.item_sale_price -
                                      order?.coupon_discount +
                                      deliveryCharge
                                    ).toFixed(2)}
                                  </span>
                                )}
                              </div>
                            ) : (
                              <div class="section small-column">
                                <h3 class="header">Total</h3>
                                {deliveryCharge && (
                                  <span>
                                    £
                                    {(
                                      order?.cart_detail[0]?.item_price -
                                      order?.coupon_discount +
                                      deliveryCharge
                                    ).toFixed(2)}
                                  </span>
                                )}
                              </div>
                            )}
                            {order?.delivery_status === "cancelled" ? (
                              <div class="section with-buttons">
                                <span>
                                  <Link
                                    to={`/account/order-details/${order?.id}`}
                                    class="cancelled bn-button view-button bn-button--secondary"
                                    style={{
                                      boxShadow: "inset 0 0 0 1px red",
                                    }}
                                  >
                                    <span style={{ color: "red" }}>
                                      Cancelled
                                    </span>
                                  </Link>
                                </span>
                                <span class="show-breakdown"></span>
                              </div>
                            ) : order?.delivery_status === "delivered" ? (
                              <div class="section with-buttons">
                                <span>
                                  <Link
                                    to={`/account/order-details/${order?.id}`}
                                    class="cancelled bn-button view-button bn-button--secondary"
                                    style={{
                                      boxShadow: "inset 0 0 0 1px green",
                                    }}
                                  >
                                    <span style={{ color: "green" }}>
                                      Delivered
                                    </span>
                                  </Link>
                                </span>
                                <span class="show-breakdown"></span>
                              </div>
                            ) : (
                              <div class="section with-buttons">
                                <span>
                                  <Link
                                    class="bn-button view-button bn-button--secondary"
                                    role="button"
                                    to={`/account/order-details/${order?.id}`}
                                    onClick={() =>
                                      window.scrollTo({
                                        top: 0,
                                        behavior: "smooth",
                                      })
                                    }
                                  >
                                    View Details
                                  </Link>
                                </span>
                                <span class="show-breakdown"></span>
                              </div>
                            )}
                          </div>
                          <div
                            class="subscription-sub-menu"
                            hidden=""
                            aria-hidden="true"
                            aria-expanded="false"
                          ></div>
                        </div>
                      );
                    }
                  })
                ) : (
                  <div class="row">
                    <div class="col-12">
                      <p>
                        It doesn't look like you have ordered with us yet! Click
                        <Link to="/">here</Link> to begin shopping
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default OrderHistory;
