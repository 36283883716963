import React from "react";
import "./ReferAFriend.css";

const ReferAFriend = () => {
  return (
    <main id="main-container" class="container top-margin">
      <div class="nav-blackout"></div>
      <div class="RE36GM4qWntmSzvbDOwH">
        <section class="hjLMEMq9Zi8hzcK0av3L">
          <a href="/account">
            <div class="S8zrtVx3ESiSO92lkVob">
              <div class="xK09C15epzbXMoY9ioyT"></div>
              <div class="EF4qtoOMnELXZZckMwj6">
                <h1>Give £5, Get £5</h1>
                <p>
                  Say hello to our new refer a friend service, the easy way to
                  spread floral joy whilst getting discounts on beautiful blooms
                  for both you and your loved ones, keep reading to discover
                  more.
                </p>
                <a class="JwbcXSAoXyujMLRDHx7D" href="/account">
                  Get £5 off
                </a>
              </div>
            </div>
          </a>
        </section>
        <section class="XwmNYxMiaTyFhX4uUvMe">
          <h3>It's as easy as 1, 2, 3!</h3>
          <div class="op5jVVCRDFvEkEENVWML">
            <div class="ejvCftzg8F6l3pHNBRw_">
              <div class="bR1stVJIEnCJc24l4vTL">
                <h4>Step 1</h4>
                <div class="WyXltSzfMKCMzVKKQLMg">
                  <i class="fa fa-desktop"></i>
                </div>
              </div>
              <p>
                Sign in or create an account and copy your unique referral link
                to share with friends.
              </p>
            </div>
            <div class="ejvCftzg8F6l3pHNBRw_">
              <div class="bR1stVJIEnCJc24l4vTL">
                <h4>Step 2</h4>
                <div class="WyXltSzfMKCMzVKKQLMg">
                  <i class="fa fa-envelope-o"></i>
                </div>
              </div>
              <p>
                Once they’ve registered, they will receive 500 Posy Points worth
                £5 off their first order.
              </p>
            </div>
            <div class="ejvCftzg8F6l3pHNBRw_">
              <div class="bR1stVJIEnCJc24l4vTL">
                <h4>Step 3</h4>
                <div class="WyXltSzfMKCMzVKKQLMg">
                  <i class="fa fa-shopping-basket"></i>
                </div>
              </div>
              <p>
                After your friend has placed an order, we will add 500 Posy
                Points into your account too!
              </p>
            </div>
          </div>
          <a class="VRZl5T_cEakrM5LuPOBb YLxdcrrIEpFGSqqBDTXs" href="/account">
            Get £5 off
          </a>
        </section>
        <section class="kEH40knLIFcN61pYoMav">
          <div class="KtRuK8LmdTWK_6fTDbW7">
            <div class="mj64qjZoAz5DPm4_fJhF">
              <img
                src="https://media.bunches.co.uk//content_pages/raf-posy-points.png"
                alt="Hand outstretched with bouquet of sunflowers"
                class="NZm3GSRHqjo_Ii5_o940"
              />
              <h3>What are Posy Points?</h3>
              <a
                class="VRZl5T_cEakrM5LuPOBb YLxdcrrIEpFGSqqBDTXs"
                href="/journal/say-hello-to-posy-points"
              >
                Find out more about Posy Points
              </a>
            </div>
            <div class="FKL7oAmhdQ0ARrWG5Mej"></div>
            <img
              src="https://media.bunches.co.uk//content_pages/refer-a-friend-sunflowers.png"
              alt="Posy Points logo"
              class="bQzXNwr_0PMvf2I0HI_F"
            />
          </div>
        </section>
      </div>
    </main>
  );
};

export default ReferAFriend;
