import React from "react";

const Affiliates = () => {
  return (
    <main id="main-container" class="container top-margin">
      <div class="nav-blackout"></div>
      <main class="row static-page">
        <section class="col-md-12">
          <article class="card">
            <header class="card-header">
              <h2 class="card-title">Become an Affiliate</h2>
            </header>
            <div class="card-block">
              <p>
                JOIN THE Integrity Flowers AFFILIATE PROGRAMME
                <br />
                …and earn up to 8% commission!
              </p>
              <p>
                Integrity Flowers offer beautiful great value flowers which make
                the ideal gift from as little as £20.45! With over 10 million
                successful UK deliveries, over 33 years experience and a
                committed team, becoming a Integrity Flowers affiliate is a
                great way for you to increase your business revenue.
              </p>
              <p>
                We enjoy a high conversion rate and we verify sales commission
                quickly. You can be a part of our success and earn up to 8%
                commission on sales with a 30 day tracking period! We pay
                commission on the full price including VAT and delivery.
              </p>
              <p>
                <a
                  href="https://www.awin1.com/signup.php?merchant=488"
                  class="bn-button bn-button--primary"
                  role="button"
                >
                  Sign up
                </a>
              </p>
            </div>
          </article>
        </section>
      </main>
    </main>
  );
};

export default Affiliates;
