import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  closeMenubar,
  openMenubar,
  toggleBasket,
} from "../../../../features/menuBar/menubarSlice";
import integrityFlowerLogo from "../../../../assets/logos/Integrityflowers Transparent cropped .png";
import integrityFlowerLogoWithoutText from "../../../../assets/logos/vite.png";
import { Link, useNavigate } from "react-router-dom";

const MobileNavBar = () => {
  const navigate = useNavigate();
  const basketCount = useSelector((state) => state?.basket?.basketCount);
  const [isSearch, setIsSearch] = useState(false);

  const [search, setSearch] = useState("");

  const menubarView = useSelector((state) => state.menubar.menubarView);
  const dispatch = useDispatch();

  const handleSearchClick = (val) => {
    if (val === "open") {
      setIsSearch(true);
    }
    if (val === "close") {
      setIsSearch(false);
    }
  };

  const handleMenubarClick = (val) => {
    if (val === "open") {
      dispatch(openMenubar());
    }
    if (val === "close") {
      dispatch(closeMenubar());
    }
  };

  const handleBasketClick = () => {
    dispatch(toggleBasket());
  };

  const handleSearchInputChange = (e) => {
    e.preventDefault();
    setSearch(e.target.value);
  };

  const handleSearchEnterClick = async (e) => {
    e.preventDefault();
    if (search !== "") {
      navigate(`/search/${search}`);
    }
  };
  return (
    <>
      <nav class="navigation-mobile">
        <div class="right-icons">
          <div style={{ textAlign: "center" }} onClick={handleBasketClick}>
            <span class="basket-amount">{basketCount}</span>
            <i class="icon-container basket-icon" aria-hidden="true">
              <i class="fa fa-shopping-basket" aria-hidden="true"></i>
              <span
                style={{
                  display: "block",
                  fontFamily: "unset",
                  fontStyle: "normal",
                }}
              >
                Basket
              </span>
            </i>
          </div>
          <span class="bn-header__icon js-mobile-search-hide mobile-menu">
            {menubarView === "close" ? (
              <i
                onClick={() => handleMenubarClick("open")}
                class="hamburger-menu icon-container"
              >
                <img
                  src="https://media.bunches.co.uk/icons/hamburger-bars.svg"
                  alt="Open navigation menu"
                />
              </i>
            ) : (
              <svg
                onClick={() => handleMenubarClick("close")}
                width="15"
                height="15"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="close-menu"
                role="button"
                aria-label="Close navigation menu"
                tabindex="0"
              >
                <title>Close navigation menu</title>
                <path
                  d="M18 1L2 17"
                  stroke="#78858D"
                  strokeWidth="2"
                  strokeLinecap="round"
                ></path>
                <path
                  d="M2 1L18 17"
                  stroke="#78858D"
                  strokeWidth="2"
                  strokeLinecap="round"
                ></path>
              </svg>
            )}
          </span>
        </div>
        <Link class="bn-bunches__logo" to="/">
          <img
            style={{ height: "50px", width: "130px" }}
            alt="Integrity Flower"
            src={integrityFlowerLogo}
          />
        </Link>
        <div class="left-icons">
          <span class="bn-header__icon">
            <i
              onClick={() => handleSearchClick("open")}
              class="icon-container search-icon"
              aria-hidden="true"
            >
              <img
                src="https://media.bunches.co.uk/icons/search.svg"
                alt="Search"
              />
            </i>
          </span>
          {/* <span>
            <div class="overlay-button-container">
              <span class="overlay-button">
                <i
                  class="overlay-heart fa fa-heart heart"
                  role="button"
                  aria-label="View Favourites"
                ></i>
              </span>
            </div>
          </span> */}
        </div>
      </nav>
      <div
        class={`mobile-search-container hidden-lg-up is-loaded ${
          isSearch ? "is-open" : "is-close"
        } `}
      >
        <div class="search-logo">
          <img
            src={integrityFlowerLogoWithoutText}
            width="30"
            height="30"
            alt="Integrity Flower"
          />
        </div>
        <form onSubmit={handleSearchEnterClick}>
          <input
            type="search"
            class="bn-input bn-input--border-bottom search-input"
            placeholder="What are you looking for?"
            aria-label="Search products"
            value={search}
            onChange={handleSearchInputChange}
          />
          <button
            type="submit"
            class="bn-button bn-button--primary bn-button--sm"
            title="Submit search"
            onChange={handleSearchEnterClick}
          >
            <i class="bn-icon bn-icon__search"></i>
          </button>
        </form>
        <span
          class="bn-header__icon close-search"
          role="button"
          aria-label="Close Search"
        >
          <i
            onClick={() => handleSearchClick("close")}
            class="bn-icon bn-icon__close"
          ></i>
        </span>
      </div>
    </>
  );
};

export default MobileNavBar;
