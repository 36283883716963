import React from "react";
import { cancel_order } from "../../../../api/api";

const CancelYourOrder = ({
  openModal,
  closeModal,
  productId,
  cancelOrderSuccess,
}) => {
  const handleCancelOrder = async () => {
    try {
      const response = await cancel_order({
        order_id: productId,
      });
      if (response?.data?.message === "Success") {
        cancelOrderSuccess();
        closeModal();
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  return (
    <dialog
      class="popup-alert cancel-dialog"
      open={openModal === "Cancel Order"}
    >
      <div class="alert-body">
        {/* <div class="cancellation-reasons">
          <p>Are you sure you wish to cancel your order?</p>
          <div class="content">
            <div class="content-inside">
              <i class="fa fa-angle-down"></i>
              <select required="">
                <option value="0">Please select a reason</option>
                <option value="60">I duplicated my order</option>
                <option value="61">The recipient is away</option>
                <option value="62">I entered the wrong delivery date</option>
                <option value="64">I forgot to use my discount code</option>
                <option value="65">Other</option>
              </select>
            </div>
          </div>
        </div> */}
        <div class="actions">
          <button
            onClick={() => closeModal()}
            type="button"
            class="bn-button bn-button--text"
          >
            No, I do not wish to cancel
          </button>
          <button
            onClick={handleCancelOrder}
            type="button"
            class="bn-button bn-button--primary"
            disabled=""
          >
            Yes, I wish to cancel
          </button>
        </div>
      </div>
    </dialog>
  );
};

export default CancelYourOrder;
