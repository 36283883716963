import React, { useState, useEffect, useRef } from "react";
import { change_message_by_order_id } from "../../../../api/api";
import Loader from "../../../Loader/Loader";

const useCaretPosition = (initialValue, maxCaretPosition, maxContentLength) => {
  const [content, setContent] = useState(initialValue);
  const [caretPosition, setCaretPosition] = useState(0);

  const contentEditableRef = useRef(null);

  const handleInput = () => {
    const selection = window.getSelection();
    if (selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);
      const currentCaretPosition = range.endOffset;
      const newContent = contentEditableRef.current.innerText;
      if (
        currentCaretPosition <= maxCaretPosition &&
        newContent.length <= maxContentLength
      ) {
        setCaretPosition(currentCaretPosition);
        setContent(newContent);
      } else {
        contentEditableRef.current.innerText = content;
      }
    }
  };

  useEffect(() => {
    if (contentEditableRef.current) {
      const selection = window.getSelection();
      const range = document.createRange();
      const textNode = contentEditableRef.current.firstChild;

      if (textNode) {
        range.setStart(textNode, caretPosition);
        range.collapse(true);
        selection.removeAllRanges();
        selection.addRange(range);
      }
    }
  }, [caretPosition]);

  return { content, contentEditableRef, handleInput };
};

const TextEditor = ({
  openModal,
  closeModal,
  orderMessageDetails,
  orderId,
  sendMessageToParent,
}) => {
  const maxCaretPosition = 250;
  const maxContentLength = 250;

  const { content, contentEditableRef, handleInput } = useCaretPosition(
    orderMessageDetails,
    maxCaretPosition,
    maxContentLength
  );

  const [isLoading, setIsLoading] = useState(false);

  const handleMessageChangeClick = async () => {
    setIsLoading(true);
    try {
      const response = await change_message_by_order_id({
        order_id: orderId,
        message: content,
      });
      if (response) {
        sendMessageToParent(content);
        closeModal();
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <dialog
      class="modal active"
      role="alertdialog"
      aria-live="assertive"
      aria-modal="true"
      open={openModal === "Change Message"}
    >
      <div class="modal-content">
        <div class="Bdw6gjfEGWMsf_bkFBjv">
          <div class="WGacRckKKIUoVJZNnFeq">
            <h1>Your card message</h1>
            <svg
              onClick={() => closeModal()}
              width="15"
              height="15"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="close-modal"
              role="button"
              aria-label="Close Modal"
              tabindex="0"
            >
              <title>Close Modal</title>
              <path
                d="M18 1L2 17"
                stroke="#78858D"
                stroke-width="2"
                stroke-linecap="round"
              ></path>
              <path
                d="M2 1L18 17"
                stroke="#78858D"
                stroke-width="2"
                stroke-linecap="round"
              ></path>
            </svg>
          </div>
          <div class="w4nQfR5xKnljWt0qxKLg">
            <p class="Sdbyk2VEHS2zgN7nRXmn">Tap to edit your message below</p>
            <div class="card-message-inside">
              <div class="inside-right standard">
                <div
                  ref={contentEditableRef}
                  contentEditable
                  role="textbox"
                  aria-label="Message"
                  aria-multiline="true"
                  className="message empty"
                  style={{
                    color: "rgb(0, 0, 0)",
                    fontSize: "1.2em",
                    fontFamily: "inherit",
                    textAlign: "center",
                    justifyContent: "center",
                  }}
                  onInput={handleInput}
                >
                  {content}
                </div>
              </div>
            </div>
            <div class="RGSNbTxX7yhVztdxhyBE">
              <button
                onClick={handleMessageChangeClick}
                class="VRZl5T_cEakrM5LuPOBb YLxdcrrIEpFGSqqBDTXs"
              >
                {isLoading ? (
                  <span>
                    <Loader />
                  </span>
                ) : (
                  <span>Save</span>
                )}
              </button>
              <button
                onClick={() => closeModal()}
                class="e7QvJMPhPw6Pbciz9UHc YLxdcrrIEpFGSqqBDTXs"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </dialog>
  );
};

export default TextEditor;
