import React, { useEffect, useState } from "react";
import SubCategory from "../CategorySubCategoryComponents/SubCategory";
import SubCategorySideCategory from "../CategorySubCategoryComponents/SubCategorySideCategory";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const FlowersCategory = ({ flowers, parentCategory, category }) => {
  return (
    <>
      <div class={`children pane-a ${flowers && "active"}`}>
        <div class="column is-large">
          <ul>
            {category &&
              category.map((cat, i) => {
                if (parentCategory?.id === cat?.parent_category?.id) {
                  return <SubCategory i={i} category={cat} />;
                }
              })}
          </ul>
          <Link
            class="bn-button bn-button--secondary has-caret"
            to={`/category/flowers/${parentCategory?.id}`}
          >
            View our full range of flowers
          </Link>
        </div>
        {/* <div class="column is-small">
          <div class="panels">
            <SubCategorySideCategory />
            <SubCategorySideCategory />
          </div>
          <ul>
            <li class="mobile-only">
              <a class="has-caret" href="/category/flowers">
                View our full range of flowers
              </a>
            </li>
            <li class="">
              <a class="has-caret" href="/category/pet-friendly">
                Pet Friendly Gifts
              </a>
            </li>
          </ul>
        </div> */}
      </div>
    </>
  );
};

export default FlowersCategory;
