import React, { useState, useEffect, useRef } from "react";
import { useUserContext } from "../UserContext";

const useCaretPosition = (
  initialValue,
  selectedEmoji,
  maxCaretPosition,
  maxContentLength
) => {
  const [content, setContent] = useState(initialValue);
  const [caretPosition, setCaretPosition] = useState(0);
  const [prevSelectedEmoji, setPrevSelectedEmoji] = useState([]);

  const contentEditableRef = useRef(null);

  const handleInput = () => {
    const selection = window.getSelection();
    if (selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);
      const currentCaretPosition = range.endOffset;
      const newContent = contentEditableRef.current.innerText;
      if (
        currentCaretPosition <= maxCaretPosition &&
        newContent.length <= maxContentLength
      ) {
        setCaretPosition(currentCaretPosition);
        setContent(newContent);
      } else {
        contentEditableRef.current.innerText = content;
      }
    }
  };

  useEffect(() => {
    if (contentEditableRef.current) {
      const selection = window.getSelection();
      const range = document.createRange();
      const textNode = contentEditableRef.current.firstChild;

      if (textNode) {
        range.setStart(textNode, caretPosition);
        range.collapse(true);
        selection.removeAllRanges();
        selection.addRange(range);
      }
    }
  }, [caretPosition]);

  useEffect(() => {
    if (selectedEmoji.length !== prevSelectedEmoji.length) {
      setContent(
        (prevContent) => prevContent + selectedEmoji[selectedEmoji.length - 1]
      );
      setPrevSelectedEmoji(selectedEmoji);
    }
  }, [selectedEmoji, prevSelectedEmoji]);

  return { content, contentEditableRef, handleInput };
};

const TextEditor = ({
  selectedBackgroundColor,
  selectedFontSize,
  selectedFontFamily,
  selectedTextAlignment,
  selectedTextAlignmentHorizontal,
  selectedEmoji,
  textEditorContent,
}) => {
  const { userData, updateUserData } = useUserContext();
  //   const [containerSize, setContainerSize] = useState({ width: 0, height: 0 });

  //   useEffect(() => {
  //     const handleResize = () => {
  //       const { width, height } =
  //         contentEditableRef.current.getBoundingClientRect();
  //       setContainerSize({ width, height });
  //     };

  //     handleResize();
  //     window.addEventListener("resize", handleResize);
  //     return () => {
  //       window.removeEventListener("resize", handleResize);
  //     };
  //   }, []);

  //   const averageCharacterWidth = 2; // Assuming average character width is 10 pixels
  //   const averageCharacterHeight = 2; // Assuming average character height is 20 pixels

  //   const maxCaretPosition = Math.floor(
  //     containerSize.width / averageCharacterWidth
  //   );
  //   const maxContentLength = Math.floor(
  //     containerSize.height / averageCharacterHeight
  //   );

  const maxCaretPosition = 250;
  const maxContentLength = 250;

  const { content, contentEditableRef, handleInput } = useCaretPosition(
    userData?.card_message || "",
    selectedEmoji,
    maxCaretPosition,
    maxContentLength
  );

  useEffect(() => {
    textEditorContent(content);

    let newData = {
      ...userData,
      card_message: content,
    };
    updateUserData(newData);
  }, [content]);

  return (
    <div
      ref={contentEditableRef}
      contentEditable
      role="textbox"
      aria-label="Message"
      aria-multiline="true"
      className="message empty"
      style={{
        color: selectedBackgroundColor,
        fontSize: selectedFontSize,
        fontFamily: selectedFontFamily,
        textAlign: selectedTextAlignment,
        justifyContent: selectedTextAlignmentHorizontal,
        height: "470px",
      }}  
      onInput={handleInput}
    >
      {content}
    </div>
  );
};

export default TextEditor;
