import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  coupon: "",
  couponVerification: "",
};

export const couponSlice = createSlice({
  name: "Coupon",
  initialState,
  reducers: {
    addCoupon: (state, action) => {
      state.coupon = action.payload;
    },
    addCouponResponse: (state, action) => {
      state.couponVerification = action.payload;
    },
  },
});

export const { addCoupon, addCouponResponse } = couponSlice.actions;

export default couponSlice.reducer;
