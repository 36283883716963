import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  menubarView: "close",
  basket: false,
};

export const menubarSlice = createSlice({
  name: "menubar",
  initialState,
  reducers: {
    openMenubar: (state) => {
      state.menubarView = "open";
    },
    closeMenubar: (state) => {
      state.menubarView = "close";
    },
    toggleBasket: (state) => {
      state.basket = !state.basket;
    },
  },
});

export const { openMenubar, closeMenubar, toggleBasket } = menubarSlice.actions;

export default menubarSlice.reducer;
