import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  AllCartItems,
  RemoveMultipleItemsToBasket,
  signIn,
  storeMultipleItemsToBasket,
} from "../../../api/api";
import Cookies from "js-cookies";
import { useDispatch } from "react-redux";
import { addToken, addUser } from "../../../features/User/userSlice";
import { v4 as uuidv4 } from "uuid";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [togglePassword, setTogglePassword] = useState("password");
  const [signInError, setSignInError] = useState("");

  useEffect(() => {
    const token = Cookies.getItem("token");
    const user = localStorage.getItem("user");
    if (!user) {
      Cookies.removeItem("token");
    }
    if (token) {
      navigate(-1);
    }
  }, []);

  const handleSignIn = async (e) => {
    e.preventDefault();
    if (!email || !password) {
      setSignInError("Please enter your email and password ");
      return;
    }
    try {
      const response = await signIn({ email, password });
      if (
        response?.data?.user?.user_role !== 1 &&
        response?.data?.user?.user_status === 1
      ) {
        dispatch(addUser(response?.data?.user));
        dispatch(addToken(response?.data?.token));
        Cookies.setItem("token", response?.data?.token);
        localStorage.setItem("user", JSON.stringify(response?.data?.user));
        navigate("/");

        try {
          const response = await AllCartItems();
          const userCartLiveData = response?.data?.data;

          const userCart = localStorage.getItem("user-cart");
          const parsedUserData = JSON.parse(userCart);

          if (userCartLiveData?.length < parsedUserData?.length) {
            const filteredData = parsedUserData.filter((local) => {
              return !userCartLiveData.some((live) => local.uid === live.uid);
            });

            const dataToSend = {
              uid: filteredData.map((product) => uuidv4()),
              variation_id: filteredData.map(
                (product) => product?.variation_id
              ),
              item_qty: filteredData.map((product) => 1),
              item_price: filteredData.map((product) => product?.item_price),
              delivery_date: filteredData.map(
                (product) => product?.delivery_date
              ),
            };
            if (filteredData && filteredData.length > 0) {
              try {
                const response = await storeMultipleItemsToBasket(dataToSend);
              } catch (error) {
                console.log("error", error);
              }
            }
          } else if (userCartLiveData?.length > parsedUserData?.length) {
            const filteredData = userCartLiveData.filter((live) => {
              return !parsedUserData.some((local) => live.uid === local.uid);
            });

            if (filteredData && filteredData.length > 0) {
              const dataToSend = {
                cart_id: filteredData.map((product) => product?.id),
              };
              try {
                const response = await RemoveMultipleItemsToBasket(dataToSend);
              } catch (error) {
                console.log("error", error);
              }
            }
          }

          // dispatch(addBasket(userCartLiveData));
        } catch (error) {
          console.log("error", error);
        }
      } else {
        if (response?.data?.user?.user_role === 1) {
          setSignInError("Admin cannot log in.");
        }
        if (response?.data?.user?.user_status !== 1) {
          setSignInError("You cannot log in. your account is blocked.");
        }
      }
    } catch (error) {
      setSignInError("Please check your email and password ");
      console.log("error", error);
    }
  };

  return (
    <main id="main-container" class="container top-margin">
      <div class="nav-blackout"></div>
      <div class="login">
        <div class="row">
          <div class="col-12">
            <ul class="breadcrumb">
              <li>
                <a href="/">Home</a>
              </li>
              <li>Sign in</li>
            </ul>
          </div>
        </div>
        <div class="heading">Sign in</div>
        <div class="content">
          <form style={{ width: "50%" }} onSubmit={handleSignIn}>
            <div class="form">
              <h1>Existing Customer?</h1>
              <div class="form-group">
                <label for="email" class="bn-label">
                  Email Address
                </label>
                <input
                  id="email"
                  type="email"
                  autocomplete="email"
                  class="form-control"
                  placeholder="Enter your email address..."
                  required=""
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setSignInError("");
                  }}
                />
              </div>
              <div class="form-group">
                <label for="password" class="bn-label">
                  Password
                </label>
                <input
                  id="password"
                  name="password"
                  type={togglePassword}
                  autocomplete="current-password"
                  class="form-control"
                  placeholder="Enter your password"
                  required=""
                  minlength="1"
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    setSignInError("");
                  }}
                />
                {togglePassword === "password" && (
                  <span
                    onClick={() => setTogglePassword("text")}
                    role="button"
                    aria-label="Show Password"
                    class="fa password-view-icon fa-eye"
                  ></span>
                )}
                {togglePassword === "text" && (
                  <span
                    onClick={() => setTogglePassword("password")}
                    role="button"
                    aria-label="Hide Password"
                    class="fa password-view-icon fa-eye-slash"
                  ></span>
                )}
                <div style={{ color: "red", fontSize: "15px" }}>
                  {signInError}
                </div>
              </div>
              <div class="buttons">
                <button type="submit" class="bn-button bn-button--primary">
                  Sign in
                </button>
                {/* <button class="bn-button bn-button--primary" disabled="">
                Email me a sign in link
              </button> */}
              </div>
              <p class="text-center mb-3">
                <Link to="/forgot-password">Forgot password?</Link>
              </p>
              {/* <div class="hr-with-text">
              <div>Or</div>
            </div>
            <div class="text-center">
              <div class="n0ywmAu4ItA_sj7LzbYw">
                <button
                  class="e7QvJMPhPw6Pbciz9UHc YLxdcrrIEpFGSqqBDTXs"
                  type="button"
                >
                  <i class="fa fa-facebook-square"></i>
                  <span>Sign in with Facebook</span>
                </button>
              </div>
            </div> */}
            </div>
          </form>
          <div class="benefits">
            <h1>New to Integrity Flowers?</h1>
            <h2>Here are the benefits you get when you register:</h2>
            <p class="toggle active">
              Registration benefits<i class="fa fa-angle-down"></i>
            </p>
            <div>
              {/* <div class="reason">
                <img
                  src="https://media.bunches.co.uk/content_pages/posy-points-icon.png"
                  alt="Posy Points"
                />
                <span>
                  <h3>Posy Points™</h3>
                  Earn points on every order which you can claim for a discount
                  on future orders.
                </span>
              </div> */}
              <div class="reason">
                <img
                  src="https://media.bunches.co.uk/content_pages/address-icon.png"
                  alt="Address Book"
                />
                <span>
                  <h3>Address book</h3>
                  All of your addresses safely stored in one place for a faster
                  checkout experience.
                </span>
              </div>
              <div class="reason">
                <img
                  src="https://media.bunches.co.uk/content_pages/reminders-icon.png"
                  alt="Reminder Service"
                />
                <span>
                  <h3>Reminder Service</h3>
                  Let us know when your special occasions are and we will remind
                  you by email.
                </span>
              </div>
            </div>
            <div class="text-center">
              <Link
                class="bn-button bn-button--primary"
                role="button"
                to="/register"
              >
                Register
              </Link>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Login;
