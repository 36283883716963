import React from "react";

const GreetingsCards = () => {
  return (
    <main id="main-container" class="container top-margin fluid-container">
      <div class="nav-blackout"></div>
      <div class="landing-page">
        <section class="greetings-card-hero">
          <a href="/category/greetings-cards">
            <div class="hero-body">
              <div class="cover"></div>
              <div class="content">
                <h1>Send just a Greetings Card</h1>
                <p>
                  For when you just want to send a greetings card, delivered
                  directly to their door. From £2.25 for an A6 card or £2.95 for
                  an A5 card.
                </p>
                <span class="button">Click here to view all cards</span>
              </div>
            </div>
            <section class="why-choose-bunches" aria-label="Why Choose Bunches">
              <div class="content background-dark-cream">
                <div class="slider">
                  <ul class="items" style={{ transform: "translateX(-100%)" }}>
                    <li class="item" style={{ order: "0" }}>
                      <p>UK DELIVERY FROM £1</p>
                    </li>
                    <li class="item" style={{ order: "1" }}>
                      <p>100% RECYCLABLE, FSC CERTIFIED</p>
                    </li>
                    <li class="item" style={{ order: "2" }}>
                      <p>HIGH QUALITY 300GSM CARD</p>
                    </li>
                    <li class="item" style={{ order: "-1" }}>
                      <p>EARN POSY POINTS ON YOUR ORDER</p>
                    </li>
                  </ul>
                </div>
              </div>
            </section>
          </a>
        </section>
        <section class="greetings-card-blooms">
          <a href="/category/flowers">
            <div class="body">
              <div class="content">
                <h2>Want to send flowers with your card?</h2>
                <p>
                  Simply choose your bouquet then select your card and add a
                  personal message.
                </p>
                <span class="button">Click here to send blooms too</span>
              </div>
              <div class="cover"></div>
            </div>
          </a>
        </section>
        <section
          class="greetings-card-carousel"
          aria-label="Best Selling Cards"
        >
          <header class="lined-header" aria-label="header">
            <h2>
              <a href="/category/greetings-cards">Best Selling Cards</a>
              <a class="view-all-headerlink" href="/category/greetings-cards">
                View all
              </a>
            </h2>
          </header>
          <div class="items">
            <div class="item">
              <div class="favourite-button-container">
                <a
                  href="/product/happy-birthday-sunflowers"
                  style={{ pointerEvents: "all" }}
                >
                  <div class="card-image-container">
                    <span class="card-shadow">
                      <img
                        class="img-fluid"
                        src="https://media.bunches.co.uk/products/586x586/k0423.jpg"
                        alt="Happy Birthday Sunflowers"
                        loading="lazy"
                      />
                    </span>
                  </div>
                </a>
                <span class="favourite-button">
                  <span class="background">
                    <i
                      class="fa fa-heart-o heart"
                      role="button"
                      aria-label="Add to Favourites"
                    ></i>
                  </span>
                </span>
              </div>
            </div>
            <div class="item">
              <div class="favourite-button-container">
                <a
                  href="/product/happy-birthday-peach-floralA5"
                  style={{ pointerEvents: "all" }}
                >
                  <div class="card-image-container">
                    <span class="card-shadow">
                      <img
                        class="img-fluid"
                        src="https://media.bunches.co.uk/products/586x586/k0749.jpg"
                        alt="Happy Birthday Peach Floral "
                        loading="lazy"
                      />
                    </span>
                  </div>
                </a>
                <span class="favourite-button">
                  <span class="background">
                    <i
                      class="fa fa-heart-o heart"
                      role="button"
                      aria-label="Add to Favourites"
                    ></i>
                  </span>
                </span>
              </div>
            </div>
            <div class="item">
              <div class="favourite-button-container">
                <a
                  href="/product/thinking-of-you-card"
                  style={{ pointerEvents: "all" }}
                >
                  <div class="card-image-container">
                    <span class="card-shadow">
                      <img
                        class="img-fluid"
                        src="https://media.bunches.co.uk/products/586x586/k0006.jpg"
                        alt="Thinking of You Card"
                        loading="lazy"
                      />
                    </span>
                  </div>
                </a>
                <span class="favourite-button">
                  <span class="background">
                    <i
                      class="fa fa-heart-o heart"
                      role="button"
                      aria-label="Add to Favourites"
                    ></i>
                  </span>
                </span>
              </div>
            </div>
            <div class="item">
              <div class="favourite-button-container">
                <a
                  href="/product/happy-birthday-to-you-card-K0381A5"
                  style={{ pointerEvents: "all" }}
                >
                  <div class="card-image-container">
                    <span class="card-shadow">
                      <img
                        class="img-fluid"
                        src="https://media.bunches.co.uk/products/586x586/k0381.jpg"
                        alt="Happy Birthday To You Card"
                        loading="lazy"
                      />
                    </span>
                  </div>
                </a>
                <span class="favourite-button">
                  <span class="background">
                    <i
                      class="fa fa-heart-o heart"
                      role="button"
                      aria-label="Add to Favourites"
                    ></i>
                  </span>
                </span>
              </div>
            </div>
            <div class="item">
              <div class="favourite-button-container">
                <a
                  href="/product/happy-anniversary-pink-floralA5"
                  style={{ pointerEvents: "all" }}
                >
                  <div class="card-image-container">
                    <span class="card-shadow">
                      <img
                        class="img-fluid"
                        src="https://media.bunches.co.uk/products/586x586/k0751.jpg"
                        alt="Happy Anniversary Pink Floral"
                        loading="lazy"
                      />
                    </span>
                  </div>
                </a>
                <span class="favourite-button">
                  <span class="background">
                    <i
                      class="fa fa-heart-o heart"
                      role="button"
                      aria-label="Add to Favourites"
                    ></i>
                  </span>
                </span>
              </div>
            </div>
            <div class="item">
              <div class="favourite-button-container">
                <a
                  href="/product/happy-anniversary-flowers"
                  style={{ pointerEvents: "all" }}
                >
                  <div class="card-image-container">
                    <span class="card-shadow">
                      <img
                        class="img-fluid"
                        src="https://media.bunches.co.uk/products/586x586/k0295.jpg"
                        alt="Happy Anniversary Flowers"
                        loading="lazy"
                      />
                    </span>
                  </div>
                </a>
                <span class="favourite-button">
                  <span class="background">
                    <i
                      class="fa fa-heart-o heart"
                      role="button"
                      aria-label="Add to Favourites"
                    ></i>
                  </span>
                </span>
              </div>
            </div>
            <div class="item">
              <div class="favourite-button-container">
                <a
                  href="/product/happy-birthday-with-love"
                  style={{ pointerEvents: "all" }}
                >
                  <div class="card-image-container">
                    <span class="card-shadow">
                      <img
                        class="img-fluid"
                        src="https://media.bunches.co.uk/products/586x586/k0377.jpg"
                        alt="Happy Birthday With Love"
                        loading="lazy"
                      />
                    </span>
                  </div>
                </a>
                <span class="favourite-button">
                  <span class="background">
                    <i
                      class="fa fa-heart-o heart"
                      role="button"
                      aria-label="Add to Favourites"
                    ></i>
                  </span>
                </span>
              </div>
            </div>
          </div>
        </section>
        <section class="greetings-card-shop-by-occasion">
          <div class="content">
            <header class="lined-header" aria-label="header">
              <h2>Shop by Occasion</h2>
            </header>
            <div class="items">
              <a
                class="item"
                href="/category/birthday-cards"
                style={{ pointerEvents: "all" }}
              >
                <div class="item-image">
                  <div class="image-container">
                    <div
                      class="image"
                      style={{
                        backgroundImage:
                          "url('https://media.bunches.co.uk/content_pages/greetings_cards/greetings-card-birthday.jpg')",
                      }}
                    ></div>
                  </div>
                </div>
                Birthday
              </a>
              <a
                class="item"
                href="/category/anniversary-cards"
                style={{ pointerEvents: "all" }}
              >
                <div class="item-image">
                  <div class="image-container">
                    <div
                      class="image"
                      style={{
                        backgroundImage:
                          "url('https://media.bunches.co.uk/content_pages/greetings_cards/greetings-card-anniversary.jpg')",
                      }}
                    ></div>
                  </div>
                </div>
                Anniversary
              </a>
              <a
                class="item"
                href="/category/thinking-of-you-cards"
                style={{ pointerEvents: "all" }}
              >
                <div class="item-image">
                  <div class="image-container">
                    <div
                      class="image"
                      style={{
                        backgroundImage:
                          "url('https://media.bunches.co.uk/content_pages/greetings_cards/greetings-card-thinking-of-you.jpg')",
                      }}
                    ></div>
                  </div>
                </div>
                Thinking of You
              </a>
              <a
                class="item"
                href="/category/thank-you-cards"
                style={{ pointerEvents: "all" }}
              >
                <div class="item-image">
                  <div class="image-container">
                    <div
                      class="image"
                      style={{
                        backgroundImage:
                          "url('https://media.bunches.co.uk/content_pages/greetings_cards/greetings-card-thank-you.jpg')",
                      }}
                    ></div>
                  </div>
                </div>
                Thank You
              </a>
              <a
                class="item"
                href="/category/get-well-cards"
                style={{ pointerEvents: "all" }}
              >
                <div class="item-image">
                  <div class="image-container">
                    <div
                      class="image"
                      style={{
                        backgroundImage:
                          "url('https://media.bunches.co.uk/content_pages/greetings_cards/greetings-card-get-well-soon.jpg')",
                      }}
                    ></div>
                  </div>
                </div>
                Get Well Soon
              </a>
            </div>
          </div>
        </section>
        <section class="greetings-card-popular-moments">
          <header class="lined-header" aria-label="header">
            <h2>Popular Moments</h2>
          </header>
          <div class="content">
            <div class="moment">
              <a href="/category/personalised-cards">
                <img
                  src="https://media.bunches.co.uk/content_pages/greetings_cards/greetings-card-personalised-cards.jpg"
                  alt="Personalised Cards"
                />
                <div class="text-content">
                  <p>Personalised Cards</p>
                  <p>Add your own photo and text</p>
                </div>
              </a>
            </div>
            <div class="moment">
              <a href="/category/birthdays-by-age">
                <img
                  src="https://media.bunches.co.uk/content_pages/greetings_cards/greetings-card-birthday-by-age.jpg"
                  alt="Birthdays by Age"
                />
                <div class="text-content">
                  <p>Birthdays by Age</p>
                  <p>Celebrate an age milestone</p>
                </div>
              </a>
            </div>
            <div class="moment">
              <a href="/category/a-new-chapter">
                <img
                  src="https://media.bunches.co.uk/content_pages/greetings_cards/greetings-card-a-new-chapter.jpg"
                  alt="A New Chapter"
                />
                <div class="text-content">
                  <p>A New Chapter</p>
                  <p>Enjoy the special times</p>
                </div>
              </a>
            </div>
            <div class="moment">
              <a href="/category/just-because">
                <img
                  src="https://media.bunches.co.uk/content_pages/greetings_cards/greetings-card-just-because.jpg"
                  alt="Just Because"
                />
                <div class="text-content">
                  <p>Just Because</p>
                  <p>Tell them how great they are</p>
                </div>
              </a>
            </div>
          </div>
        </section>
        <section
          class="greetings-card-carousel"
          aria-label="Popular cards right now"
        >
          <header class="lined-header" aria-label="header">
            <h2>
              <a href="/category/greetings-cards">Popular cards right now</a>
              <a class="view-all-headerlink" href="/category/greetings-cards">
                View all
              </a>
            </h2>
          </header>
          <div class="items">
            <div class="item">
              <div class="favourite-button-container">
                <a
                  href="/product/happy-birthday-balloons-a5"
                  style={{ pointerEvents: "all" }}
                >
                  <div class="card-image-container">
                    <span class="card-shadow">
                      <img
                        class="img-fluid"
                        src="https://media.bunches.co.uk/products/586x586/k0001.jpg"
                        alt="Happy Birthday Balloons"
                        loading="lazy"
                      />
                    </span>
                  </div>
                </a>
                <span class="favourite-button">
                  <span class="background">
                    <i
                      class="fa fa-heart-o heart"
                      role="button"
                      aria-label="Add to Favourites"
                    ></i>
                  </span>
                </span>
              </div>
            </div>
            <div class="item">
              <div class="favourite-button-container">
                <a
                  href="/product/happy-birthday-card"
                  style={{ pointerEvents: "all" }}
                >
                  <div class="card-image-container">
                    <span class="card-shadow">
                      <img
                        class="img-fluid"
                        src="https://media.bunches.co.uk/products/586x586/k0003.jpg"
                        alt="Happy Birthday Card"
                        loading="lazy"
                      />
                    </span>
                  </div>
                </a>
                <span class="favourite-button">
                  <span class="background">
                    <i
                      class="fa fa-heart-o heart"
                      role="button"
                      aria-label="Add to Favourites"
                    ></i>
                  </span>
                </span>
              </div>
            </div>
            <div class="item">
              <div class="favourite-button-container">
                <a
                  href="/product/cute-anniversary"
                  style={{ pointerEvents: "all" }}
                >
                  <div class="card-image-container">
                    <span class="card-shadow">
                      <img
                        class="img-fluid"
                        src="https://media.bunches.co.uk/products/586x586/k0008.jpg"
                        alt="Cute Anniversary"
                        loading="lazy"
                      />
                    </span>
                  </div>
                </a>
                <span class="favourite-button">
                  <span class="background">
                    <i
                      class="fa fa-heart-o heart"
                      role="button"
                      aria-label="Add to Favourites"
                    ></i>
                  </span>
                </span>
              </div>
            </div>
            <div class="item">
              <div class="favourite-button-container">
                <a
                  href="/product/flowery-get-well-soon"
                  style={{ pointerEvents: "all" }}
                >
                  <div class="card-image-container">
                    <span class="card-shadow">
                      <img
                        class="img-fluid"
                        src="https://media.bunches.co.uk/products/586x586/k0009.jpg"
                        alt="Flowery Get Well Soon"
                        loading="lazy"
                      />
                    </span>
                  </div>
                </a>
                <span class="favourite-button">
                  <span class="background">
                    <i
                      class="fa fa-heart-o heart"
                      role="button"
                      aria-label="Add to Favourites"
                    ></i>
                  </span>
                </span>
              </div>
            </div>
            <div class="item">
              <div class="favourite-button-container">
                <a
                  href="/product/engagement-congratulations"
                  style={{ pointerEvents: "all" }}
                >
                  <div class="card-image-container">
                    <span class="card-shadow">
                      <img
                        class="img-fluid"
                        src="https://media.bunches.co.uk/products/586x586/k0083.jpg"
                        alt="Engagement Congratulations"
                        loading="lazy"
                      />
                    </span>
                  </div>
                </a>
                <span class="favourite-button">
                  <span class="background">
                    <i
                      class="fa fa-heart-o heart"
                      role="button"
                      aria-label="Add to Favourites"
                    ></i>
                  </span>
                </span>
              </div>
            </div>
            <div class="item">
              <div class="favourite-button-container">
                <a href="/product/well-done" style={{ pointerEvents: "all" }}>
                  <div class="card-image-container">
                    <span class="card-shadow">
                      <img
                        class="img-fluid"
                        src="https://media.bunches.co.uk/products/586x586/k0130.jpg"
                        alt="Well Done"
                        loading="lazy"
                      />
                    </span>
                  </div>
                </a>
                <span class="favourite-button">
                  <span class="background">
                    <i
                      class="fa fa-heart-o heart"
                      role="button"
                      aria-label="Add to Favourites"
                    ></i>
                  </span>
                </span>
              </div>
            </div>
          </div>
        </section>
        <section
          class="greetings-card-carousel"
          aria-label="Personalised Cards"
        >
          <header class="lined-header" aria-label="header">
            <h2>
              <a href="/category/personalised-cards">Personalised Cards</a>
              <a
                class="view-all-headerlink"
                href="/category/personalised-cards"
              >
                View all
              </a>
            </h2>
          </header>
          <div class="items">
            <div class="item">
              <div class="favourite-button-container">
                <a
                  href="/product/rectangle-flower-frame-happy-birthday-personalised-card"
                  style={{ pointerEvents: "all" }}
                >
                  <div class="card-image-container">
                    <span class="card-shadow">
                      <img
                        class="img-fluid"
                        src="https://media.bunches.co.uk/products/586x586/kp095a5-2.jpg"
                        alt="Rectangle Flower Frame Happy Birthday Personalised Card"
                        loading="lazy"
                      />
                    </span>
                  </div>
                </a>
                <span class="favourite-button">
                  <span class="background">
                    <i
                      class="fa fa-heart-o heart"
                      role="button"
                      aria-label="Add to Favourites"
                    ></i>
                  </span>
                </span>
              </div>
            </div>
            <div class="item">
              <div class="favourite-button-container">
                <a
                  href="/product/pastel-dots-happy-birthday-photographic-personalised-card"
                  style={{ pointerEvents: "all" }}
                >
                  <div class="card-image-container">
                    <span class="card-shadow">
                      <img
                        class="img-fluid"
                        src="https://media.bunches.co.uk/products/586x586/kp0105a5-2.png"
                        alt="Pastel Dots Happy Birthday Photographic Personalised Card"
                        loading="lazy"
                      />
                    </span>
                  </div>
                </a>
                <span class="favourite-button">
                  <span class="background">
                    <i
                      class="fa fa-heart-o heart"
                      role="button"
                      aria-label="Add to Favourites"
                    ></i>
                  </span>
                </span>
              </div>
            </div>
            <div class="item">
              <div class="favourite-button-container">
                <a
                  href="/product/monochrome-floral-birthday-personalised-card"
                  style={{ pointerEvents: "all" }}
                >
                  <div class="card-image-container">
                    <span class="card-shadow">
                      <img
                        class="img-fluid"
                        src="https://media.bunches.co.uk/products/586x586/kp075a5-1.jpg"
                        alt="Monochrome Floral Birthday Personalised Card"
                        loading="lazy"
                      />
                    </span>
                  </div>
                </a>
                <span class="favourite-button">
                  <span class="background">
                    <i
                      class="fa fa-heart-o heart"
                      role="button"
                      aria-label="Add to Favourites"
                    ></i>
                  </span>
                </span>
              </div>
            </div>
            <div class="item">
              <div class="favourite-button-container">
                <a
                  href="/product/flower-corners-happy-birthday-bestie-personalised-card"
                  style={{ pointerEvents: "all" }}
                >
                  <div class="card-image-container">
                    <span class="card-shadow">
                      <img
                        class="img-fluid"
                        src="https://media.bunches.co.uk/products/586x586/kp097a5-2.jpg"
                        alt="Flower Corners Happy Birthday Bestie Personalised Card"
                        loading="lazy"
                      />
                    </span>
                  </div>
                </a>
                <span class="favourite-button">
                  <span class="background">
                    <i
                      class="fa fa-heart-o heart"
                      role="button"
                      aria-label="Add to Favourites"
                    ></i>
                  </span>
                </span>
              </div>
            </div>
            <div class="item">
              <div class="favourite-button-container">
                <a
                  href="/product/red-happy-birthday-photo-card"
                  style={{ pointerEvents: "all" }}
                >
                  <div class="card-image-container">
                    <span class="card-shadow">
                      <img
                        class="img-fluid"
                        src="https://media.bunches.co.uk/products/586x586/kp058-1.png"
                        alt="Red Happy Birthday Photo Card"
                        loading="lazy"
                      />
                    </span>
                  </div>
                </a>
                <span class="favourite-button">
                  <span class="background">
                    <i
                      class="fa fa-heart-o heart"
                      role="button"
                      aria-label="Add to Favourites"
                    ></i>
                  </span>
                </span>
              </div>
            </div>
            <div class="item">
              <div class="favourite-button-container">
                <a
                  href="/product/not-old-just-retro-personalised"
                  style={{ pointerEvents: "all" }}
                >
                  <div class="card-image-container">
                    <span class="card-shadow">
                      <img
                        class="img-fluid"
                        src="https://media.bunches.co.uk/products/586x586/kp078-1.jpg"
                        alt="Not Old Just Retro Personalised"
                        loading="lazy"
                      />
                    </span>
                  </div>
                </a>
                <span class="favourite-button">
                  <span class="background">
                    <i
                      class="fa fa-heart-o heart"
                      role="button"
                      aria-label="Add to Favourites"
                    ></i>
                  </span>
                </span>
              </div>
            </div>
          </div>
        </section>
        <section class="greetings-card-reasons">
          <div class="content">
            <div class="reason">
              <img
                src="https://media.bunches.co.uk/content_pages/greetings_cards/greetings-card-delivery.svg"
                width="60"
                height="60"
                alt="UK Delivery from £1"
              />
              <div class="description">
                <p>UK Delivery from £1</p>
              </div>
            </div>
            <div class="reason">
              <img
                src="https://media.bunches.co.uk/content_pages/greetings_cards/greetings-card-recycle.svg"
                width="60"
                height="60"
                alt="100% Recyclable"
              />
              <div class="description">
                <p>100% Recyclable, FSC Certified</p>
              </div>
            </div>
            <div class="reason">
              <img
                src="https://media.bunches.co.uk/content_pages/greetings_cards/greetings-card-highquality.svg"
                width="60"
                height="60"
                alt="High Quality Card"
              />
              <div class="description">
                <p>High Quality 300GSM Card</p>
              </div>
            </div>
            <div class="reason">
              <img
                src="https://media.bunches.co.uk/content_pages/greetings_cards/greetings-card-posypoints.svg"
                width="60"
                height="60"
                alt="Earn Posy Points"
              />
              <div class="description">
                <p>Earn Posy Points On Your Order</p>
              </div>
            </div>
          </div>
        </section>
        <section class="greetings-card-why-choose-bunches">
          <div class="content">
            <img
              width="240"
              height="240"
              src="https://media.bunches.co.uk/content_pages/greetings_cards/greetings-card-why-choose-bunches.gif"
            />
            <div class="text-content">
              <h4>Why choose Bunches</h4>
              <p>
                Since 1989 we have donated 10% of our profits to charity and
                we're committed to becoming a sustainable business. We care
                about you - our excellent customer support team are always here
                to help.
              </p>
              <a href="/about-us">Learn more</a>
            </div>
          </div>
        </section>
        <div class="greetings-card-categories">
          <a
            class="column personalised-cards"
            href="/category/personalised-cards"
          >
            Personalised Cards
          </a>
          <a class="column birthday-cards" href="/category/birthday-cards">
            All Birthday Cards
          </a>
          <a class="column get-well-soon-cards" href="/category/get-well-cards">
            Get Well Soon Cards
          </a>
          <a class="column thank-you-cards" href="/category/thank-you-cards">
            Thank You Cards
          </a>
        </div>
      </div>
      <section class="email-newsletter">
        <div class="header">
          <h2>
            <img
              class="img-fluid icon"
              src="https://media.bunches.co.uk/icons/icon_envelope.svg"
              alt="Icon of an envelope representing our newsletter email."
              width="28"
              height="20"
              loading="lazy"
            />
            Keep in touch with us
          </h2>
          <p>
            Sign up to our emails for news, competitions and exclusive
            discounts.
          </p>
        </div>
        <form class="form">
          <input
            type="text"
            class="input--full"
            placeholder="Your name"
            aria-label="Your full name"
            required=""
            value=""
          />
          <input
            type="email"
            class="input--full"
            placeholder="Your email address"
            aria-label="The email address you'd like to receive the newsletter to"
            required=""
            value=""
          />
          <button class="bn-button bn-button--primary" type="submit">
            Subscribe
          </button>
        </form>
        <section class="notification-section"></section>
        <div class="small-print">
          <p>
            We won't share your details with anyone. View our
            <a href="/terms#privacyPolicy">Privacy Policy</a>.
          </p>
        </div>
      </section>
    </main>
  );
};

export default GreetingsCards;
