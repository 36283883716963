import React, { useState } from "react";
import CheckOutCardModalFilters from "./CheckOutCardModalFilters";
import CheckOutCardModelCard from "./CheckOutCardModelCard";
import CheckOutCardModelPersonalizeMeCard from "./CheckOutCardModelPersonalizeMeCard";

const closeIcon = (
  <svg
    width="12"
    height="12"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18 1L2 17"
      stroke="#ffffff"
      strokeWidth="2"
      strokeLinecap="round"
    ></path>
    <path
      d="M2 1L18 17"
      stroke="#ffffff"
      strokeWidth="2"
      strokeLinecap="round"
    ></path>
  </svg>
);

const filterIcon = (
  <svg
    width="13"
    height="15"
    viewBox="0 0 15 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 11C0.585786 11 0.25 11.3358 0.25 11.75C0.25 12.1642 0.585786 12.5 1 12.5V11ZM4 12.5H4.75V11H4V12.5ZM1 12.5H4V11H1V12.5Z"
      fill="#78858D"
    ></path>
    <circle cx="5.75" cy="11.75" r="1.25" stroke="#78858D"></circle>
    <path
      d="M13.7002 12.5C14.1144 12.5 14.4502 12.1642 14.4502 11.75C14.4502 11.3358 14.1144 11 13.7002 11L13.7002 12.5ZM7.5 11L6.75 11L6.75 12.5L7.5 12.5L7.5 11ZM13.7002 11L7.5 11L7.5 12.5L13.7002 12.5L13.7002 11Z"
      fill="#78858D"
    ></path>
    <path
      d="M1 6C0.585786 6 0.25 6.33579 0.25 6.75C0.25 7.16421 0.585786 7.5 1 7.5V6ZM7.5 7.5H8.25V6H7.5V7.5ZM1 7.5H7.5V6H1V7.5Z"
      fill="#78858D"
    ></path>
    <circle cx="9.3501" cy="6.75" r="1.25" stroke="#78858D"></circle>
    <path
      d="M13.7002 7.5C14.1144 7.5 14.4502 7.16421 14.4502 6.75C14.4502 6.33579 14.1144 6 13.7002 6L13.7002 7.5ZM11.5 6L10.75 6L10.75 7.5L11.5 7.5L11.5 6ZM13.7002 6L11.5 6L11.5 7.5L13.7002 7.5L13.7002 6Z"
      fill="#78858D"
    ></path>
    <path
      d="M13.7002 2.5C14.1144 2.5 14.4502 2.16421 14.4502 1.75C14.4502 1.33579 14.1144 1 13.7002 1L13.7002 2.5ZM6.1002 1L5.35019 1L5.35019 2.5L6.1002 2.5L6.1002 1ZM13.7002 1L6.1002 1L6.1002 2.5L13.7002 2.5L13.7002 1Z"
      fill="#78858D"
    ></path>
    <circle
      cx="4.3501"
      cy="1.75"
      r="1.25"
      transform="rotate(180 4.3501 1.75)"
      stroke="#78858D"
    ></circle>
    <path
      d="M1 1C0.585786 1 0.25 1.33579 0.25 1.75C0.25 2.16421 0.585787 2.5 1 2.5L1 1ZM2.6 2.5L3.35 2.5L3.35 1L2.6 1L2.6 2.5ZM1 2.5L2.6 2.5L2.6 1L1 1L1 2.5Z"
      fill="#78858D"
    ></path>
  </svg>
);

const CheckOutCardModal = ({ toggleModal, isClickedCard }) => {
  const [isClickedFilter, setIsClickedFilter] = useState(true);
  const handleFilterButtonClick = () => {
    setIsClickedFilter(!isClickedFilter);
  };
  return (
    <dialog
      class="modal active"
      role="alertdialog"
      aria-live="assertive"
      aria-modal="true"
      open={isClickedCard}
    >
      <div class="modal-content">
        <div class="hzNOY_PJb_wCzx_hPONA">
          <div class="XSchBvMKdbbGdMR44IXb">
            <h1>Choose Your Card</h1>
            <div class="l9jIoVk6WeGo4A7i7L_f">(from just £2.25)</div>
            <svg
              onClick={() => toggleModal(true)}
              width="15"
              height="15"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="close-modal"
              role="button"
              aria-label="Close Modal"
              tabindex="0"
            >
              <title>Close Modal</title>
              <path
                d="M18 1L2 17"
                stroke="#78858D"
                strokeWidth="2"
                strokeLinecap="round"
              ></path>
              <path
                d="M2 1L18 17"
                stroke="#78858D"
                strokeWidth="2"
                strokeLinecap="round"
              ></path>
            </svg>
          </div>
          <div class="E4hIbmavMUWH4WAzB2Lk">
            <div class="Js7G3UQygbsBtXFP5RAy">
              <div
                onClick={handleFilterButtonClick}
                class="aAkyr1uSQmPrRLRRVfGs"
                role="button"
              >
                Filters{filterIcon}
              </div>
              <input
                class="xAuluw12iCFnzs62ZPNu"
                type="search"
                placeholder="Search Cards"
                value=""
              />
              <div
                class="nE90M7h9PK2NRaqYoXOf"
                data-filters="true"
                hidden={isClickedFilter}
              >
                <CheckOutCardModalFilters />
              </div>
            </div>
            <div class="TaQv6pP_PNzV1Lhxij1S">
              <div class="PJMtIuC1l7QtbhDX5B6t" role="button">
                Thinking of You
                {closeIcon}
              </div>
            </div>
            <div class="Bjmv7X0pUnmfmkpBRfmW">
              <img
                src="https://media.bunches.co.uk/products/k0000-web.png"
                width="80"
                height="80"
                alt="Default Card"
              />
              <span>
                <button class="byN5lklEheSE7lsR_rhp">
                  Choose free standard card
                </button>
              </span>
            </div>
            <div class="B00XZZmis3lF8w82yGfw">
              <CheckOutCardModelCard />

              <CheckOutCardModelPersonalizeMeCard />
            </div>
            <div class="Bjmv7X0pUnmfmkpBRfmW">
              <img
                src="https://media.bunches.co.uk/products/k0000-web.png"
                width="80"
                height="80"
                alt="Default Card"
              />
              <span>
                <button class="byN5lklEheSE7lsR_rhp">
                  Choose free standard card
                </button>
              </span>
            </div>
          </div>
        </div>
      </div>
    </dialog>
  );
};

export default CheckOutCardModal;
