import React, { useEffect } from "react";
import DescNav from "./DescNav";
import MobileNavBar from "./MobileNavBar";
import { useSelector, useDispatch } from "react-redux";
import { addBasket } from "../../../../features/basket/basket";

const MainHeader = ({ category }) => {
  const dispatch = useDispatch();
  const basket = useSelector((state) => state.basket);

  useEffect(() => {
    const userCart = localStorage.getItem("user-cart");
    if (userCart) {
      dispatch(addBasket(JSON.parse(userCart)));
    }
  }, []);

  return (
    <header className="bn-header clear">
      <DescNav />
      <MobileNavBar />
    </header>
  );
};

export default MainHeader;
