import React from "react";

const CheckOutProductsModal = ({ isClickedProduct, toggleProductModal }) => {
  return (
    <dialog
      class="modal active"
      role="alertdialog"
      aria-live="assertive"
      aria-modal="true"
      open={isClickedProduct}
    >
      <div class="modal-content">
        <div class="erfw7uUlLUpdukLwZH3g">
          <div class="WrpQFrPu7F5zGNM_iMJq">
            <img
              src="https://media.bunches.co.uk/products/586x586/cakb-1.jpg"
              alt="Mini Birthday Cake"
            />
            <div class="i96pizX75PGGUwB0kWb0">
              <h2>Mini Birthday Cake</h2>
              <h3>£3.99</h3>
              <div class="fZXB1KsRwy0wQy2RVgMi">
                Wish someone a Happy Birthday with a 6cm sq fruit cake finished
                with a ribbon.
                <br />
                <br />
                <i>
                  Suitable for vegetarians. Contains traces of Nuts, Egg, Milk
                  Wheat, Gluten, Soya and Fortified British Wine.
                </i>
                <br />
                <br />
              </div>
              <div class="Hfz1ksh2YHdWf9YlJEr4">
                <button class="byN5lklEheSE7lsR_rhp">Add</button>
                <button
                  onClick={() => toggleProductModal()}
                  class="e7QvJMPhPw6Pbciz9UHc YLxdcrrIEpFGSqqBDTXs"
                >
                  Select a different extra
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </dialog>
  );
};

export default CheckOutProductsModal;
