import React from "react";

const Delivery = () => {
  return (
    <main id="main-container" class="container top-margin">
      <div class="nav-blackout"></div>
      <main class="row static-page">
        <section class="col-md-12">
          <article class="card">
            <header class="card-header">
              <h2 class="card-title">Delivery Information</h2>
            </header>
            <div class="card-block">
              <section>
                <h3 class="h3">General Information for All Orders</h3>
                <ul>
                  <li>
                    Orders must be placed before 5:15pm Monday to Friday and
                    before 10:45am Saturday and Sunday for next day delivery.
                  </li>
                  <li>
                    Orders can be taken in advance and stored on our system
                    until the required date.
                  </li>
                  <li>
                    An email confirmation will be sent out once the order is
                    successful.
                  </li>
                  <li>
                    We do not pass on any personal details and the only
                    information sent out to the recipient with the gift is the
                    personal message card.
                  </li>
                  <li>
                    For Royal Mail deliveries, should the recipient be away at
                    the time of delivery, a card will be left stating attempted
                    delivery and will included details of how to collect or
                    arrange a re-delivery.
                  </li>
                  <li>
                    We can deliver flowers, plants and greetings cards to any
                    postal address in England, Wales, Scotland (including all
                    highlands and islands), Northern Ireland, the Channel
                    Islands, Isle of Man, Orkney and Shetland.
                  </li>
                  <li>
                    We are unable to deliver any item to the Republic of
                    Ireland.
                  </li>
                  <li>
                    Unfortunately we cannot offer same day delivery and there
                    are no deliveries on Bank Holidays.
                  </li>
                  <li>
                    When ordering please enter the full address including the
                    postcode of the recipient.
                  </li>
                </ul>
                <p>
                  <strong>
                    We will not be held responsible for items that are not
                    delivered or are delayed due to incorrect details.
                  </strong>
                </p>
                <p>
                  Deliveries sent to hospitals, schools, military and business
                  addresses will be delivered to a main reception or mailing
                  room prior to internal distribution. We recommend that orders
                  to such locations be requested for delivery on a weekday and
                  not a Saturday. If you are sending flowers to a hospital,
                  please check in advance that the hospital will allow fresh
                  flowers to be delivered to the wards.
                </p>
              </section>
              <section>
                <h3 class="h3">Delivery Details for Flowers and Gifts</h3>
                <ul>
                  <li>
                    All flowers are sent via Royal Mail Tracked 24 or an
                    equivalent service for £3.49 unless otherwise stated. Orders
                    placed with this delivery option will receive full online
                    tracking. An email with full tracking details will be sent
                    at 8pm the evening before your gift is due to be delivered
                    once it has been dispatched.
                  </li>
                  <li>
                    We usually dispatch flowers one day prior to the delivery
                    date requested, 90% of orders are delivered on the next
                    working day (excluding bank holidays), however there is a
                    chance your order may arrive the day after.
                  </li>
                  <li>
                    Delivery to the following postcodes may take longer than 1-2
                    days to arrive with a usual timeframe of 3-5 days:
                    <ul>
                      <li>
                        <strong>
                          HS, KW15, PA20, PA28-PA38, PA41-PA49, PA60–PA78, PA80,
                          PH30, PH41, PH43, TR21-25, ZE, JE, GY, IM, KA27,
                          KW16-17, PH42, PH44.
                        </strong>
                      </li>
                    </ul>
                  </li>
                  <li>
                    Deliveries with Royal Mail Tracked 24 can take place up to
                    6pm.
                  </li>
                  <li>
                    For an additional fee of £5.99 we also offer a guaranteed
                    courier delivery service using either DPD, DHL, Royal Mail
                    Special Delivery, or an equivalent service, orders placed
                    with this delivery option will receive full online tracking
                    and may require a signature upon receipt of delivery. An
                    email with full tracking details will be sent at 8pm the
                    evening before your gift is due to be delivered once it has
                    been dispatched. If using our courier service via DPD and
                    the recipient is not able to accept the gift on the first
                    delivery attempt, a QR code will be emailed to the sender
                    with the details of where to collect the parcel from. This
                    will then need to be given to the recipient so that they can
                    collect their gift from the local pick up shop.
                  </li>
                </ul>
              </section>
              <section>
                <h3 class="h3">Delivery Details for Hampers</h3>
                <ul>
                  <li>
                    Hampers sent out via tracked courier (DPD/Hermes) are
                    delivered on your chosen date between Monday and Sunday.
                    Courier delivered hampers cannot be delivered on bank
                    holidays.
                  </li>
                  <li>
                    If the recipient is not able to accept the gift on the first
                    delivery attempt, a QR code will be emailed to the sender
                    with the details of where to collect the parcel from. This
                    will then need to be given to the recipient so that they can
                    collect their gift from the local pick up shop.
                  </li>
                  <li>
                    Delivery to the following postcodes may take between 2-7
                    days to arrive and may incur additional delivery charges:
                    <ul>
                      <li>
                        <strong>
                          AB30-63, DD8-10, FK19-21, G83-84, GY1-9, HS1-9,
                          IV1-39, IV40-56, IV57-63, IM1-9, IM99, JE1-3, KW1-18,
                          KA27-28, PA20-80, PH1, PH5-16, PH17-50, PO30-41,
                          SY23-25, SA20, TR12, TR19, TD15, TR21-25, ZE1-3.
                        </strong>
                      </li>
                    </ul>
                  </li>
                  <li>
                    The Saturday and Sunday delivery service is currently
                    unavailable for the Highlands and Islands.
                  </li>
                  <li>
                    We are unable to offer product or delivery refunds for
                    failed fresh food deliveries that are due to the recipient
                    not being at home or due to us having been provided with an
                    incorrect delivery address.
                  </li>
                </ul>
              </section>
              <section>
                <h3 class="h3">Delivery Details for Letterbox Hampers</h3>
                <ul>
                  <li>
                    All letterbox hampers are delivered within 1-2 days by one
                    of our trusted couriers, including Royal Mail Tracked 24 and
                    Evri.
                  </li>
                  <li>
                    The following postcodes may take between 2-7 days to arrive
                    and may incur additional delivery charges:
                    <ul>
                      <li>
                        <strong>
                          AB30-63, DD8-10, FK19-21, G83-84, GY1-9, HS1-9,
                          IV1-39, IV40-56, IV57-63, IM1-9, IM99, JE1-3, KW1-18,
                          KA27-28, PA20-80, PH1, PH5-16, PH17-50, PO30-41,
                          SY23-25, SA20, TR12, TR19, TD15, TR21-25, ZE1-3.
                        </strong>
                      </li>
                    </ul>
                  </li>
                </ul>
              </section>
            </div>
          </article>
        </section>
      </main>
    </main>
  );
};

export default Delivery;
