import React from "react";

const CookiesPolicy = () => {
  return (
    <main id="main-container" class="container top-margin">
      <div class="nav-blackout"></div>
      <main class="row static-page about-us-page">
        <section class="col-md-12">
          <div role="tablist" aria-multiselectable="true">
            <article class="card" hidden="">
              <header class="card-header" role="tab">
                <h2 class="card-title">What is a Cookie?</h2>
              </header>
              <div hidden="">
                <div class="card-block" role="tabpanel">
                  <p style={{ fontSize: "20px" }}>
                    A cookie is a small text file stored on a user’s computer
                    whilst they are browsing a website. We use cookies on our
                    site to track usage statistics, and to keep track of what
                    you have in your basket.
                  </p>
                  <p style={{ fontSize: "20px" }}>
                    To be able to order products from this website you need have
                    cookies enabled. If you do not have cookies enabled you will
                    still be able to view the website for browsing and research.
                    However, you will not be able to add products to your basket
                    and buy them.
                  </p>
                  <p style={{ fontSize: "20px" }}>
                    Our cookies do not store personal information such as credit
                    card details. Please note, that cookies can not harm your
                    computer, they are purely informational.
                  </p>
                </div>
              </div>
            </article>

            <article class="card" hidden="">
              <header class="card-header" role="tab">
                <h2 class="card-title">Our Cookies</h2>
              </header>
              <div hidden="">
                <div class="card-block" role="tabpanel">
                  <p style={{ fontSize: "20px" }}>
                    Our website uses cookies to allow basic function, such as
                    adding items to the basket, logging into an account, or
                    other persistent information.
                  </p>
                  <p style={{ fontSize: "20px" }}>
                    Here is a list of the cookies used on our website, and their
                    functions:
                  </p>
                </div>

                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    padding: "0px 1.25rem",
                  }}
                  role="tabpanel"
                >
                  <div style={{ fontSize: "20px", width: "15%" }}>
                    <p style={{ fontWeight: "bold" }}>Name</p>
                  </div>
                  <div style={{ fontSize: "20px", width: "85%" }}>
                    <p style={{ fontWeight: "bold" }}>Cookie Purpose</p>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    padding: "0px 1.25rem",
                  }}
                  role="tabpanel"
                >
                  <div style={{ fontSize: "20px", width: "15%" }}>
                    <p style={{ fontWeight: "bold" }}>JSESSIONID</p>
                  </div>
                  <div style={{ fontSize: "20px", width: "85%" }}>
                    <p>
                      This cookie is created when you first visit our website,
                      and is created to provide basic site function, such as
                      usage of the shopping basket, discount codes, and account
                      logins. Once the session is finished, this cookie is
                      removed from the computer. You can disable this cookie
                      through your browser settings, however doing so will
                      prevent this site from working correctly on your device.
                    </p>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    padding: "0px 1.25rem",
                  }}
                  role="tabpanel"
                >
                  <div style={{ fontSize: "20px", width: "15%" }}>
                    <p style={{ fontWeight: "bold" }}>_utma, </p>
                    <p style={{ fontWeight: "bold" }}>_utmb, </p>
                  </div>
                  <div style={{ fontSize: "20px", width: "85%" }}>
                    <p>
                      These cookies are used by Google Analytics, to allow basic
                      usage tracking of activity on the website. This allows us
                      to analyze vistor information, such as Browser usage, new
                      vistor number, time on site, and most popularly viewed
                      pages. This information is not open to the public, and
                      does not contain any personal information.
                    </p>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    padding: "0px 1.25rem",
                  }}
                  role="tabpanel"
                >
                  <div style={{ fontSize: "20px", width: "15%" }}>
                    <p style={{ fontWeight: "bold" }}>_utmc, </p>
                    <p style={{ fontWeight: "bold" }}>_utma, </p>
                  </div>
                  <div style={{ fontSize: "20px", width: "85%" }}>
                    <p>
                      These cookies may remain on your computer from thirty
                      minutes to 2 years. This is so that if you revisit our
                      site, you are not tracked as a new visitor but as a
                      returning visitor. You may clear these cookies at any
                      point by clearing your browser's browsing data, or opt out
                      of Google Analytics entirely using the Google Analytics
                      Opt-out Browser Add-on.
                    </p>
                  </div>
                </div>
              </div>
            </article>
            <article class="card" hidden="">
              <header class="card-header" role="tab">
                <h2 class="card-title">Third Party Cookies</h2>
              </header>
              <div hidden="">
                <div class="card-block" role="tabpanel">
                  <p style={{ fontSize: "20px" }}>
                    We may someitmes embed widgets on a webpage such as media
                    players or promotional links, for instance YouTube videos
                    and Skype buttons. If you go on to a web page that contains
                    embedded content, you may be sent cookies from those
                    websites.
                  </p>
                  <p style={{ fontSize: "20px" }}>
                    You may also see tools to 'share' to your friends through
                    social networks - such as Facebook and Twitter. You may be
                    sent cookies from these websites as well.
                  </p>
                  <p style={{ fontSize: "20px" }}>
                    Since we do not control these cookies, we suggest you check
                    these third party websites for information on their cookies
                    and how to manage them.
                  </p>
                </div>
              </div>
            </article>
          </div>
        </section>
      </main>
    </main>
  );
};

export default CookiesPolicy;
