import React from "react";

const CheckOutCardModelPersonalizeMeCard = () => {
  return (
    <div class="cLeHuTBWGsxj_UG4vN5w">
      <div class="mGQXLDnKYRzfedSy_V1K">
        <div class="VJmkproAOqb7kW_Fx4Uh">Personalise Me</div>
        <img
          src="https://media.bunches.co.uk/products/586x586/kp0170-1.jpg"
          width="285"
          height="285"
          alt="Simple Arch Design Personalised Card"
        />
      </div>
    </div>
  );
};

export default CheckOutCardModelPersonalizeMeCard;
