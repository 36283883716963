import React, { useState, useEffect } from "react";
import { chargePayment } from "../../api/api";
import { loadStripe } from "@stripe/stripe-js";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader/Loader";

const PaymentModal = ({
  isPayment,
  setIsPayment,
  paymentSuccessOfOrder,
  amountForPayment,
  orderId,
}) => {
  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const [stripe, setStripe] = useState(null);
  const [card, setCard] = useState(null);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [loaderVisible, setLoaderVisible] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setMessage("");
  }, [isPayment]);

  useEffect(() => {
    const initializeStripe = async () => {
      try {
        const stripePromise = await loadStripe(
          process.env.REACT_APP_STRIPE_PUBLIC_KEY
        );
        setStripe(stripePromise);
        setCard(stripePromise.elements().create("card"));
      } catch (error) {
        console.error("Error initializing Stripe:", error);
        setMessage("Error initializing Stripe. Please try again later.");
      }
    };

    initializeStripe();
  }, []);

  useEffect(() => {
    if (stripe && card) {
      card.mount("#card-element");
    }
  }, [stripe, card]);

  const handleSubmitForm = async (event) => {
    event.preventDefault();
    setSubmitDisabled(true);
    setLoaderVisible(true);
    setIsLoading(true);

    if (!stripe || !card) {
      setMessage("Stripe is not initialized properly.");
      setSubmitDisabled(false);
      setLoaderVisible(false);
      setIsLoading(false);
      return;
    }

    try {
      const result = await stripe.createToken(card);
      if (result.error) {
        setSubmitDisabled(false);
        setLoaderVisible(false);
        setIsLoading(false);
        setMessage(result.error.message);
      } else {
        const response = await chargePayment({
          stripeToken: result.token.id,
          amount: amountForPayment,
          currency: "GBP",
          order_id: orderId,
        });
        paymentSuccessOfOrder();
        // setSubmitDisabled(false);
        // setLoaderVisible(false);
        setTimeout(() => {
          setIsPayment(false);
          navigate("/account/order-history");
        }, 1500);
        setMessage("Order placed! You will receive an email confirmation.");
      }
    } catch (error) {
      setSubmitDisabled(false);
      setLoaderVisible(false);
      setIsLoading(false);
      console.error("Error processing payment:", error);
      setMessage("An error occurred while processing your payment.");
    }
  };

  return (
    <dialog
      className="modal active"
      role="alertdialog"
      aria-live="assertive"
      aria-modal="true"
      open={isPayment}
    >
      <div style={{ padding: "20px", width: "30%" }} className=" modal-content">
        <div style={{}} className="card m-4 p-3">
          <form
            action={`${process.env.REACT_APP_API_URL}/charge_payment`}
            method="post"
            onSubmit={handleSubmitForm}
            id="payment-form"
            style={{ padding: "20px" }}
          >
            <div className="form-group">
              <h3 style={{ marginBottom: "15px" }} htmlFor="card-element">
                Credit or debit card
              </h3>
              <div id="card-element"></div>
              <div id="card-errors" role="alert" style={{ color: "#dc3545" }}>
                {message}
              </div>
            </div>
            {isLoading ? (
              <Loader />
            ) : (
              <div>
                <button
                  style={{
                    display: submitDisabled || loaderVisible ? "none" : "",
                    border: "none",
                    background:
                      submitDisabled || loaderVisible ? "#cccccc" : "green",
                    borderRadius: "6px",
                    color: "white",
                    fontSize: "15px",
                    padding: "10px 10px 8px 10px",
                    marginTop: "10px",
                    marginRight: "10px",
                  }}
                  type="submit"
                  id="submit-button"
                  disabled={submitDisabled || loaderVisible}
                >
                  Submit Payment
                </button>
                <button
                  style={{
                    display: loaderVisible ? "none" : "",
                    border: "none",
                    background: loaderVisible ? "#cccccc" : "red",
                    borderRadius: "6px",
                    color: "white",
                    fontSize: "15px",
                    padding: "10px 10px 8px 10px",
                    marginTop: "10px",
                  }}
                  onClick={() => setIsPayment(false)}
                  disabled={loaderVisible}
                >
                  Cancel
                </button>
              </div>
            )}
          </form>
        </div>
      </div>
    </dialog>
  );
};

export default PaymentModal;
