import React, { useState } from "react";
import { subscribe_form } from "../../../../../api/api";

const KeepInTouchWithUs = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subscribe, setSubscribe] = useState("");
  const handleSubmitSubscription = async (e) => {
    e.preventDefault();
    if (name !== "" && email !== "") {
      try {
        const response = await subscribe_form({
          name,
          email,
        });
        if (response?.data?.message === "Subscribe form created successfully") {
          setSubscribe(true);
          setTimeout(() => {
            setSubscribe("success");
          }, 2000);
          setName("");
          setEmail("");
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      setSubscribe("name and email are required.");
    }
  };
  return (
    <section class="email-newsletter">
      <div class="header">
        <h2>
          <img
            class="img-fluid icon"
            src="https://media.bunches.co.uk/icons/icon_envelope.svg"
            alt="Icon of an envelope representing our newsletter email."
            width="28"
            height="20"
            loading="lazy"
          />
          Keep in touch with us
        </h2>
        <p>
          Sign up to our emails for news, competitions and exclusive discounts.
        </p>
      </div>
      <form onSubmit={handleSubmitSubscription} class="form">
        <input
          type="text"
          class="input--full"
          placeholder="Your name"
          aria-label="Your full name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <input
          type="email"
          class="input--full"
          placeholder="Your email address"
          aria-label="The email address you'd like to receive the newsletter to"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <button class="bn-button bn-button--primary" type="submit">
          Subscribe
        </button>
      </form>
      {subscribe === "name and email are required." && (
        <div style={{ color: "red" }}>{subscribe}</div>
      )}
      {subscribe === "success" && (
        <section class="notification-section">
          <div class="notification success" role="alert">
            Thank you for subscribing to our email. We'll be in touch soon!
          </div>
        </section>
      )}
      <div class="small-print">
        <p>
          We won't share your details with anyone. View our{" "}
          <a href="/terms#privacyPolicy">Privacy Policy</a>.
        </p>
      </div>
    </section>
  );
};

export default KeepInTouchWithUs;
