import React from "react";
import { Link } from "react-router-dom";
import "./product.css";
const imageUrl = process.env.REACT_APP_IMAGE_URL;
const Product = ({ product }) => {
  return (
    <>
      <div key={product?.id} class="_yAEQ5RaR87EE0i5m86b">
        <div class="favourite-button-container">
          <Link class="" to={`/product/${product?.id}`}>
            <img
              class="img-fluid"
              src={
                imageUrl +
                product?.product_variation[0]?.product_gallery[0]?.gallery_image
              }
              alt={product.product_name}
              width="285"
              height="285"
              loading="lazy"
            />
          </Link>
          {/* <span class="favourite-button">
            <span class="background">
              <i
                class="fa fa-heart-o heart"
                role="button"
                aria-label="Add to Favourites"
              ></i>
            </span>
          </span> */}
        </div>
        <div class="LrSBFs9Cjm3W7ppPILiL">
          <h3 class="zWNqrQKgvUN2QB07O_dP">
            <Link to={`/product/${product?.id}`}>{product?.product_name}</Link>
          </h3>
          {/* <div class="bo8oJd52T3hzyJ76k8vN">
            {product?.product_variation[0]?.variation_description}
          </div> */}
          {/* <p class="_gZi40WOUBBOiZObY0NI">
            £{product?.product_variation[0]?.variation_price}
          </p> */}
          {product?.product_variation[0]?.sale_price !== null ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <p
                class="_gZi40WOUBBOiZObY0NI colorGray"
                style={{ marginRight: "10px" }}
              >
                £{product?.product_variation[0]?.variation_price}
              </p>
              <p class="_gZi40WOUBBOiZObY0NI" style={{ marginLeft: "5px" }}>
                £{product?.product_variation[0]?.sale_price}
              </p>
            </div>
          ) : (
            <p class="_gZi40WOUBBOiZObY0NI">
              £{product?.product_variation[0]?.variation_price}
            </p>
          )}
        </div>
      </div>
    </>
  );
};

export default Product;
