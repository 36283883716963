import React from "react";
import "./IntegrityFlower.css";
import LandingPage from "./LandingPage/LandingPage";
import { useSelector } from "react-redux";

const IntegrityFlower = () => {
  const category = useSelector((state) => state.category);
  return (
    <>
      {category?.category?.length > 0 && (
        <div>
          <LandingPage />
        </div>
      )}
    </>
  );
};

export default IntegrityFlower;
