import React from "react";

const CheckOutCardModalFilters = () => {
  return (
    <div class="agKwmzaDHkj8ZY4wiCvI">
      <div class="PgSC6zTl3Apc9ED2r2KP">
        <div class="oxlsztlkGbEKYxPNa6fv">
          Occasion<i class="fa fa-angle-down"></i>
        </div>
        <div class="ZVgSsfqftSxlHOUWGq7A" hidden="">
          <div class="luyAVmesDCAfHRLyxEx3" role="button">
            <label>
              <span></span>
              <p>All Occasions</p>
            </label>
          </div>
          <div class="luyAVmesDCAfHRLyxEx3" role="button">
            <label>
              <span></span>
              <p>Anniversary</p>
            </label>
          </div>
          <div class="luyAVmesDCAfHRLyxEx3" role="button">
            <label>
              <span></span>
              <p>Birthday</p>
            </label>
          </div>
          <div class="luyAVmesDCAfHRLyxEx3" role="button">
            <label>
              <span></span>
              <p>Christmas</p>
            </label>
          </div>
          <div class="luyAVmesDCAfHRLyxEx3" role="button">
            <label>
              <span></span>
              <p>Congratulations</p>
            </label>
          </div>
          <div class="luyAVmesDCAfHRLyxEx3" role="button">
            <label>
              <span></span>
              <p>Easter</p>
            </label>
          </div>
          <div class="luyAVmesDCAfHRLyxEx3" role="button">
            <label>
              <span></span>
              <p>Especially for You</p>
            </label>
          </div>
          <div class="luyAVmesDCAfHRLyxEx3" role="button">
            <label>
              <span></span>
              <p>Father's Day</p>
            </label>
          </div>
          <div class="luyAVmesDCAfHRLyxEx3" role="button">
            <label>
              <span></span>
              <p>Friendship</p>
            </label>
          </div>
          <div class="luyAVmesDCAfHRLyxEx3" role="button">
            <label>
              <span></span>
              <p>Get Well Soon</p>
            </label>
          </div>
          <div class="luyAVmesDCAfHRLyxEx3" role="button">
            <label>
              <span></span>
              <p>Good Luck</p>
            </label>
          </div>
          <div class="luyAVmesDCAfHRLyxEx3" role="button">
            <label>
              <span></span>
              <p>Graduation</p>
            </label>
          </div>
          <div class="luyAVmesDCAfHRLyxEx3" role="button">
            <label>
              <span></span>
              <p>Leaving</p>
            </label>
          </div>
          <div class="luyAVmesDCAfHRLyxEx3" role="button">
            <label>
              <span></span>
              <p>Missing You</p>
            </label>
          </div>
          <div class="luyAVmesDCAfHRLyxEx3" role="button">
            <label>
              <span></span>
              <p>Mother's Day</p>
            </label>
          </div>
          <div class="luyAVmesDCAfHRLyxEx3" role="button">
            <label>
              <span></span>
              <p>New Baby</p>
            </label>
          </div>
          <div class="luyAVmesDCAfHRLyxEx3" role="button">
            <label>
              <span></span>
              <p>New Home</p>
            </label>
          </div>
          <div class="luyAVmesDCAfHRLyxEx3" role="button">
            <label>
              <span></span>
              <p>New Job</p>
            </label>
          </div>
          <div class="luyAVmesDCAfHRLyxEx3" role="button">
            <label>
              <span></span>
              <p>New Year</p>
            </label>
          </div>
          <div class="luyAVmesDCAfHRLyxEx3" role="button">
            <label>
              <span></span>
              <p>Newly Engaged</p>
            </label>
          </div>
          <div class="luyAVmesDCAfHRLyxEx3" role="button">
            <label>
              <span></span>
              <p>Retirement</p>
            </label>
          </div>
          <div class="luyAVmesDCAfHRLyxEx3" role="button">
            <label>
              <span></span>
              <p>Romance</p>
            </label>
          </div>
          <div class="luyAVmesDCAfHRLyxEx3" role="button">
            <label>
              <span></span>
              <p>Sorry</p>
            </label>
          </div>
          <div class="luyAVmesDCAfHRLyxEx3" role="button">
            <label>
              <span></span>
              <p>Sympathy</p>
            </label>
          </div>
          <div class="luyAVmesDCAfHRLyxEx3" role="button">
            <label>
              <span></span>
              <p>Thank You</p>
            </label>
          </div>
          <div class="luyAVmesDCAfHRLyxEx3" role="button">
            <label>
              <span>
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18 1L2 17"
                    stroke="#000000"
                    strokeWidth="2"
                    strokeLinecap="round"
                  ></path>
                  <path
                    d="M2 1L18 17"
                    stroke="#000000"
                    strokeWidth="2"
                    strokeLinecap="round"
                  ></path>
                </svg>
              </span>
              <p>Thinking of You</p>
            </label>
          </div>
          <div class="luyAVmesDCAfHRLyxEx3" role="button">
            <label>
              <span></span>
              <p>Valentine's Day</p>
            </label>
          </div>
          <div class="luyAVmesDCAfHRLyxEx3" role="button">
            <label>
              <span></span>
              <p>Wedding</p>
            </label>
          </div>
          <div class="luyAVmesDCAfHRLyxEx3" role="button">
            <label>
              <span></span>
              <p>Welcome Home</p>
            </label>
          </div>
          <div class="luyAVmesDCAfHRLyxEx3" role="button">
            <label>
              <span></span>
              <p>Well Done</p>
            </label>
          </div>
        </div>
      </div>
      <div class="PgSC6zTl3Apc9ED2r2KP">
        <div class="oxlsztlkGbEKYxPNa6fv">
          Recipient<i class="fa fa-angle-down"></i>
        </div>
        <div class="ZVgSsfqftSxlHOUWGq7A" hidden="">
          <div class="luyAVmesDCAfHRLyxEx3" role="button">
            <label>
              <span></span>
              <p>Auntie</p>
            </label>
          </div>
          <div class="luyAVmesDCAfHRLyxEx3" role="button">
            <label>
              <span></span>
              <p>Boyfriend</p>
            </label>
          </div>
          <div class="luyAVmesDCAfHRLyxEx3" role="button">
            <label>
              <span></span>
              <p>Brother</p>
            </label>
          </div>
          <div class="luyAVmesDCAfHRLyxEx3" role="button">
            <label>
              <span></span>
              <p>Cards for Children</p>
            </label>
          </div>
          <div class="luyAVmesDCAfHRLyxEx3" role="button">
            <label>
              <span></span>
              <p>Dad</p>
            </label>
          </div>
          <div class="luyAVmesDCAfHRLyxEx3" role="button">
            <label>
              <span></span>
              <p>Daughter</p>
            </label>
          </div>
          <div class="luyAVmesDCAfHRLyxEx3" role="button">
            <label>
              <span></span>
              <p>Female Birthday</p>
            </label>
          </div>
          <div class="luyAVmesDCAfHRLyxEx3" role="button">
            <label>
              <span></span>
              <p>Fiancee</p>
            </label>
          </div>
          <div class="luyAVmesDCAfHRLyxEx3" role="button">
            <label>
              <span></span>
              <p>Friend</p>
            </label>
          </div>
          <div class="luyAVmesDCAfHRLyxEx3" role="button">
            <label>
              <span></span>
              <p>Girlfriend</p>
            </label>
          </div>
          <div class="luyAVmesDCAfHRLyxEx3" role="button">
            <label>
              <span></span>
              <p>Grandparents</p>
            </label>
          </div>
          <div class="luyAVmesDCAfHRLyxEx3" role="button">
            <label>
              <span></span>
              <p>Husband</p>
            </label>
          </div>
          <div class="luyAVmesDCAfHRLyxEx3" role="button">
            <label>
              <span></span>
              <p>Male Birthday</p>
            </label>
          </div>
          <div class="luyAVmesDCAfHRLyxEx3" role="button">
            <label>
              <span></span>
              <p>Mum</p>
            </label>
          </div>
          <div class="luyAVmesDCAfHRLyxEx3" role="button">
            <label>
              <span></span>
              <p>New Dad</p>
            </label>
          </div>
          <div class="luyAVmesDCAfHRLyxEx3" role="button">
            <label>
              <span></span>
              <p>New Mum</p>
            </label>
          </div>
          <div class="luyAVmesDCAfHRLyxEx3" role="button">
            <label>
              <span></span>
              <p>Sister</p>
            </label>
          </div>
          <div class="luyAVmesDCAfHRLyxEx3" role="button">
            <label>
              <span></span>
              <p>Son</p>
            </label>
          </div>
          <div class="luyAVmesDCAfHRLyxEx3" role="button">
            <label>
              <span></span>
              <p>Uncle</p>
            </label>
          </div>
          <div class="luyAVmesDCAfHRLyxEx3" role="button">
            <label>
              <span></span>
              <p>Wife</p>
            </label>
          </div>
        </div>
      </div>
      <div class="PgSC6zTl3Apc9ED2r2KP">
        <div class="oxlsztlkGbEKYxPNa6fv">
          Birthday Ages<i class="fa fa-angle-down"></i>
        </div>
        <div class="ZVgSsfqftSxlHOUWGq7A" hidden="">
          <div class="luyAVmesDCAfHRLyxEx3" role="button">
            <label>
              <span></span>
              <p>18th</p>
            </label>
          </div>
          <div class="luyAVmesDCAfHRLyxEx3" role="button">
            <label>
              <span></span>
              <p>20th</p>
            </label>
          </div>
          <div class="luyAVmesDCAfHRLyxEx3" role="button">
            <label>
              <span></span>
              <p>21st</p>
            </label>
          </div>
          <div class="luyAVmesDCAfHRLyxEx3" role="button">
            <label>
              <span></span>
              <p>25th</p>
            </label>
          </div>
          <div class="luyAVmesDCAfHRLyxEx3" role="button">
            <label>
              <span></span>
              <p>30th</p>
            </label>
          </div>
          <div class="luyAVmesDCAfHRLyxEx3" role="button">
            <label>
              <span></span>
              <p>40th</p>
            </label>
          </div>
          <div class="luyAVmesDCAfHRLyxEx3" role="button">
            <label>
              <span></span>
              <p>55th</p>
            </label>
          </div>
          <div class="luyAVmesDCAfHRLyxEx3" role="button">
            <label>
              <span></span>
              <p>50th</p>
            </label>
          </div>
          <div class="luyAVmesDCAfHRLyxEx3" role="button">
            <label>
              <span></span>
              <p>60th</p>
            </label>
          </div>
          <div class="luyAVmesDCAfHRLyxEx3" role="button">
            <label>
              <span></span>
              <p>65th</p>
            </label>
          </div>
          <div class="luyAVmesDCAfHRLyxEx3" role="button">
            <label>
              <span></span>
              <p>70th</p>
            </label>
          </div>
          <div class="luyAVmesDCAfHRLyxEx3" role="button">
            <label>
              <span></span>
              <p>75th</p>
            </label>
          </div>
          <div class="luyAVmesDCAfHRLyxEx3" role="button">
            <label>
              <span></span>
              <p>80th</p>
            </label>
          </div>
          <div class="luyAVmesDCAfHRLyxEx3" role="button">
            <label>
              <span></span>
              <p>90th</p>
            </label>
          </div>
          <div class="luyAVmesDCAfHRLyxEx3" role="button">
            <label>
              <span></span>
              <p>100th</p>
            </label>
          </div>
        </div>
      </div>
      <div class="PgSC6zTl3Apc9ED2r2KP">
        <div class="oxlsztlkGbEKYxPNa6fv">
          Anniversaries<i class="fa fa-angle-down"></i>
        </div>
        <div class="ZVgSsfqftSxlHOUWGq7A" hidden="">
          <div class="luyAVmesDCAfHRLyxEx3" role="button">
            <label>
              <span></span>
              <p>1st</p>
            </label>
          </div>
          <div class="luyAVmesDCAfHRLyxEx3" role="button">
            <label>
              <span></span>
              <p>10th</p>
            </label>
          </div>
          <div class="luyAVmesDCAfHRLyxEx3" role="button">
            <label>
              <span></span>
              <p>15th</p>
            </label>
          </div>
          <div class="luyAVmesDCAfHRLyxEx3" role="button">
            <label>
              <span></span>
              <p>20th</p>
            </label>
          </div>
          <div class="luyAVmesDCAfHRLyxEx3" role="button">
            <label>
              <span></span>
              <p>25th</p>
            </label>
          </div>
          <div class="luyAVmesDCAfHRLyxEx3" role="button">
            <label>
              <span></span>
              <p>30th</p>
            </label>
          </div>
          <div class="luyAVmesDCAfHRLyxEx3" role="button">
            <label>
              <span></span>
              <p>40th</p>
            </label>
          </div>
          <div class="luyAVmesDCAfHRLyxEx3" role="button">
            <label>
              <span></span>
              <p>50th</p>
            </label>
          </div>
          <div class="luyAVmesDCAfHRLyxEx3" role="button">
            <label>
              <span></span>
              <p>60th</p>
            </label>
          </div>
        </div>
      </div>
      <div class="PgSC6zTl3Apc9ED2r2KP">
        <div class="oxlsztlkGbEKYxPNa6fv">
          Style<i class="fa fa-angle-down"></i>
        </div>
        <div class="ZVgSsfqftSxlHOUWGq7A" hidden="">
          <div class="luyAVmesDCAfHRLyxEx3" role="button">
            <label>
              <span></span>
              <p>Animals</p>
            </label>
          </div>
          <div class="luyAVmesDCAfHRLyxEx3" role="button">
            <label>
              <span></span>
              <p>Artistic</p>
            </label>
          </div>
          <div class="luyAVmesDCAfHRLyxEx3" role="button">
            <label>
              <span></span>
              <p>Cute</p>
            </label>
          </div>
          <div class="luyAVmesDCAfHRLyxEx3" role="button">
            <label>
              <span></span>
              <p>Humourous</p>
            </label>
          </div>
          <div class="luyAVmesDCAfHRLyxEx3" role="button">
            <label>
              <span></span>
              <p>Illustrative</p>
            </label>
          </div>
          <div class="luyAVmesDCAfHRLyxEx3" role="button">
            <label>
              <span></span>
              <p>Personalised</p>
            </label>
          </div>
          <div class="luyAVmesDCAfHRLyxEx3" role="button">
            <label>
              <span></span>
              <p>Photographic</p>
            </label>
          </div>
          <div class="luyAVmesDCAfHRLyxEx3" role="button">
            <label>
              <span></span>
              <p>Romantic</p>
            </label>
          </div>
          <div class="luyAVmesDCAfHRLyxEx3" role="button">
            <label>
              <span></span>
              <p>Sympathy</p>
            </label>
          </div>
          <div class="luyAVmesDCAfHRLyxEx3" role="button">
            <label>
              <span></span>
              <p>Traditional</p>
            </label>
          </div>
          <div class="luyAVmesDCAfHRLyxEx3" role="button">
            <label>
              <span></span>
              <p>Typographic</p>
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckOutCardModalFilters;
