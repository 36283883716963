import React, { useEffect, useState } from "react";
import "./orderDetails.css";
import ChangeDeliveryDate from "./ChangeDeliveryDate/ChangeDeliveryDate";
import ChangeRecipient from "./ChangeRecipient/ChangeRecipient";
import YourCardMessage from "./YourCardMessage/YourCardMessage";
import CancelYourOrder from "./CancelYourOrder/CancelYourOrder";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { getSingleOrderById, shipping_charges } from "../../../api/api";
import ChangeDateForCartItem from "../../ChangeDateForCartItem/ChangeDateForCartItem";
import { useSelector } from "react-redux";
import CircleLoader from "../../Loader/CircleLoader";

const imageUrl = process.env.REACT_APP_IMAGE_URL;

const OrderDetails = () => {
  const allProducts = useSelector((state) => state?.products?.products);
  const basketState = useSelector((state) => state?.basket?.basket);
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState("");
  const [productId, setProductId] = useState(null);

  const [orderDetails, setOrderDetails] = useState(null);

  const [isChangeDelivery, setIsChangeDelivery] = useState(false);

  const [cartItemProduct, setCartItemProduct] = useState(null);
  const [cartItemProductVariation, setCartItemProductVariation] =
    useState(null);

  const [deliveryCharge, setDeliveryCharge] = useState(null);

  useEffect(() => {
    loadOrderDetails();
  }, [productId]);

  const loadOrderDetails = async () => {
    try {
      const response = await getSingleOrderById(productId);
      setOrderDetails(response?.data?.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    isLogin();
    loadDeliveryCharge();

    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    const path = url.pathname;
    const pathParts = path.split("/");
    const lastPart = pathParts[pathParts.length - 1];
    setProductId(lastPart);
  }, []);

  const loadDeliveryCharge = async () => {
    try {
      const response = await shipping_charges();
      setDeliveryCharge(response?.data?.data?.shipping_charges);
    } catch (error) {
      console.log("error", error);
    }
  };

  const closeModal = () => {
    setOpenModal("");
  };

  const isLogin = () => {
    const token = Cookies.get("token");
    if (!token) {
      navigate("/login");
    }
  };

  const formatDate = (d) => {
    const date = d instanceof Date ? d : new Date(d);
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    const year = date.getFullYear();
    const month = months[date.getMonth()];
    const dayOfMonth = date.getDate();
    const dayOfWeek = days[date.getDay()];

    const suffix =
      dayOfMonth === 1 || dayOfMonth === 21 || dayOfMonth === 31
        ? "st"
        : dayOfMonth === 2 || dayOfMonth === 22
        ? "nd"
        : dayOfMonth === 3 || dayOfMonth === 23
        ? "rd"
        : "th";

    return `${dayOfWeek} ${dayOfMonth}${suffix} ${month} ${year}`;
  };

  const handleDeliveryDateChangeClick = () => {
    setIsChangeDelivery(true);
    setOpenModal("Change Delivery Date");

    if (allProducts) {
      const cartItemProduct = allProducts.filter((prod) => {
        return (
          prod.id ===
          orderDetails?.cart_detail[0]?.product_variation?.product_detail?.id
        );
      });
      setCartItemProduct(cartItemProduct[0]);
    }
  };

  const sendDeliveryDateToParent = (data) => {
    const updatedOrderDetails = { ...orderDetails };
    if (updatedOrderDetails.cart_detail && updatedOrderDetails.cart_detail[0]) {
      updatedOrderDetails.cart_detail[0].delivery_date = data;
    }
    setOrderDetails(updatedOrderDetails);
  };

  const sendMessageToParent = (message) => {
    let orderDetail = { ...orderDetails };
    orderDetail.card_message = message;
    setOrderDetails(orderDetail);
  };

  const cancelOrderSuccess = () => {
    setOrderDetails({
      ...orderDetails,
      delivery_status: "cancelled",
    });
    // orderDetails.delivery_status
  };
  return (
    <>
      {/* <ChangeDeliveryDate
        openModal={openModal}
        closeModal={closeModal}
        isChangeDelivery={isChangeDelivery}
      /> */}
      {/* {cartItemProduct && cartItemProductVariation && (
        <ChangeDateForCartItem
          isChangeDelivery={isChangeDelivery}
          setIsChangeDelivery={setIsChangeDelivery}
          cartItemProduct={cartItemProduct}
          cartItemProductVariation={cartItemProductVariation}
        />
      )} */}
      <ChangeRecipient
        productId={productId}
        orderDetails={orderDetails}
        openModal={openModal}
        closeModal={closeModal}
      />
      {orderDetails && (
        <YourCardMessage
          orderMessageDetails={orderDetails?.card_message}
          orderId={orderDetails?.id}
          openModal={openModal}
          closeModal={closeModal}
          sendMessageToParent={sendMessageToParent}
        />
      )}
      {openModal === "Cancel Order" && (
        <CancelYourOrder
          productId={productId}
          openModal={openModal}
          closeModal={closeModal}
          cancelOrderSuccess={cancelOrderSuccess}
        />
      )}
      {cartItemProduct && (
        <ChangeDateForCartItem
          isChangeDelivery={isChangeDelivery}
          setIsChangeDelivery={setIsChangeDelivery}
          cartItemProduct={cartItemProduct}
          orderId={orderDetails?.id}
          sendDeliveryDateToParent={sendDeliveryDateToParent}
        />
      )}
      {orderDetails !== null ? (
        <main id="main-container" class="container top-margin">
          <div class="nav-blackout"></div>
          <div class="row">
            <div class="col-12">
              <ul class="breadcrumb">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/account">Your Account</Link>
                </li>
                <li>
                  <Link to="/account/order-history">Order History</Link>
                </li>
                <li>Order Details</li>
              </ul>
            </div>
          </div>
          <div class="_3LpYaOIfjieYwbco8s_">
            <div class="OltPgYCmlYCMv4b1Sm3U">
              <h1 class="QzJRhxyOEurUX1pBKLqz">Order Details</h1>
              <p>
                <strong>Order number:</strong> {orderDetails?.order_num}
              </p>
              {orderDetails.delivery_status === "cancelled" ? (
                <div class="cancelled badge badge-danger mx-1 mt-2 mb-4">
                  Cancelled
                </div>
              ) : orderDetails.delivery_status === "delivered" ? (
                <div
                  style={{ background: "green" }}
                  class="cancelled badge mx-1 mt-2 mb-4"
                >
                  Delivered
                </div>
              ) : (
                <div class="D5YYXT3oLM8xMkgQKuN1 CzCY_0Yh2CemLc5Oe_5A">
                  If you want to make any changes to your order, please contact
                  us.
                  {/* <span>
                    <button
                      onClick={handleDeliveryDateChangeClick}
                      type="button"
                      class="e7QvJMPhPw6Pbciz9UHc YLxdcrrIEpFGSqqBDTXs"
                    >
                      Change Delivery Date
                    </button>
                  </span>
                  <span>
                    <button
                      onClick={() => setOpenModal("Change Recipient")}
                      type="button"
                      class="e7QvJMPhPw6Pbciz9UHc YLxdcrrIEpFGSqqBDTXs"
                    >
                      Change Recipient
                    </button>
                  </span>
                  <span>
                    <button
                      onClick={() => setOpenModal("Change Message")}
                      type="button"
                      class="e7QvJMPhPw6Pbciz9UHc YLxdcrrIEpFGSqqBDTXs"
                    >
                      Change Message
                    </button>
                  </span>
                  <button
                    onClick={() => setOpenModal("Cancel Order")}
                    type="button"
                    class="IeCWgXQuTJ30m6JZ5suT"
                  >
                    Cancel Order
                  </button> */}
                </div>
              )}
            </div>
            <div class="e3hmssPfNwwVhUi5NO0e">
              <div class="sDdk4oezt7sVomqHXh1a">
                <div class="fRUoQupckgDoODJYB25s">
                  <img
                    src={
                      imageUrl +
                      orderDetails?.cart_detail[0]?.product_variation
                        ?.product_gallery[0]?.gallery_image
                    }
                    alt="Spray Cascade"
                  />
                </div>
                <p class="xO3gL7da3KtpYv2RsKyp">
                  <strong>Delivery Date:</strong>{" "}
                  {formatDate(orderDetails?.cart_detail[0]?.delivery_date)}
                  &nbsp;
                  {/* <span class="gfbDrlr_krgmkFLMx9_g">
                  (Royal Mail Tracked 24)
                </span> */}
                </p>
                <ul class="LC1tC2T7q5o5SWSpFR0C">
                  <li class="j42wJ0dNRJwgEP6Hi38Q">
                    <p>
                      {
                        orderDetails?.cart_detail[0]?.product_variation
                          ?.product_detail?.product_name
                      }
                    </p>
                    <p>
                      £
                      {(orderDetails?.cart_detail[0]?.item_sale_price
                        ? orderDetails?.cart_detail[0]?.item_sale_price
                        : orderDetails?.cart_detail[0]?.item_price
                      ).toFixed()}
                    </p>
                  </li>
                  <li class="j42wJ0dNRJwgEP6Hi38Q">
                    <p>Gift Card</p>
                    <p>FREE</p>
                  </li>
                  {/* <li class="j42wJ0dNRJwgEP6Hi38Q">
                  <p>Delivery</p>
                  <p>£3.49</p>
                </li> */}
                  <li class="j42wJ0dNRJwgEP6Hi38Q">
                    <p class="font-italic">Discount</p>
                    <p class="font-italic">£{orderDetails?.coupon_discount}</p>
                  </li>
                  <li class="j42wJ0dNRJwgEP6Hi38Q">
                    <p class="font-italic">Delivery Charge</p>
                    {deliveryCharge && (
                      <p class="font-italic">£{deliveryCharge}</p>
                    )}
                  </li>
                  <li class="j42wJ0dNRJwgEP6Hi38Q">
                    <p class="bold">Total</p>
                    {/* item_sale_price */}
                    {deliveryCharge &&
                      (orderDetails?.cart_detail[0]?.item_sale_price !==
                      null ? (
                        <p class="bold">
                          £
                          {(
                            orderDetails?.cart_detail[0]?.item_sale_price -
                            Number(orderDetails?.coupon_discount) +
                            deliveryCharge
                          ).toFixed(2)}
                        </p>
                      ) : (
                        <p class="bold">
                          £
                          {(
                            orderDetails?.cart_detail[0]?.item_price -
                            Number(orderDetails?.coupon_discount) +
                            deliveryCharge
                          ).toFixed(2)}
                        </p>
                      ))}
                  </li>
                </ul>
              </div>
              <div class="sDdk4oezt7sVomqHXh1a">
                <p class="xO3gL7da3KtpYv2RsKyp">
                  <strong>Name:</strong> {orderDetails?.recipient_name}
                </p>
                <strong class="xO3gL7da3KtpYv2RsKyp">Address:</strong>
                <ul class="ypVqO4EH3a5IUtcfBcpv">
                  <li>{orderDetails?.address_line_1}</li>
                  <li>{orderDetails?.address_line_2}</li>
                  <li>{orderDetails?.city}</li>
                  <li>{orderDetails?.country}</li>
                  <li>{orderDetails?.postal_code}</li>
                </ul>
                <strong class="xO3gL7da3KtpYv2RsKyp">Message:</strong>
                <div class="personalised-container">
                  <div class="card-message-inside">
                    <div class="inside-right standard">
                      <div class="message">
                        <span
                          style={{
                            color: "#000000",
                            fontSize: "1.2em",
                            fontFamily: "inherit",
                            textAlign: "center",
                            justifyContent: "center",
                          }}
                        >
                          {orderDetails?.card_message}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="czhXuGjaWm3UPGyi70WG">
              <Link
                role="button"
                class="e7QvJMPhPw6Pbciz9UHc YLxdcrrIEpFGSqqBDTXs"
                to="/account/order-history"
                onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
              >
                Back to Orders
              </Link>
            </div>
            {orderDetails.delivery_status === "cancelled" && (
              <div
                class="PpJPYnJ0SxVNDy6CcMkU KmZaPtQ1hSpVpkonQFhV Dw2n7nukGgIVfFQaploC"
                style={{ background: "#ffd3d3" }}
                role="alert"
              >
                Your order has been cancelled
              </div>
            )}
            {orderDetails.delivery_status === "delivered" && (
              <div
                class="PpJPYnJ0SxVNDy6CcMkU KmZaPtQ1hSpVpkonQFhV Dw2n7nukGgIVfFQaploC"
                role="alert"
              >
                Your order has been delivered
              </div>
            )}
          </div>
        </main>
      ) : (
        <CircleLoader />
      )}
    </>
  );
};

export default OrderDetails;
