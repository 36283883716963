import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  getProductsByCategory,
  getProductsById,
  shipping_charges,
} from "../../../api/api";
import ChooseDelivery from "./ChooseDelivery.js/ChooseDelivery";
import Product from "../Category/CategoryFlowers/Product";
import CircleLoader from "../../Loader/CircleLoader";

const imageUrl = process.env.REACT_APP_IMAGE_URL;

const ProductView = () => {
  const { id } = useParams();
  const [image, setImage] = useState(null);
  const [backgroundPosition, setBackgroundPosition] =
    useState("50.9815% 50.2795%");
  const [isBouquetContents, setIsBouquetContents] = useState(false);
  const [product, setProduct] = useState(null);
  const [productVariation, setProductVariation] = useState(null);

  const [enableDateSelectionOption, setEnableDateSelectionOption] =
    useState(false);

  const [productsByCategory, setProductsByCategory] = useState(null);

  const [deliveryCharge, setDeliveryCharge] = useState("");

  const [products, setProducts] = useState(null);
  useEffect(() => {
    loadData();
  }, []);
  const loadData = async () => {
    try {
      const response = await getProductsByCategory(2);
      if (response?.data?.data) {
        const allProducts = response.data.data;
        const shuffledProducts = shuffleArray(allProducts);
        const randomProducts = shuffledProducts.slice(0, 5);
        setProducts(randomProducts);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  useEffect(() => {
    if (!id) return;
    loadProduct();
    loadDeliveryCharge();
  }, [id]);

  const loadDeliveryCharge = async () => {
    try {
      const response = await shipping_charges();
      setDeliveryCharge(response?.data?.data?.shipping_charges);
    } catch (error) {
      console.log("error", error);
    }
  };

  const loadProduct = async () => {
    try {
      const response = await getProductsById(id);
      setProduct(response?.data?.data);
      setProductVariation(response?.data?.data?.product_variation[0]);
      setImage(response?.data?.data?.product_variation[0]?.product_gallery[0]);

      try {
        const response2 = await getProductsByCategory(
          response?.data?.data?.category_id
        );
        setProductsByCategory(response2?.data?.data);
      } catch (error) {
        console.log("error", error);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleVaritionCliCk = (variationId) => {
    setEnableDateSelectionOption(false);
    if (!product) return;
    const seletedVariation = product?.product_variation.filter((variation) => {
      return variation.id === variationId;
    });
    setProductVariation(seletedVariation[0]);
  };

  const handleImageChange = (image) => {
    setImage(image);
  };

  const handleMouseMove = (event) => {
    const { clientX, clientY, target } = event;
    const { offsetWidth, offsetHeight } = target;
    const scaleFactor = 4.5;
    const minXPercent = -0;
    const minYPercent = -0;
    const maxXPercent = 20 * scaleFactor;
    const maxYPercent = 20 * scaleFactor;
    let xPercent = ((clientX - offsetWidth) / offsetWidth) * 100 * scaleFactor;
    let yPercent =
      ((clientY - offsetHeight) / offsetHeight) * 100 * scaleFactor;
    xPercent = Math.min(Math.max(xPercent, minXPercent), maxXPercent);
    yPercent = Math.min(Math.max(yPercent, minYPercent), maxYPercent);
    setBackgroundPosition(`${xPercent}% ${yPercent}%`);
  };

  const handleMouseLeave = () => {};

  const handleContinueClick = () => {
    setEnableDateSelectionOption(true);
  };

  return (
    <main id="main-container" class="container top-margin">
      <div class="nav-blackout"></div>
      {productsByCategory && product && productVariation ? (
        <div class="product-page">
          <div class="row">
            <div class="col-12">
              <ul class="breadcrumb">
                <li>
                  <Link
                    onClick={() =>
                      window.scrollTo({ top: 0, behavior: "smooth" })
                    }
                    to="/"
                  >
                    Home
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() =>
                      window.scrollTo({ top: 0, behavior: "smooth" })
                    }
                    to="/category/flowers"
                  >
                    Flowers
                  </Link>
                </li>
                <li>{product?.product_name}</li>
              </ul>
            </div>
          </div>
          <div class="product-area row">
            <div class="mobile-price col-12">
              <h2 class="product-title">{product?.product_name}</h2>
              <div class="favourite-button-container">
                <p class="product-price">
                  £{productVariation?.variation_price}
                </p>
                <span class="favourite-button">
                  <span class="background">
                    <i
                      class="fa fa-heart-o heart"
                      role="button"
                      aria-label="Add to Favourites"
                    ></i>
                  </span>
                </span>
              </div>
            </div>
            <section class="col-lg-6">
              <div class="row images">
                <div class="main-image col-12 col-sm-10 col-md-10 col-lg-12">
                  <figure
                    class="zoom"
                    style={{
                      backgroundImage: `url(${
                        imageUrl + image?.gallery_image
                      })`,
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: backgroundPosition,
                    }}
                    onMouseMove={handleMouseMove}
                    onMouseLeave={handleMouseLeave}
                  >
                    <img
                      alt={product.product_name}
                      src={imageUrl + image?.gallery_image}
                    />
                  </figure>
                </div>
                <div class="additional-product-images col-12 col-sm-2 col-md-2 col-lg-12">
                  <ul class="clickable-product-images">
                    {productVariation?.product_gallery.length > 1 &&
                      productVariation?.product_gallery.map((image, i) => {
                        return (
                          <li key={i}>
                            <img
                              onClick={() => handleImageChange(image)}
                              alt={product?.product_name}
                              src={imageUrl + image?.gallery_image}
                            />
                          </li>
                        );
                      })}
                    {/* <li>
                    <img
                      alt={product?.product_name}
                      src="https://media.bunches.co.uk/products/fnsc-2.jpg"
                    />
                  </li>
                  <li>
                    <img
                      alt={product?.product_name}
                      src="https://media.bunches.co.uk/products/fnsc-3.jpg"
                    />
                  </li> */}
                  </ul>
                </div>
              </div>
            </section>
            <section class="col-sm-12 col-lg-6">
              <div class="product-content">
                <div class="desktop-price">
                  <h2 class="product-title"> {product?.product_name}</h2>
                  <div class="favourite-button-container">
                    {/* <p class="product-price">
                      £{productVariation?.variation_price}
                    </p> */}

                    {productVariation?.sale_price !== null ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <p
                          class="product-price colorGray"
                          style={{ marginRight: "10px" }}
                        >
                          £{productVariation?.variation_price}
                        </p>
                        <p class="product-price">
                          £{productVariation?.sale_price}
                        </p>
                      </div>
                    ) : (
                      <p class="product-price">
                        £{productVariation?.variation_price}
                      </p>
                    )}

                    {/* <span class="favourite-button">
                      <span class="background">
                        <i
                          class="fa fa-heart-o heart"
                          role="button"
                          aria-label="Add to Favourites"
                        ></i>
                      </span>
                    </span> */}
                  </div>
                </div>
                <div class="product-description clear">
                  {productVariation?.variation_description}
                  <br />
                  <br />
                  {/* Each Carnation stem has at least 4 blooms so 11 stems will give
                around 44 flowering heads! */}
                </div>
                <ul class="product-bullet-points">
                  {productVariation?.product_option.map((option, i) => {
                    if (option?.option_title !== null) {
                      return <li key={i}>{option?.option_title}</li>;
                    }
                  })}
                  {/* <li>Sent in bud for maximum vase life</li>
                <li>Vase not included</li> */}
                </ul>
              </div>
              <div class="clear"></div>
              <div
                class={`bouquet-contents ${isBouquetContents ? "active" : ""}`}
              >
                <div
                  onClick={() => setIsBouquetContents(!isBouquetContents)}
                  class="header"
                  role="button"
                >
                  <span>Contents</span>
                  <i class="fa fa-angle-down"></i>
                </div>
                <div class="content">
                  <div class="content-inside">
                    <ul>
                      {productVariation?.bouquet_content.map((option, i) => {
                        return (
                          <li key={i}>
                            {option?.content_title === null
                              ? "NA"
                              : option?.content_title}
                          </li>
                        );
                      })}

                      {/* <li>4 x Yellow Spray Carnations</li>
                    <li>3 x White Spray Carnations</li>
                    <li>1 x White Gypsophila</li>
                    <li>Total Stems - 12</li> */}
                    </ul>
                  </div>
                </div>
              </div>
              <div class="product-wrapper calendar">
                {/* <div class="posy-points">
                This product earns <span class="bold">102</span> Posy Points™ -
                Worth £1.02 off your next order
                <a
                  aria-label="Posy Points information"
                  href="/posy-points"
                  target="_blank"
                >
                  <i
                    class="fa fa-info-circle posy-points-info"
                    aria-hidden="true"
                  ></i>
                </a>
              </div> */}
                <div class="product-selection">
                  <div class="product-variants-wrapper">
                    <div class="step-header">Step 1 - Choose an option</div>
                    <div class="variants-parent">
                      {product?.product_variation.map((variation) => {
                        return (
                          <div
                            key={variation.id}
                            onClick={() => handleVaritionCliCk(variation.id)}
                            class={`variant product-checkbox ${
                              productVariation.id === variation.id
                                ? "selected"
                                : ""
                            }`}
                          >
                            <span
                              role="checkbox"
                              aria-checked={
                                productVariation.id === variation.id
                              }
                              class="tick-box"
                            >
                              {productVariation.id === variation.id && (
                                <span class="tick fa fa-check"></span>
                              )}
                            </span>
                            <span>{variation?.variation_label}</span> -
                            {/* <span>{variation?.sale_price}</span> */}
                            {variation?.sale_price !== null ? (
                              <span style={{}}>
                                <span
                                  class=" colorGray"
                                  style={{ marginRight: "10px" }}
                                >
                                  £{variation?.variation_price}
                                </span>
                                <span>£{variation?.sale_price}</span>
                              </span>
                            ) : (
                              <span>£{variation?.variation_price}</span>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <button
                    onClick={handleContinueClick}
                    class="continue bn-button--lg bn-button bn-button--primary"
                  >
                    Continue
                  </button>
                  <div class="step-header">Step 2 - Choose your delivery</div>
                  {enableDateSelectionOption && (
                    <ChooseDelivery
                      unAvailableDates={product?.unavailable_dates}
                      productVariation={productVariation}
                      product={product}
                      deliveryCharge={deliveryCharge}
                    />
                  )}
                </div>
              </div>
              <section class="payment-methods">
                <div class="methods-row">
                  <div class="method">
                    <div class="visa" role="img" aria-label="Visa"></div>
                  </div>
                  <div class="method">
                    <div
                      class="applePay"
                      role="img"
                      aria-label="Apple Pay"
                    ></div>
                  </div>
                  <div class="method">
                    <div
                      class="googlePay"
                      role="img"
                      aria-label="Google Pay"
                    ></div>
                  </div>
                  <div class="method">
                    <div
                      class="mastercard"
                      role="img"
                      aria-label="Mastercard"
                    ></div>
                  </div>
                  <div class="method">
                    <div class="payPal" role="img" aria-label="PayPal"></div>
                  </div>
                </div>
              </section>
            </section>
          </div>
          {/* <div class="lined-header clear">
          <h2>Why choose Bunches?</h2>
        </div>
        <section class="wqz3T6vQmJApvqxlATi4">
          <ul
            class="vfnmTCPdQDYSqF9q0gtp"
            role="tablist"
            style={{
              paddingLeft: "444px",
              paddingRight: "444px",
            }}
          >
            <li class="BVjjx3GcdULIv_UfElOG Dv10Avp3VljgNaBZTnpa" role="tab">
              Customer Reviews
            </li>
            <li class="BVjjx3GcdULIv_UfElOG" role="tab">
              Quality Guaranteed
            </li>
            <li class="BVjjx3GcdULIv_UfElOG" role="tab">
              Posy Points
            </li>
            <li class="BVjjx3GcdULIv_UfElOG" role="tab">
              Add a Little Extra
            </li>
            <li class="BVjjx3GcdULIv_UfElOG" role="tab">
              Making a Difference
            </li>
          </ul>
          <div class="cxl47oDXZZkWIdSaYDrG" data-testid="content-container">
            <div
              class="hTgPAqvW6McKVCKbpyJl"
              role="tabpanel"
              aria-hidden="false"
            >
              <div class="JOFKhJyqchToOUzMAxka">
                <div id="carousel-widget" class="jmV3f4_NUk8OfDyBILt2">
                  <iframe
                    src="https://widget.reviews.co.uk/carousel/widget?elementId=carousel-widget&amp;version=3b&amp;&amp;store=bunchescouk&amp;primaryClr=%23f3c5ad&amp;neutralClr=%23dee1e3&amp;reviewTextClr=%23363d40&amp;layout=fullWidth&amp;numReviews=8&amp;hideDates=true"
                    frameborder="0"
                    width="100%"
                    title="Reviews Carousel Widget"
                  ></iframe>
                </div>
                <div class="jcRdO1IBC1evulJW9e7w">
                  <h3 class="wBXtPnFw8qyA9h26P1Mf">What our customers think</h3>
                  <p>
                    We have one of the largest collections of independent
                    customer reviews in the industry. We believe that our
                    customers are the best judge of how well we are doing. We
                    are always listening to what our customers are saying and
                    learning how we can do better.
                  </p>
                </div>
              </div>
            </div>
            <div
              class="hTgPAqvW6McKVCKbpyJl"
              role="tabpanel"
              aria-hidden="true"
            >
              <div class="GPn62D1k9QBmQ6YK8U69">
                <img
                  src="https://media.bunches.co.uk/wcb/qualityguaranteed.jpg"
                  alt="We offer a no-quibble guarantee!"
                  class="zcbubnNB9owEUkPOvsU5"
                />
                <div class="jcRdO1IBC1evulJW9e7w">
                  <h3 class="wBXtPnFw8qyA9h26P1Mf">
                    We offer a no-quibble guarantee!
                  </h3>
                  <p>
                    You can order with confidence thanks to our quality
                    guarantees. We take great care and pride in packing all our
                    flower and plant gifts and provide a 7 day freshness
                    guarantee for your peace of mind. But we're human and
                    understand that things don’t always work out as expected -
                    our no quibble guarantee means that we will make things
                    right for you without any hassle.
                  </p>
                </div>
              </div>
            </div>
            <div
              class="hTgPAqvW6McKVCKbpyJl"
              role="tabpanel"
              aria-hidden="true"
            >
              <div class="GPn62D1k9QBmQ6YK8U69">
                <img
                  src="https://media.bunches.co.uk/wcb/posypoints.jpg"
                  alt="Posy Points - Our customer reward scheme"
                  class="zcbubnNB9owEUkPOvsU5"
                />
                <div class="jcRdO1IBC1evulJW9e7w">
                  <h3 class="wBXtPnFw8qyA9h26P1Mf">
                    Posy Points - Our customer reward scheme
                  </h3>
                  <p>
                    Collect and spend points with our unique Posy Points
                    customer reward programme. You can earn Posy Points on every
                    order you make and then use them to save money on any orders
                    in the future. For example, spend £20 and you can earn 200
                    points – that's £1 off your next order. It's quick and easy
                    to start earning points, just set up your online account and
                    start collecting straight away.
                  </p>
                </div>
              </div>
            </div>
            <div
              class="hTgPAqvW6McKVCKbpyJl"
              role="tabpanel"
              aria-hidden="true"
            >
              <div class="GPn62D1k9QBmQ6YK8U69">
                <img
                  src="https://media.bunches.co.uk/wcb/extras.jpg"
                  alt="Add a little extra"
                  class="zcbubnNB9owEUkPOvsU5"
                />
                <div class="jcRdO1IBC1evulJW9e7w">
                  <h3 class="wBXtPnFw8qyA9h26P1Mf">Add a little extra</h3>
                  <p>
                    Did you know, we have the largest range of add-on gifts in
                    the market? <br />
                    <br />
                    With everything from sweet treats, to scented candles,
                    prosecco and even gin - we have something for everyone here
                    at Bunches. Many of which are also made with planet-friendly
                    packaging - so you can shop with us knowing we are
                    environmentally conscious.
                    <br />
                    <br />
                    To read a little more about our vast range of add-on gifts,
                    <a
                      href="https://www.bunches.co.uk/journal/delivering-a-little-extra-happiness?utm_source=contenttab-aale"
                      target="_blank"
                    >
                      click here
                    </a>
                    .
                  </p>
                </div>
              </div>
            </div>
            <div
              class="hTgPAqvW6McKVCKbpyJl"
              role="tabpanel"
              aria-hidden="true"
            >
              <div class="GPn62D1k9QBmQ6YK8U69">
                <img
                  src="https://media.bunches.co.uk/wcb/makingadifference.jpg"
                  alt="Making a difference"
                  class="zcbubnNB9owEUkPOvsU5"
                />
                <div class="jcRdO1IBC1evulJW9e7w">
                  <h3 class="wBXtPnFw8qyA9h26P1Mf">Making a difference</h3>
                  <p>
                    We believe that Bunches is a blessing, not just a business.
                    For this reason, each year we donate a minimum of 10% of our
                    profits to charitable projects close to our hearts, both in
                    the UK and abroad. <br />
                    <br />
                    Throughout the years we have donated over £1 million to
                    these charitable projects, and helped to positively impact
                    the lives of thousands of individuals along the way.
                    <br />
                    <br />
                    To find out more,
                    <a
                      href="https://www.bunches.co.uk/about-us?utm_source=contenttab-mad#charity"
                      target="_blank"
                    >
                      click here
                    </a>
                    .
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section> */}
          <div class="lined-header clear">
            <h2>You might also be interested in…</h2>
          </div>
          <div class="related-products">
            {products &&
              products.map((product) => {
                return <Product product={product} />;
              })}
          </div>
          <div class="clear"></div>
        </div>
      ) : (
        <div>
          <CircleLoader />
        </div>
      )}
    </main>
  );
};

export default ProductView;
