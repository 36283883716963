import React, { useEffect, useState } from "react";
import "./LandingPage.css";
import KeepInTouchWithUs from "./KeepInTouchWithUs/KeepInTouchWithUs";
import EveryOrderMakesDifference from "./EveryOrderMakesDifference/EveryOrderMakesDifference";
import Product from "./Product/Product";
import WhyYoullLoveShoppingWithUs from "./WhyYoullLoveShoppingWithUs/WhyYoullLoveShoppingWithUs";
import FlowerDelevery from "./Product/FlowerDelevery";
import RatingReviewCard from "./Product/RatingReviewCard";
import LandingFirstProductComp from "./Product/LandingFirstProductComp";
import AllOverReview from "./Product/AllOverReview/AllOverReview";
import WhyYoullLoveShoppingWithUsTwo from "./WhyYoullLoveShoppingWithUs/WhyYoullLoveShoppingWithUsTwo";
import GreetingsCards from "./Product/GreetingsCards";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const LandingPage = () => {
  const CategoryState = useSelector((state) => state.category);
  const [categoryNameArray, setCategoryNameArray] = useState([]);
  const [categoryLength, setCategoryLength] = useState(0);

  useEffect(() => {
    const sortedCategoryNames = CategoryState?.categoryWithParentValue
      .map((cat) => {
        return {
          category_name: cat?.category_name,
          id: cat?.id,
        };
      })
      .sort((a, b) => a.category_name.localeCompare(b.category_name));
    setCategoryNameArray(sortedCategoryNames);
    setCategoryLength(CategoryState?.categoryWithParentValue?.length);  
  }, [CategoryState]);

  const [categoryIds, setCategoryIds] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);

  const productState = useSelector((state) => state.products);
  useEffect(() => {
    if (productState && categoryIds) {
      const filtered = productState?.products?.filter((product) => {
        return categoryIds.includes(product.category_id);
      });
      setFilteredProducts(filtered);
    }
  }, [productState, categoryIds]);

  // const getProduct = (categoryId) => {
  //   if (CategoryState.category && categoryId) {
  //     const filteredCategory = CategoryState?.category?.filter((cat) => {
  //       return (
  //         Number(cat?.parent_category?.id) === Number(categoryId) ||
  //         Number(cat?.id) === Number(categoryId)
  //       );
  //     });
  //     setCategoryIds(filteredCategory.map((cat) => cat.id));
  //   }
  // };

  return (
    <main id="main-container" class="container top-margin fluid-container">
      <div class="nav-blackout"></div>
      <div class="landing-page">
        <AllOverReview />

        <LandingFirstProductComp />

        {/* <RatingReviewCard /> */}

        {/* <section class="homepage-carousel" aria-label="Flower Delivery">
          <header class="lined-header" aria-label="header">
            <h2>
              <a href="/category/flowers">Flower Delivery</a>
              <a class="view-all-headerlink" href="/category/flowers">
                View all
              </a>
            </h2>
          </header>
          <FlowerDelevery />
        </section> */}
        <section class="homepage-carousel" aria-label="Carousel">
          {/* <Product /> */}
        </section>

        <WhyYoullLoveShoppingWithUsTwo />

        {categoryNameArray.map((category, i) => {
          return (
            <section
              key={i}
              class="homepage-carousel"
              aria-label="Hand Tied Bouquets"
            >
              <header class="lined-header" aria-label="header">
                <h2>
                  <Link
                    to={`/category/flowers/${category?.id}`}
                    onClick={() =>
                      window.scrollTo({ top: 0, behavior: "smooth" })
                    }
                  >
                    {category?.category_name}
                    {/* Hand Tied Bouquets */}
                  </Link>
                  <Link
                    class="view-all-headerlink"
                    to={`/category/flowers/${category?.id}`}
                    onClick={() =>
                      window.scrollTo({ top: 0, behavior: "smooth" })
                    }
                  >
                    View all
                  </Link>
                </h2>
              </header>
              <Product categoryId={category?.id} product={category?.id} />
            </section>
          );
        })}

        {/* <section class="homepage-carousel" aria-label="Letterbox Flowers">
          <header class="lined-header" aria-label="header">
            <h2>
              <a href="/category/letterbox-flowers">Letterbox Flowers</a>
              <a class="view-all-headerlink" href="/category/letterbox-flowers">
                View all
              </a>
            </h2>
          </header>
          <Product />
        </section> */}

        {/* <section
          class="homepage-carousel"
          aria-label="Birthday Flowers and Gifts"
        >
          <header class="lined-header" aria-label="header">
            <h2>
              <a href="/category/birthday-flowers-and-gifts">
                Birthday Flowers and Gifts
              </a>
              <a
                class="view-all-headerlink"
                href="/category/birthday-flowers-and-gifts"
              >
                View all
              </a>
            </h2>
          </header>
          <Product />
        </section> */}

        {/* <GreetingsCards /> */}

        {/* <section class="homepage-carousel" aria-label="House Plants">
          <header class="lined-header" aria-label="header">
            <h2>
              <a href="/category/house-plants">House Plants</a>
              <a class="view-all-headerlink" href="/category/house-plants">
                View all
              </a>
            </h2>
          </header>
          <Product />
        </section> */}

        {/* <section class="homepage-carousel" aria-label="Hampers">
          <header class="lined-header" aria-label="header">
            <h2>
              <a href="/hamper-delivery">Hampers</a>
              <a class="view-all-headerlink" href="/hamper-delivery">
                View all
              </a>
            </h2>
          </header>
          <Product />
        </section> */}

        {/* <EveryOrderMakesDifference /> */}
      </div>
      <KeepInTouchWithUs />
    </main>
  );
};

export default LandingPage;
