import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  category: null,
  categoryWithParentValue: null,
  clickedCategory: "",
};

export const CategorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {
    addCategory: (state, action) => {
      state.category = action.payload;
    },
    addCategoryWithParentValueNull: (state, action) => {
      state.categoryWithParentValue = action.payload;
    },
    addCategoryName: (state, action) => {
      state.clickedCategory = action.payload;
    },
  },
});

export const { addCategory, addCategoryWithParentValueNull, addCategoryName } =
  CategorySlice.actions;

export default CategorySlice.reducer;
