import React from "react";

const CheckOutCardModelCard = () => {
  return (
    <div class="cLeHuTBWGsxj_UG4vN5w">
      <div class="mGQXLDnKYRzfedSy_V1K">
        <img
          src="https://media.bunches.co.uk/products/586x586/k0757.jpg"
          width="285"
          height="285"
          alt="Thinking Of You Bee "
        />
      </div>
    </div>
  );
};

export default CheckOutCardModelCard;
