import React from "react";
import "./Loader.css";

const Loader = () => {
  return (
    <div class="loading-dots">
      <div class="loading-dots--dot"></div>
      <div class="loading-dots--dot"></div>
      <div class="loading-dots--dot"></div>
    </div>
  );
};

export default Loader;
