import React from "react";

const PosyPoints = () => {
  return (
    <main id="main-container" class="container top-margin">
      <div class="nav-blackout"></div>
      <main class="row static-page" id="main-content">
        <section class="col-md-12">
          <article class="card">
            <header class="card-header">
              <h2 class="card-title">Posy Points™</h2>
            </header>
            <div class="card-block">
              <p>
                Posy Points are our way of saying "thank you" to customers.
                Every time you order with us, you'll earn points that can save
                you money on your next order with us. So, they're just our way
                of giving a little something back to you!
              </p>
              <p>
                You earn 5 Posy Points for every £1 you spend. Every Posy Point
                is worth 1p. For example, if you spend £40 on a gift, you will
                earn 200 Posy Points which will give you a £2 saving on your
                next order.
              </p>
              <p>
                It's really easy to start earning Posy Points -
                <a href="/login"> simply log in to your account</a> here on our
                website (or <a href="/register">create one</a> whilst placing an
                order if you haven't got one already). Then place your order and
                your Posy Points will automatically be added to your account.
              </p>
              <p>
                You can redeem your Posy Points against any product we offer.
                Simply log in to your account, place your order and at the
                checkout you'll be able to use all or some of your Posy Points
                as you choose to save money on your order. Alternatively, you
                can call our <a href="/contact-us">Customer Support Team</a> on{" "}
                <a rel="nofollow" href="tel:+441623750343">
                  +44 20 8123 7938
                </a>{" "}
                to place your order.
              </p>
              <p>
                You can check your Posy Points balance at any time by{" "}
                <a href="/login">logging in to your account</a>. It's up to you
                when you redeem your Posy Points, you have up to two years to
                use your points from the date you earn them.
              </p>
              <p>
                The table below gives a couple of examples of the number of
                points you can collect and how much they are worth.
              </p>
              <table class="table">
                <thead>
                  <tr>
                    <th>Product Ordered</th>
                    <th>Price</th>
                    <th>Points Earned</th>
                    <th>Discount on Your Next Order</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Product One</td>
                    <td>£29.00</td>
                    <td>145 Posy Points</td>
                    <td>£1.45 off your next order!</td>
                  </tr>
                  <tr>
                    <td>Product Two</td>
                    <td>£32.00</td>
                    <td>160 Posy Points</td>
                    <td>£1.60 off your next order!</td>
                  </tr>
                </tbody>
              </table>
              <h3 class="h3">Posy Points FAQ</h3>
              <h4 class="h4">What are Posy Points?</h4>
              <p>
                Posy Points is a reward scheme for Integrity Flowers customers.
                Every time you order with us, you'll earn points that you can
                put towards your next order with us.
              </p>
              <h4 class="h4">How do I collect Posy Points?</h4>
              <p>
                You collect Posy Points on every order you place with us on our
                website or over the phone. The points you earn will be
                automatically credited to your account. If you cancel or return
                an order to us, we reserve the right to cancel the Posy Points
                awarded for that order.
              </p>
              <h4 class="h4">How much are my Posy Points worth?</h4>
              <p>
                You will earn 5 Posy Points for every £1 spent. For example, if
                you buy a bouquet for £20.00, you will earn you 100 Posy Points.
                Each point is worth £0.01 so earning 100 Posy Points will give
                you a £1.00 saving off your next order.
              </p>
              <h4 class="h4">How do I redeem my Posy Points?</h4>
              <p>
                To redeem your Posy Points, just log in to your account, place
                your order and at the checkout you'll be able to use all or some
                of your Posy Points to reduce the cost of your order.
                Alternatively, you can call our Customer Support Team on +44 20
                8123 7938 to place your order.
              </p>
              <p>
                If you have collected enough points, you can pay for an order in
                full using Posy Points. You can only redeem Posy Points with us
                and they cannot be exchanged for cash or other items. Posy
                Points are also not transferable between accounts.
              </p>
              <h4 class="h4">How can I check my Posy Points balance?</h4>
              <p>
                You can check your Posy Points balance at any time by{" "}
                <a href="/login">
                  logging into your customer account on this website
                </a>
                . The total number of points and their value will be displayed
                at the top of the page. Your points balance will also be
                displayed at the checkout when you place an order.
                Alternatively, you can call our Customer Support Team on +44 20
                8123 7938.
              </p>
              <h4 class="h4">Do I have to register to claim Posy Points?</h4>
              <p>
                You will need to be a registered customer to collect and store
                Posy Points. The points that you accumulate will be stored in
                your account for you to save money on your future orders. We do
                offer Guest Checkout for customers who wish to place an order
                without registering for an account but you will not receive any
                Posy Points for orders placed this way.
              </p>
              <h4 class="h4">Do Posy Points have an expiry date?</h4>
              <p>
                You have two years to use Posy Points from the date you earn
                them. At the end of this two year period, any unused Posy Points
                will expire and be removed from your customer account.
              </p>
            </div>
          </article>
        </section>
      </main>
    </main>
  );
};

export default PosyPoints;
