import React, { useEffect, useState } from "react";
import { signUp } from "../../../api/api";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import { Link, useNavigate } from "react-router-dom";
import { addUser } from "../../../features/User/userSlice";

const Register = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");

  const [togglePassword, setTogglePassword] = useState("password");

  const [signUpError, setSignUpError] = useState("");

  useEffect(() => {
    const token = Cookies.get("token");
    if (token) {
      navigate("/");
    }
  }, []);

  function isValidEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  const handleRegisterClick = async () => {
    const isValidPhoneNumber = (phone) => {
      const phoneRegex = /^\d{10,15}$/;
      return phoneRegex.test(phone);
    };

    if (!email || !password || !name || !phone) {
      setSignUpError("Please enter your name, email, phone and password ");
      return;
    } else if (password.length < 8) {
      setSignUpError("Your password needs to be a minimum of 8 characters ");
      return;
    } else if (!isValidEmail(email)) {
      setSignUpError("Please enter a valid email address");
      return;
    } else if (!isValidPhoneNumber(phone)) {
      setSignUpError("Please enter a valid phone number");
      return;
    }
    if (signUpError === "") {
      try {
        const response = await signUp({
          email,
          password,
          name,
          tel_num: phone,
        });
        if (response?.data?.message === "The email has already been taken.") {
          setSignUpError(
            "An account with this email address has already been registered. Please log in instead."
          );
        } else {
          dispatch(addUser(response?.data?.user));
          Cookies.set("token", response?.data?.access_token);
          localStorage.setItem("user", JSON.stringify(response?.data?.user));
          navigate(-1);
        }
      } catch (error) {
        console.log("error", error);
      }
    }
  };

  return (
    <main id="main-container" class="container top-margin">
      <div class="nav-blackout"></div>
      <div class="register">
        <div class="row">
          <div class="col-12">
            <ul class="breadcrumb">
              <li>
                <Link href="/">Home</Link>
              </li>
              <li>Register</li>
            </ul>
          </div>
        </div>
        <div class="heading">Register</div>
        <div class="content">
          <div class="form">
            <div class="form-group">
              <label class="bn-label required" for="name">
                Full Name
              </label>
              <input
                id="name"
                type="text"
                autocomplete="name"
                class="form-control"
                required=""
                minlength="1"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                  setSignUpError("");
                }}
              />
            </div>
            <div class="form-group">
              <label class="bn-label required" for="email">
                Email Address
              </label>
              <input
                id="email"
                type="email"
                autocomplete="email"
                class="form-control"
                required=""
                minlength="1"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setSignUpError("");
                }}
              />
            </div>
            <div class="form-group">
              <label class="bn-label required" for="email">
                Phone
              </label>
              <input
                id="phone"
                type="phone"
                autocomplete="phone"
                class="form-control"
                minlength="1"
                value={phone}
                onChange={(e) => {
                  setPhone(e.target.value);
                  setSignUpError("");
                }}
              />
            </div>
            <div class="form-group mb-2">
              <label class="bn-label required" for="password">
                Password
              </label>
              <div class="form-group">
                <input
                  id="password"
                  name="password"
                  type={togglePassword}
                  class="form-control"
                  placeholder=""
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    setSignUpError("");
                  }}
                />
                {togglePassword === "password" && (
                  <span
                    onClick={() => setTogglePassword("text")}
                    role="button"
                    aria-label="Show Password"
                    class="fa password-view-icon fa-eye"
                  ></span>
                )}
                {togglePassword === "text" && (
                  <span
                    onClick={() => setTogglePassword("password")}
                    role="button"
                    aria-label="Hide Password"
                    class="fa password-view-icon fa-eye-slash"
                  ></span>
                )}
                <div style={{ color: "red", fontSize: "15px" }}>
                  {signUpError}
                </div>
              </div>
              <div class="mt-2">
                Your password needs to be a minimum of 10 characters
              </div>
            </div>
            {/* <div class="text-center mb-1">
                <div class="captcha-container">
                  <div id="captcha" class="mtcaptcha" mtcapwid="captcha-2">
                    <iframe
                      id="captcha-iframe-2"
                      style={{
                        display: "inline-block",
                        border: "0px",
                        padding: "0px",
                        margin: "0px",
                        background: "transparent",
                        height: "178px",
                      }}
                      src="https://service.mtcaptcha.com/mtcv1/client/iframe.html?v=2024-03-24.15.25.36&amp;sitekey=MTPublic-ErfGNFQXC&amp;iframeId=captcha-iframe-2&amp;widgetSize=standard&amp;custom=false&amp;widgetInstance=captcha&amp;challengeType=standard&amp;theme=neowhite&amp;lang=en-GB&amp;action=&amp;autoFadeOuterText=false&amp;host=https%3A%2F%2Fwww.bunches.co.uk&amp;hostname=www.bunches.co.uk&amp;serviceDomain=service.mtcaptcha.com&amp;textLength=0&amp;lowFrictionInvisible=&amp;enableMouseFlow=false"
                      title="mtcaptcha"
                      sandbox=" allow-forms allow-popups allow-same-origin allow-scripts allow-modals allow-popups-to-escape-sandbox"
                      width="100%"
                      height="178px"
                    ></iframe>
                    <input
                      type="hidden"
                      class="mtcaptcha-verifiedtoken"
                      name="mtcaptcha-verifiedtoken"
                      id="captcha-verifiedtoken-2"
                      readonly="readonly"
                      value=""
                    />
                  </div>
                </div>
              </div> */}
            {/* <label class="email-label mb-4">
                <input
                  id="marketing"
                  type="checkbox"
                  class="email-input mr-2"
                  checked=""
                />
                Yes! Please send me discount codes, offers and details of new
                products by email. I can unsubscribe at any time.
              </label> */}
            <div class="text-center mb-3">
              <button
                class="bn-button bn-button--primary"
                type="submit"
                disabled=""
                onClick={handleRegisterClick}
              >
                Register
              </button>
            </div>
            {/* <div class="hr-with-text">
              <div>Or</div>
            </div>
            <div class="text-center">
              <p>
                You can use your Facebook account details to use our site using
                the button below:
              </p>
              <br />
              <div class="n0ywmAu4ItA_sj7LzbYw">
                <button
                  class="e7QvJMPhPw6Pbciz9UHc YLxdcrrIEpFGSqqBDTXs"
                  type="button"
                >
                  <i class="fa fa-facebook-square"></i>
                  <span>Sign in with Facebook</span>
                </button>
              </div>
            </div> */}
          </div>
          <div class="benefits">
            <h1>Benefits of registering</h1>
            <h2>Here are the benefits you get when you register:</h2>
            <div>
              {/* <div class="reason">
                <img
                  src="https://media.bunches.co.uk/content_pages/posy-points-icon.png"
                  alt="Posy Points"
                />
                <span>
                  <h3>Posy Points™</h3>
                  Earn points on every order which you can claim for a discount
                  on future orders.
                </span>
              </div> */}
              <div class="reason">
                <img
                  src="https://media.bunches.co.uk/content_pages/address-icon.png"
                  alt="Address Book"
                />
                <span>
                  <h3>Address book</h3>
                  All of your addresses safely stored in one place for a faster
                  checkout experience.
                </span>
              </div>
              <div class="reason">
                <img
                  src="https://media.bunches.co.uk/content_pages/reminders-icon.png"
                  alt="Reminder Service"
                />
                <span>
                  <h3>Reminder Service</h3>
                  Let us know when your special occasions are and we will remind
                  you by email.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Register;
