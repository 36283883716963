import React, { useEffect, useState } from "react";
import integrityFlowerLogo from "../../../../assets/logos/Integrityflowers Transparent cropped .png";
import { useDispatch, useSelector } from "react-redux";
import { toggleBasket } from "../../../../features/menuBar/menubarSlice";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { next_delivery_date, searchProducts } from "../../../../api/api";
import { addSearch } from "../../../../features/search/searchSlice";

const DescNav = () => {
  const navigate = useNavigate();
  const basketCount = useSelector((state) => state?.basket?.basketCount);
  const basketItems = useSelector((state) => state?.basket?.basket);
  // const user = useSelector((state) => state?.user);

  const [search, setSearch] = useState("");
  const [nextAvailableDate, setNextAvailableDate] = useState(null);

  const user = localStorage.getItem("user");
  const token = Cookies.get("token");

  const dispatch = useDispatch();
  const handleBasketClick = () => {
    dispatch(toggleBasket());
  };

  useEffect(() => {
    const loadNextDate = async () => {
      try {
        const response = await next_delivery_date();
        setNextAvailableDate(response?.data);
      } catch (error) {
        console.log(error);
      }
    };
    loadNextDate();
  }, []);

  const handleSignOutClick = () => {
    localStorage.setItem("user-cart", JSON.stringify(basketItems));

    Cookies.remove("token");
    document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

    localStorage.removeItem("user");
    window.location.href = "/";
  };

  const handleSearchInputChange = (e) => {
    setSearch(e.target.value);
  };

  const handleSearchEnterClick = async (e) => {
    e.preventDefault();
    if (search !== "") {
      navigate(`/search/${search}`);
    }
  };

  const formatDate = (d) => {
    const date = d instanceof Date ? d : new Date(d);
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    const month = months[date.getMonth()];
    const dayOfMonth = date.getDate();
    const dayOfWeek = days[date.getDay()];

    const suffix =
      dayOfMonth === 1 || dayOfMonth === 21 || dayOfMonth === 31
        ? "st"
        : dayOfMonth === 2 || dayOfMonth === 22
        ? "nd"
        : dayOfMonth === 3 || dayOfMonth === 23
        ? "rd"
        : "th";

    return `${dayOfWeek} ${dayOfMonth}${suffix} ${month}`;
  };

  return (
    <div className="bn-desktop-header">
      <ul class="bn-header__options">
        {token === null || token === undefined || token === "" ? (
          <li>
            <Link id="headerSignIn" to="/login">
              Sign In / Register
            </Link>
          </li>
        ) : (
          <>
            <li>
              <Link to="/account">My Account</Link>
            </li>
            <li>
              <button onClick={handleSignOutClick} class="bn-button">
                Sign Out
              </button>
            </li>
          </>
        )}

        <li>
          <Link to="/contact-us">
            <svg
              style={{ marginRight: "2px", position: "relative", top: "2px" }}
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="15"
              fill="currentColor"
              class="bi bi-telephone-fill"
              viewBox="0 0 16 16"
            >
              <path d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z" />
            </svg>
            <span>+44 20 8123 7938</span>
          </Link>
        </li>
        <li onClick={handleBasketClick} class="checkout-icon">
          <svg
            style={{ marginRight: "2px", position: "relative", top: "2px" }}
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-basket-fill"
            viewBox="0 0 16 16"
          >
            <path d="M5.071 1.243a.5.5 0 0 1 .858.514L3.383 6h9.234L10.07 1.757a.5.5 0 1 1 .858-.514L13.783 6H15.5a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5H15v5a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V9H.5a.5.5 0 0 1-.5-.5v-2A.5.5 0 0 1 .5 6h1.717zM3.5 10.5a.5.5 0 1 0-1 0v3a.5.5 0 0 0 1 0zm2.5 0a.5.5 0 1 0-1 0v3a.5.5 0 0 0 1 0zm2.5 0a.5.5 0 1 0-1 0v3a.5.5 0 0 0 1 0zm2.5 0a.5.5 0 1 0-1 0v3a.5.5 0 0 0 1 0zm2.5 0a.5.5 0 1 0-1 0v3a.5.5 0 0 0 1 0z" />
          </svg>
          <span>Basket ({basketCount})</span>
        </li>
      </ul>

      <div class="bn-desktop-header__wrapper">
        <div class="left-container">
          <form class="bn-header__search">
            <input
              type="search"
              aria-label="Search products"
              placeholder="Search"
              required=""
              value={search}
              onChange={handleSearchInputChange}
            />
            <button
              onClick={handleSearchEnterClick}
              type="submit"
              aria-label="Submit search query"
            >
              <i class="fa fa-search" aria-hidden="true"></i>
            </button>
          </form>
          {/* <span>
            <div class="overlay-button-container">
              <span class="overlay-button">
                <i
                  class="overlay-heart fa fa-heart heart"
                  role="button"
                  aria-label="View Favourites"
                ></i>
              </span>
            </div>
          </span> */}
        </div>
        <Link class="bn-bunches__logo" to="/">
          <img
            style={{ width: "240px", height: "90px" }}
            alt="Integrity Flowers' logo"
            src={integrityFlowerLogo}
          />
        </Link>
        {nextAvailableDate && (
          <div>
            <p>
              Earliest available delivery{" "}
              <span class="bold"> {formatDate(nextAvailableDate)}</span>
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default DescNav;
