import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { profile_data, updateProfileData } from "../../../api/api";
import Cookies from "js-cookie";

const EditDetails = () => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState({});

  const [userDataError, setUserDataError] = useState({});

  const [formDataDefaultValue, setFormDataDefaultValue] = useState({});

  const [update, setUpdate] = useState("");

  useEffect(() => {
    setTimeout(() => {
      setUpdate("");
    }, 2500);
  }, [update]);

  useEffect(() => {
    loadData();
    const isLogin = () => {
      const token = Cookies.get("token");
      if (!token) {
        navigate("/login");
      }
    };
    isLogin();
  }, []);

  const loadData = async () => {
    try {
      const response = await profile_data();
      setFormDataDefaultValue(response?.data?.data);
      setUserData(response?.data?.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };

  const handleUpdateUserDetails = async (e) => {
    e.preventDefault();
    const newErrors = {};
    if (!userData.name) {
      newErrors.name = "Please enter your first name.";
    }
    if (!userData.address_1) {
      newErrors.address_1 = "Please enter your address line one.";
    }
    if (!userData.address_2) {
      newErrors.address_2 = "Please enter your address line two.";
    }
    if (!userData.address_3) {
      newErrors.address_3 = "Please enter your address line Three.";
    }
    if (!userData.town) {
      newErrors.town = "Please enter your town.";
    }
    if (!userData.county) {
      newErrors.county = "Please enter your city.";
    }
    if (!userData.country) {
      newErrors.country = "Please enter your county.";
    }
    if (!userData.postcode) {
      newErrors.postcode = "Please enter your postcode.";
    }
    if (!userData.tel_num) {
      newErrors.tel_num = "Please enter your telephone number.";
    } else if (!/^\d+$/.test(userData.tel_num)) {
      newErrors.tel_num = "Telephone number should contain only digits.";
    }

    if (Object.keys(newErrors).length > 0) {
      setUserDataError(newErrors);
      return;
    }

    try {
      const response = await updateProfileData(userData);
      if (response?.data?.message === "Success") {
        setUserDataError({});
        setUpdate("Your data Updated");
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <main id="main-container" className="container top-margin">
      <div className="nav-blackout"></div>
      <div className="row">
        <div className="col-12">
          <ul className="breadcrumb">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/account">Your Account</Link>
            </li>
            <li>Edit Your Details</li>
          </ul>
        </div>
      </div>
      <div className="account-page">
        <div className="header-title">Edit Your Details</div>
        <form
          onSubmit={handleUpdateUserDetails}
          class="edit-customer-details row"
        >
          <div class="section col-12">
            <div class="form">
              <div class="row">
                <div class="col-sm-12 col-md-6 col-lg-6">
                  <div class="form-group">
                    <label for="name" class="form-label">
                      Full Name
                    </label>
                    <input
                      onChange={handleChange}
                      type="text"
                      name="name"
                      id="name"
                      class="col-sm-12 form-control"
                      placeholder="Full name"
                      autocomplete="name"
                      defaultValue={formDataDefaultValue?.name}
                    />
                    {userDataError.name && (
                      <div style={{ color: "red" }} className="error">
                        {userDataError.name}
                      </div>
                    )}
                  </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6">
                  <div class="form-group">
                    <label for="email" class="form-label">
                      Email Address
                    </label>
                    <input
                      onChange={handleChange}
                      type="email"
                      name="email"
                      id="email"
                      class="col-sm-12 form-control"
                      placeholder="Email address"
                      autocomplete="email"
                      defaultValue={formDataDefaultValue?.email}
                      disabled
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label for="address_1" class="form-label">
                      House Number and Street Name
                    </label>
                    <input
                      onChange={handleChange}
                      type="text"
                      name="address_1"
                      id="address_1"
                      class="col-sm-12 form-control"
                      placeholder="First address line"
                      autocomplete="street-address"
                      defaultValue={formDataDefaultValue?.address_1}
                    />
                    {userDataError.address_1 && (
                      <div style={{ color: "red" }} className="error">
                        {userDataError.address_1}
                      </div>
                    )}
                    <label for="address_2" class="form-label">
                      Address Line 2
                    </label>
                    <input
                      onChange={handleChange}
                      type="text"
                      name="address_2"
                      id="address_2"
                      class="col-sm-12 form-control"
                      placeholder="Second address line"
                      autocomplete="address-line1"
                      defaultValue={formDataDefaultValue?.address_2}
                    />
                    {userDataError.address_2 && (
                      <div style={{ color: "red" }} className="error">
                        {userDataError.address_2}
                      </div>
                    )}
                    <label for="address_3" class="form-label">
                      Address Line 3
                    </label>
                    <input
                      onChange={handleChange}
                      type="text"
                      name="address_3"
                      id="address_3"
                      class="col-sm-12 form-control"
                      placeholder="Third address line"
                      autocomplete="address-line1"
                      defaultValue={formDataDefaultValue?.address_3}
                    />
                    {userDataError.address_3 && (
                      <div style={{ color: "red" }} className="error">
                        {userDataError.address_3}
                      </div>
                    )}
                    <label for="town" class="form-label">
                      Town
                    </label>
                    <input
                      onChange={handleChange}
                      type="text"
                      name="town"
                      id="town"
                      class="col-sm-12 form-control"
                      placeholder="Town or city"
                      autocomplete="address-line3"
                      defaultValue={formDataDefaultValue?.town}
                    />
                    {userDataError.town && (
                      <div style={{ color: "red" }} className="error">
                        {userDataError.town}
                      </div>
                    )}
                    <label for="county" class="form-label">
                      City
                    </label>
                    <input
                      onChange={handleChange}
                      type="text"
                      name="county"
                      id="county"
                      class="col-sm-12 form-control"
                      placeholder="City"
                      autocomplete="address-line4"
                      defaultValue={formDataDefaultValue?.county}
                    />
                    {userDataError.county && (
                      <div style={{ color: "red" }} className="error">
                        {userDataError.county}
                      </div>
                    )}
                    <label for="country" class="form-label">
                      country
                    </label>
                    <input
                      onChange={handleChange}
                      type="text"
                      name="country"
                      id="country"
                      class="col-sm-12 form-control"
                      placeholder="country"
                      autocomplete="address-line4"
                      defaultValue={formDataDefaultValue?.country}
                    />
                    {userDataError.country && (
                      <div style={{ color: "red" }} className="error">
                        {userDataError.country}
                      </div>
                    )}
                    <label for="postcode" class="form-label">
                      Postcode
                    </label>
                    <input
                      onChange={handleChange}
                      type="text"
                      name="postcode"
                      id="postcode"
                      class="col-sm-12 form-control"
                      placeholder="postcode"
                      autocomplete="postal-code"
                      defaultValue={formDataDefaultValue?.postcode}
                    />
                    {userDataError.postcode && (
                      <div style={{ color: "red" }} className="error">
                        {userDataError.postcode}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <div class="form-group">
                    <label for="tel_num" class="form-label">
                      Telephone Number
                    </label>
                    <input
                      onChange={handleChange}
                      type="tel"
                      name="tel_num"
                      id="tel_num"
                      class="col-sm-12 form-control"
                      placeholder="Telephone number"
                      autocomplete="tel"
                      defaultValue={formDataDefaultValue?.tel_num}
                    />
                    {userDataError.tel_num && (
                      <div style={{ color: "red" }} className="error">
                        {userDataError.tel_num}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {/* <div class="row input-row no-gutters">
                <div class="col-12 opt-out">
                  <label class="email-label">
                    <input
                      type="checkbox"
                      name="optIn"
                      class="email-input mr-2"
                    />
                    Yes! Please send me discount codes, offers and details of
                    new products by email. I can unsubscribe at any time.
                  </label>
                  <p>
                    If you wish to update your mail preferences, please
                    <a href="/contact-us">contact us</a>.
                  </p>
                </div>
              </div> */}
              {update && <div style={{ color: "green" }}>{update}</div>}
              <div class="section row">
                <div class="col-12 text-center">
                  <button type="submit" class="bn-button bn-button--primary">
                    Update My Details
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </main>
  );
};

export default EditDetails;
