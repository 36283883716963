import React from "react";
import { Routes, Route } from "react-router-dom";
import CategoryFlowers from "./components/Pages/Category/CategoryFlowers/CategoryFlowers";
import CategoryFilter from "./components/Pages/Category/CategoryFilter";

function CategoryRoues() {
  return (
    <main>
      <CategoryFilter />
      <Routes>
        <Route path="/flowers" element={<CategoryFlowers />} />
      </Routes>
    </main>
  );
}

export default CategoryRoues;
