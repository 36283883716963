import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { removeItemFromBasket } from "../../features/basket/basket";
import { deleteCartItem } from "../../api/api";
import { id } from "date-fns/locale";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import ChangeDateForCartItem from "../ChangeDateForCartItem/ChangeDateForCartItem";

const BasketItem = ({ cartItem }) => {
  const coupon = useSelector((state) => state.coupon);
  const allProducts = useSelector((state) => state?.products?.products);
  const cartData = useSelector((state) => state?.basket?.basket);
  const imageUrl = process.env.REACT_APP_IMAGE_URL;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const token = Cookies.get("token");

  const [image, setImage] = useState(null);

  const [isChangeDelivery, setIsChangeDelivery] = useState(false);

  const [cartItemProduct, setCartItemProduct] = useState(null);
  const [cartItemProductVariation, setCartItemProductVariation] =
    useState(null);

  useEffect(() => {
    if (
      cartItem &&
      cartItem.product_variation &&
      cartItem.product_variation.product_gallery &&
      cartItem.product_variation.product_gallery[0] &&
      cartItem.product_variation.product_gallery[0].gallery_image
    ) {
      setImage(cartItem.product_variation.product_gallery[0].gallery_image);
    } else {
      setImage(null);
    }
  }, [cartItem]);

  const formatDate = (d) => {
    if (d === undefined || d === null) return "";
    const date = d instanceof Date ? d : new Date(d);
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    const year = date.getFullYear();
    const month = months[date.getMonth()];
    const dayOfMonth = date.getDate();
    const dayOfWeek = days[date.getDay()];

    const suffix =
      dayOfMonth === 1 || dayOfMonth === 21 || dayOfMonth === 31
        ? "st"
        : dayOfMonth === 2 || dayOfMonth === 22
        ? "nd"
        : dayOfMonth === 3 || dayOfMonth === 23
        ? "rd"
        : "th";

    return `${dayOfWeek} ${dayOfMonth}${suffix} ${month} ${year}`;
  };

  const handleRemoveOrderClick = async () => {
    if (!token) {
      const userCart = localStorage.getItem("user-cart");
      const parsedUserData = JSON.parse(userCart);

      const filteredData = parsedUserData.filter(
        (local) => local.uid !== cartItem.uid
      );

      localStorage.setItem("user-cart", JSON.stringify(filteredData));

      dispatch(removeItemFromBasket(cartItem.uid));
      return;
    }
    try {
      const response = await deleteCartItem(cartItem.id);
      dispatch(removeItemFromBasket(cartItem.uid));
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleDeliveryDateChangeClick = () => {
    setIsChangeDelivery(true);
    if (allProducts) {
      const cartItemProduct = allProducts.filter((prod) => {
        return prod.id === cartItem?.product_variation?.product_detail?.id;
      });
      setCartItemProduct(cartItemProduct[0]);

      const productVariation = cartItemProduct[0]?.product_variation.filter(
        (variation) => {
          return variation?.id === cartItem?.product_variation?.id;
        }
      );
      setCartItemProductVariation(productVariation);
    }
    // cartItem?.product_variation?.product_detail?.id;
    // allProducts
  };
  console.log("cartItem", cartItem);
  return (
    <>
      {cartItemProduct && cartItemProductVariation && (
        <ChangeDateForCartItem
          isChangeDelivery={isChangeDelivery}
          setIsChangeDelivery={setIsChangeDelivery}
          cartItemProduct={cartItemProduct}
          cartItemProductVariation={cartItemProductVariation}
        />
      )}
      <div key={cartItem?.id} class="qnwlewn2cssXojz8JJDN">
        <div class="uSALEIQsrdvIGHiKC4tO">
          <span>
            <h1>{cartItem?.product_variation?.product_detail?.product_name}</h1>
            {cartItem?.item_sale_price !== null ? (
              <div style={{ display: "flex" }}>
                Total:
                <p
                  style={{
                    marginRight: "10px",
                    marginLeft: "3px",
                    textDecoration: "line-through",
                  }}
                >
                  £{cartItem?.item_price ?? 0}
                </p>
                <p>
                  £
                  {(cartItem?.item_sale_price ?? 0) -
                    (coupon?.couponVerification?.discount ?? 0)}
                </p>
              </div>
            ) : (
              <p>
                £
                {(cartItem?.item_price ?? 0) -
                  (coupon?.couponVerification?.discount ?? 0)}
              </p>
            )}
            {/* {cartItem?.item_price !== null ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <p
                  class="_gZi40WOUBBOiZObY0NI colorGray"
                  style={{ marginRight: "10px" }}
                >
                  £{product?.product_variation[0]?.variation_price}
                </p>
                <p class="_gZi40WOUBBOiZObY0NI" style={{ marginLeft: "5px" }}>
                  £{product?.product_variation[0]?.sale_price}
                </p>
              </div>
            ) : (
              <p class="_gZi40WOUBBOiZObY0NI">
                £{product?.product_variation[0]?.variation_price}
              </p>
            )} */}
          </span>
          {/* <h2 className="LqXSDX4TND9QahjDfEHr">
                Total: £
                {(basketState[0]?.item_price ?? 0) -
                  (coupon?.couponVerification?.discount ?? 0)}
              </h2>
              {coupon?.coupon !== null && (
                <p style={{ textAlign: "center" }}>
                  Promo/voucher code{" "}
                  <span style={{ fontWeight: "bold" }}>{coupon?.coupon}</span>{" "}
                  Applied
                </p>
              )} */}
          {image !== null && (
            <Link to="/checkout/1">
              <div class="UntWHkcwCDfpW2otHL22">
                <img
                  src={imageUrl + image}
                  width="56"
                  height="56"
                  alt="image"
                />
              </div>
            </Link>
          )}
        </div>
        {/* <div class="cSbChp747LopTq7nHMDa">
        <Link aria-label="Edit Recipient" to="/checkout/1">
          <img
            src="https://media.bunches.co.uk/icons/pencil.svg"
            alt="Edit Recipient"
            class="o7xquVNwxlbKT9GqzWj2"
            aria-hidden="true"
          />
        </Link>
        <span>
          <h2>Recipient</h2>
          <p></p>
          <Link
            class="Lh3uDByRTNVMP2S75ihs E02GN3vPiDwi03VcE_Cc"
            to="/checkout/1"
          >
            The address for your order is invalid.
          </Link>
        </span>
      </div> */}
        <div class="cSbChp747LopTq7nHMDa">
          <div
            onClick={handleDeliveryDateChangeClick}
            aria-label="Edit Delivery"
          >
            <img
              src="https://media.bunches.co.uk/icons/pencil.svg"
              alt="Edit Delivery"
              class="o7xquVNwxlbKT9GqzWj2"
            />
          </div>
          <span>
            <h2>Delivery Date</h2>
            <p>
              <span>{formatDate(cartItem?.delivery_date)}</span>
            </p>
          </span>
        </div>
        {/* <div class="wNWFwb3r7ECbic09Dcdn">
          <div>
            <Link
              class="Lh3uDByRTNVMP2S75ihs E02GN3vPiDwi03VcE_Cc"
              to="/checkout/1"
            >
              Your card message is empty.
            </Link>
          </div>
        </div> */}
        <div class="nhLMrWYJKH6Alqtdyusw"></div>
        <p class="VW0gqJP30HEqZt4Qc4xK BzQzDX8laulhgkV0bs6e">
          <span>
            <span onClick={handleRemoveOrderClick}>Remove order</span>
          </span>
        </p>
        <div class="QN43NdwD2N7NVsHJqGZA">
          {/* <button class="VRZl5T_cEakrM5LuPOBb YLxdcrrIEpFGSqqBDTXs">
          More Info
        </button> */}
          {/* <Link
            class="e7QvJMPhPw6Pbciz9UHc YLxdcrrIEpFGSqqBDTXs VRZl5T_cEakrM5LuPOBb"
            to="/checkout/1"
          >
            Edit
          </Link> */}
        </div>
      </div>
    </>
  );
};

export default BasketItem;
