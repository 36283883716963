import React from "react";
import "./Loader.css";

const CircleLoader = () => {
  return (
    <div
      style={{ display: "flex", justifyContent: "center", marginTop: "30px" }}
    >
      <div class="loading"></div>
    </div>
  );
};

export default CircleLoader;
