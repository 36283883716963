import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isCheckout: false,
};

export const checkOutSlice = createSlice({
  name: "checkOut",
  initialState,
  reducers: {
    addIsCheckOut: (state, action) => {
      state.isCheckout = action.payload;
    },
  },
});

export const { addIsCheckOut } = checkOutSlice.actions;

export default checkOutSlice.reducer;
