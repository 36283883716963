import React, { useEffect, useState } from "react";
import { useUserContext } from "../UserContext";

const NameAndAddress = ({ nextPage }) => {
  const { userData, updateUserData } = useUserContext();

  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    address_line_1: "",
    address_line_2: "",
    postcode: "",
    city: "",
    country: "United Kingdom",
  });

  const [errors, setErrors] = useState({
    name: "",
    phone: "",
    address_line_1: "",
    address_line_2: "",
    postcode: "",
    city: "",
    country: "",
  });

  useEffect(() => {
    setFormData({
      ...formData,
      name: userData.name,
      phone: userData.phone,
      address_line_1: userData.address_line_1,
      address_line_2: userData.address_line_2,
      postcode: userData.postcode,
      city: userData.city,
      country: userData.country,
    });
  }, [userData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    setErrors({
      ...errors,
      [name]: "",
    });

    updateUserData({
      ...userData,
      [name]: value,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    let newErrors = {};

    if (!formData.name) {
      newErrors = {
        ...newErrors,
        name: "Please enter full name",
      };
    }

    if (!formData.phone) {
      newErrors = {
        ...newErrors,
        phone: "Please enter phone number.",
      };
    } else if (!/^\d{10,15}$/.test(formData.phone)) {
      newErrors = {
        ...newErrors,
        phone: "Please enter a valid phone number.",
      };
    }

    if (!formData.postcode) {
      newErrors = {
        ...newErrors,
        postcode: "Please enter postcode.",
      };
    }

    if (!formData.address_line_1) {
      newErrors = {
        ...newErrors,
        address_line_1: "Please enter address line 1",
      };
    }

    // if (!formData.address_line_2) {
    //   newErrors = {
    //     ...newErrors,
    //     address_line_2: "Please enter address line 2",
    //   };
    // }

    if (!formData.city) {
      newErrors = {
        ...newErrors,
        city: "Please enter city",
      };
    }

    // if (!formData.country) {
    //   newErrors = {
    //     ...newErrors,
    //     country: "Please enter country",
    //   };
    // }

    setErrors((prevErrors) => ({
      ...prevErrors,
      ...newErrors,
    }));

    if (Object.keys(newErrors).length === 0) {
      nextPage();
    }
  };

  return (
    <section id="recipient" class="F8D7tEzAQUW7EsIPEric">
      <div class="q4aopOnggp2NoeHw7lAZ">
        <form onSubmit={handleSubmit} autocomplete="off">
          <div class="Ol5sCKYGyQSPFdbFn_QV mNQlLGz2_naGtes9kKDM">
            <div class="VnBpn0GY4tQEXz8hKHDQ">
              <label for="name">Full name</label>
              <input
                id="name"
                type="text"
                name="name"
                autoComplete="off"
                maxLength="35"
                value={formData.name}
                onChange={handleInputChange}
              />
              {errors.name && (
                <div style={{ color: "red" }} className="error">
                  {errors.name}
                </div>
              )}
            </div>

            <div class="VnBpn0GY4tQEXz8hKHDQ">
              <label for="name">Phone</label>
              <input
                id="phone"
                type="text"
                name="phone"
                autoComplete="off"
                maxLength="35"
                value={formData.phone}
                onChange={handleInputChange}
              />
              {errors.phone && (
                <div style={{ color: "red" }} className="error">
                  {errors.phone}
                </div>
              )}
            </div>

            {/* <div class="gxwW3e6krm2dub1Wtyy4">
              <span>Start typing below or </span>
              <a role="button" class="VW0gqJP30HEqZt4Qc4xK">
                click here to enter manually
              </a>
            </div> */}
            <div class="VnBpn0GY4tQEXz8hKHDQ">
              <h4>Delivery address line 1</h4>
              <div class="address-lookup">
                <input
                  name="address_line_1"
                  type="text"
                  className="input--full qas-typeahead"
                  autoComplete="off"
                  value={formData.address_line_1}
                  onChange={handleInputChange}
                />
                {errors.address_line_1 && (
                  <div style={{ color: "red" }} className="error">
                    {errors.address_line_1}
                  </div>
                )}
              </div>
            </div>

            <div class="VnBpn0GY4tQEXz8hKHDQ">
              <h4>Delivery address line 2</h4>
              <div class="address-lookup">
                <input
                  name="address_line_2"
                  type="text"
                  className="input--full qas-typeahead"
                  autoComplete="off"
                  value={formData.address_line_2}
                  onChange={handleInputChange}
                />
                {errors.address_line_2 && (
                  <div style={{ color: "red" }} className="error">
                    {errors.address_line_2}
                  </div>
                )}
              </div>
            </div>
            <div className="checkout-form-contact-info">
              <div class="VnBpn0GY4tQEXz8hKHDQ">
                <h4>City</h4>
                <input
                  name="city"
                  type="text"
                  className="input--full qas-typeahead input-integrity-address"
                  autoComplete="off"
                  value={formData.city}
                  onChange={handleInputChange}
                />
                {errors.city && (
                  <div style={{ color: "red" }} className="error">
                    {errors.city}
                  </div>
                )}
              </div>

              <div class="VnBpn0GY4tQEXz8hKHDQ">
                <h4>Country</h4>
                <input
                  name="country"
                  type="text"
                  className="input--full qas-typeahead input-integrity-address"
                  autoComplete="off"
                  value={formData.country}
                  readOnly
                  // onChange={handleInputChange}
                />
                {errors.country && (
                  <div style={{ color: "red" }} className="error">
                    {errors.country}
                  </div>
                )}
              </div>

              <div class="VnBpn0GY4tQEXz8hKHDQ">
                <h4>Postcode</h4>
                <input
                  name="postcode"
                  type="text"
                  className="input--full qas-typeahead input-integrity-address"
                  autoComplete="off"
                  value={formData.postcode}
                  onChange={handleInputChange}
                />
                {errors.postcode && (
                  <div style={{ color: "red" }} className="error">
                    {errors.postcode}
                  </div>
                )}
              </div>
            </div>
          </div>
          <button
            class="VRZl5T_cEakrM5LuPOBb YLxdcrrIEpFGSqqBDTXs"
            type="submit"
          >
            Continue
          </button>
        </form>
      </div>
    </section>
  );
};

export default NameAndAddress;
