import React from "react";

const Terms = () => {
  return (
    <main id="main-container" class="container top-margin">
      <div class="nav-blackout"></div>
      <main class="row static-page">
        <aside class="col-md-3 static-page-side-links" id="links">
          <a
            class="nav-link active"
            aria-controls="overview"
            href="/terms#termsAndConditions"
          >
            Terms &amp; Conditions
          </a>
          <a
            class="nav-link"
            aria-controls="personalisedCardTerms"
            href="/terms#personalisedCardTermsAndConditions"
          >
            Personalised Products Terms And Conditions
          </a>
          <a
            class="nav-link"
            aria-controls="hampersTerms"
            href="/terms#hampersTermsAndConditions"
          >
            Hampers Terms &amp; Conditions
          </a>
          <a
            class="nav-link"
            aria-controls="privacyPolicy"
            href="/terms#privacyPolicy"
          >
            Privacy Policy
          </a>
          <a
            class="nav-link"
            aria-controls="cookies"
            href="/terms#cookiePolicy"
          >
            Cookie Policy
          </a>
        </aside>
        <section class="col-md-9">
          <div role="tablist" aria-multiselectable="true">
            <article class="card">
              <header class="card-header" role="tab">
                <h2 class="card-title">Terms &amp; Conditions</h2>
              </header>
              <div>
                <div class="card-block" role="tabpanel">
                  <p>
                    Integrity Flowers Florapost Ltd are completely dedicated to
                    your total satisfaction.
                  </p>
                  <p>
                    If you have any suggestions or comments regarding our
                    flowers please email us at
                    <a href="mailto:flowers@Integrity Flowers">
                      flowers@Integrity Flowers
                    </a>
                    .
                  </p>
                  <section>
                    <h3 class="h3">Our Contact Details:</h3>
                    <address>
                      Integrity Flowers Florapost Ltd
                      <br />
                      19 Hazelford Way
                      <br />
                      Newstead Village
                      <br />
                      Nottinghamshire
                      <br />
                      United Kingdom
                      <br />
                      NG15 0DQ
                    </address>
                    <p>
                      <strong>Telephone:</strong>
                      <a rel="nofollow" href="tel:+44 20 8123 7938">
                        +44 20 8123 7938
                      </a>
                      <br />
                      <strong>From outside UK:</strong>
                      <a rel="nofollow" href="tel:+441623750343">
                        +44 1623 750343
                      </a>
                      <br />
                      <strong>Email:</strong>
                      <a href="mailto:flowers@Integrity Flowers">
                        flowers@Integrity Flowers
                      </a>
                    </p>
                    <p>
                      Registered in England and Wales. Company registration
                      number:
                      <em>3924234</em>
                      <br />
                      VAT registration number:
                      <em>509377820</em>
                      <br />
                      Data Protection Act registration number <em>Z4831682</em>
                      <br />
                      All prices include
                      <abbr title="Value Added Tax">VAT</abbr> at the relevant
                      rate.
                    </p>
                  </section>
                  <section>
                    <h3 class="h3">Making A Purchase</h3>
                    <p>
                      This site has been designed to make ordering flowers in
                      the Integrity Flowers range as easy and convenient as
                      possible. Simply select your bouquet, click on the 'Place
                      Order' button, proceed to checkout, fill in your details
                      and leave the rest to us. Your beautifully boxed flowers
                      and gifts will be sent by guaranteed courier or Royal Mail
                      Tracked 24 to any address in the UK (excluding Northern
                      Ireland and the Republic of Ireland) - 7 days a week.
                    </p>
                    <p>
                      Please note - you can only use one promotional code per
                      order.
                    </p>
                  </section>
                  <section>
                    <h3 class="h3">
                      General Delivery Information For All Orders
                    </h3>
                    <ul>
                      <li>
                        We can deliver to any postal address in England, Wales,
                        Scotland (including all highlands and islands), the
                        Channel Islands, Isle of Man, Orkney, and Shetland. We
                        are unable to deliver any item to Northern Ireland or
                        the Republic of Ireland.
                      </li>
                      <li>
                        Please note
                        <strong>we cannot offer same day delivery</strong>.
                      </li>
                      <li>
                        When ordering please enter the full address including
                        the postcode of the recipient.
                        <strong>
                          We will not be held responsible for items that are not
                          delivered due to incorrect details.
                        </strong>
                      </li>
                      <li>
                        We do not pass on any details and the only information
                        sent with the gift is the personal message card.
                      </li>
                      <li>
                        For Royal Mail deliveries, should the recipient be away
                        at the time of delivery, a card will be left stating
                        attempted delivery and will include details of how to
                        collect them or arrange re-delivery.
                      </li>
                      <li>
                        Please note - we do not include a vase with any of our
                        bouquets as standard. Bouquets are pictured in a vase on
                        the website for display purposes, but a vase is not
                        shipped with any bouquet unless one is added as an
                        optional extra in the checkout.
                      </li>
                      <li>
                        Flowers sent to hospitals, schools, military, and
                        business addresses will be delivered to a main reception
                        or mailing room prior to internal distribution. We
                        recommend that orders to such locations be requested for
                        delivery on a weekday and not Saturday.
                      </li>
                      <li>
                        Unfortunately we are unable to deliver on Bank Holidays.
                      </li>
                    </ul>
                  </section>
                  <section>
                    <h3 class="h3">Delivery Information For Flower Gifts</h3>
                    <ul>
                      <li>All flowers and plants exclude delivery.</li>
                      <li>
                        Delivery charges start at £3.49 unless stated otherwise.
                      </li>
                      <li>
                        We usually dispatch flowers one day prior to the
                        delivery date requested.
                      </li>
                      <li>
                        As we deliver by Royal Mail Tracked 24, or an equivalent
                        service, we cannot deliver on Bank Holidays.
                      </li>
                      <li>
                        Deliveries can take place up to 6pm Monday to Sunday.
                      </li>
                    </ul>
                  </section>
                  <section>
                    <h3 class="h3">Optional Courier Delivery Details</h3>
                    <ul>
                      <li>
                        For an additional fee of £5.99 we offer a guaranteed
                        delivery on the day of your choice between 8:00am and
                        6:00pm.
                      </li>
                      <li>
                        Flowers delivered by this service may need to be signed
                        for by the recipient.
                      </li>
                    </ul>
                  </section>
                  <section>
                    <h3 class="h3">Credit Card Security</h3>
                    <p>
                      Integrity Flowers Florapost accept payment from all major
                      credit and debit cards, including Visa, Mastercard,
                      Switch/Maestro, American Express, Visa Electron, Delta,
                      Eurocard, and JCB. We also accept payment by PayPal,
                      Google Pay, and Apple Pay.
                    </p>
                    <p>
                      This site uses Braintree (PayPal) and Stripe for online
                      credit card transactions. These are the most popular
                      online payment systems in the UK. All payment information
                      is transmitted in secure mode and is encrypted using the
                      highest levels of online security encryption available.
                      Credit card details are captured within the systems of the
                      payment providers, and no card details are stored on your
                      computer or computers belonging to Integrity Flowers
                      Florapost. When your order has been placed, you will
                      receive an email from Integrity Flowers to confirm the
                      payment has been successful.
                    </p>
                  </section>
                  <section>
                    <h3 class="h3">Substitution Policy</h3>
                    <p>
                      In the unlikely event of supply difficulties, Integrity
                      Flowers Florapost Ltd reserves the right to substitute any
                      product with any other type of equal quality and value.
                    </p>
                  </section>
                  <section>
                    <h3 class="h3">Returns Policy and Cancellation Rights</h3>
                    <p>
                      If you are in any way disappointed with our flowers or
                      service, please telephone us on
                      <a rel="nofollow" href="tel:+44 20 8123 7938">
                        +44 20 8123 7938
                      </a>{" "}
                      or
                      <a rel="nofollow" href="tel:+441623750343">
                        (+44) 1623 750343
                      </a>
                      , or email us at
                      <a href="mailto:flowers@Integrity Flowers">
                        flowers@Integrity Flowers
                      </a>
                      .
                    </p>
                    <address>
                      Integrity Flowers Florapost Ltd
                      <br />
                      19 Hazelford Way
                      <br />
                      Newstead Village
                      <br />
                      Nottinghamshire
                      <br />
                      NG15 0DQ
                    </address>
                    <ul>
                      <li>
                        If you have cause for complaint this must be received
                        within 14 days after dispatch. Please note we cannot
                        guarantee an exact delivery date on flowers dispatched
                        by first class post.
                      </li>
                      <li>
                        Our customer support phone lines are open 9am - 5pm
                        Monday to Friday (excluding bank holidays). This may be
                        extended during busy periods such as around Mother's Day
                        and Christmas.
                      </li>
                      <li>All calls are charged at the national rate.</li>
                      <li>
                        We aim to respond to customer service matters within one
                        working day and resolve all complaints within three
                        working days. Calls may be monitored for training
                        purposes and to maintain our high level of quality
                        service.
                      </li>
                    </ul>
                    <p>
                      In accordance with the
                      <em>Consumer Contracts Regulations (2013)</em>
                      you may also cancel your order at any time without reason,
                      within 14 working days of delivery. Should you wish to
                      cancel your order and receive a refund, you must confirm
                      this in writing within 14 working days of receipt and
                      return the packaged item to us at the address given above.
                    </p>
                    <p>
                      The item(s) must be returned to us unused and in as new
                      condition. You will be liable for all postage costs
                      incurred as a result of returning an item to us. We cannot
                      issue a refund until we have received the goods. We
                      recommend you obtain proof of posting when you return any
                      goods to us.
                    </p>
                    <p>
                      Please note this policy excludes all our flower products
                      as these are perishable goods and exempt from this
                      legislation. This does not affect your statutory rights as
                      a consumer.
                    </p>
                  </section>
                  <section>
                    <h3 class="h3">Website Disclaimer</h3>
                    <p>
                      The information contained in this website is for general
                      information purposes only. The information is provided by
                      Integrity Flowers Florapost Limited and while we endeavour
                      to keep the information up to date and correct, we make no
                      representations or warranties of any kind, express or
                      implied, about the completeness, accuracy, reliability,
                      suitability or availability with respect to the website or
                      the information, products, services, or related graphics
                      contained on the website for any purpose. Any reliance you
                      place on such information is therefore strictly at your
                      own risk.
                    </p>
                    <p>
                      In no event will we be liable for any loss or damage
                      including without limitation, indirect or consequential
                      loss or damage, or any loss or damage whatsoever arising
                      from loss of data or profits arising out of, or in
                      connection with, the use of this website.
                    </p>
                    <p>
                      Through this website you are able to link to other
                      websites which are not under the control of Integrity
                      Flowers Florapost Limited. We have no control over the
                      nature, content and availability of those sites. The
                      inclusion of any links does not necessarily imply a
                      recommendation or endorse the views expressed within them.
                    </p>
                    <p>
                      Every effort is made to keep the website up and running
                      smoothly. However, Integrity Flowers Florapost Limited
                      takes no responsibility for, and will not be liable for,
                      the website being temporarily unavailable due to technical
                      issues beyond our control.
                    </p>
                  </section>
                </div>
              </div>
            </article>
            <article class="card" hidden="">
              <header class="card-header" role="tab">
                <h2 class="card-title">
                  Additional Terms &amp; Conditions for Personalised Products
                </h2>
              </header>
              <div hidden="">
                <div class="card-block" role="tabpanel">
                  <p>
                    For personalised products, we have the following additional
                    and amended terms.
                  </p>
                  <ul>
                    <li>
                      here may be occasions in which we are unable to accept
                      your order after a confirmation email has been received,
                      if this happens we will promptly contact you via email and
                      offer a full refund. For example, an order may not be
                      accepted if it violates our content rules (see below for
                      content rules)
                    </li>
                    <li>
                      Images must be uploaded in JPEG or PNG format, any other
                      format cannot be accepted
                    </li>
                    <li>
                      We cannot accept responsibility if your expectations
                      aren't met if low resolution images have been supplied
                    </li>
                    <li>
                      Material that violates or may be perceived as violating
                      the rights of others
                    </li>
                    <li>
                      Material that contains viruses or other forms of
                      corruption, along with content that may compromise the
                      integrity of the Integrity Flowers website
                    </li>
                    <li>
                      Material that promotes or advocates illegal activity
                    </li>
                    <li>Material that references illegal drugs</li>
                    <li>Material of a political nature</li>
                    <li>Material of an extremist religious nature</li>
                    <li>Material of a distressing nature</li>
                    <li>
                      Content we believe is abusive, deceptive, pornographic,
                      obscene, defamatory, slanderous, offensive, racist or
                      otherwise inappropriate
                    </li>
                    <li>
                      Material that is or can be perceived to be harmful to
                      children
                    </li>
                  </ul>
                  <p>
                    We have sole discretion to determine whether content is
                    prohibited.
                  </p>
                  <p>
                    We monitor content that has been flagged by our systems or
                    staff.
                  </p>
                  <p>
                    We do not take responsibility for any prohibited content
                    uploaded to our website.
                  </p>
                  <p>
                    However, we may delete or edit content without notice if it
                    breaks any policies or laws.
                  </p>
                </div>
              </div>
            </article>
            <article class="card" hidden="">
              <header class="card-header" role="tab">
                <h2 class="card-title">
                  Additional Terms &amp; Conditions for Hampers
                </h2>
              </header>
              <div hidden="">
                <div class="card-block" role="tabpanel">
                  <p>
                    For our hampers, we have the following additional terms.
                  </p>
                  <ul>
                    <li>All hampers exclude delivery.</li>
                    <li>Hamper delivery charges start at £3.50.</li>
                    <li>
                      We cannot deliver hampers on the same day of ordering.
                      Orders placed before 1:00pm can be dispatched same day for
                      delivery at the earliest available date.
                    </li>
                    <li>We do not deliver outside the UK.</li>
                    <li>We cannot deliver to PO boxes or BFPO addresses.</li>
                  </ul>
                  <h3>For hampers delivered by tracked courier</h3>
                  <ul>
                    <li>
                      The following postcodes will incur an additional charge to
                      the standard delivery to these postcodes. It can take 2-7
                      working days for delivery:
                      <ul>
                        <li>
                          <strong>
                            AB30-63, DD8-10, FK19-21, G83-84, GY1-9, HS1-9,
                            IV1-39, IV40-56, IV57-63, IM1-9, IM99, JE1-3,
                            KW1-18, KA27-28, PA20-80, PH1, PH5-16, PH17-50,
                            PO30-41, SY23-25, SA20, TR12, TR19, TD15, TR21-25,
                            ZE1-3.
                          </strong>
                        </li>
                      </ul>
                    </li>
                    <li>
                      The Saturday and Sunday delivery service is currently
                      unavailable for the Highlands and Islands.
                    </li>
                    <li>
                      Hampers sent out via tracked courier (DPD/Hermes) are
                      delivered on your chosen date between Monday and Sunday.
                      Courier delivered hampers cannot be delivered on bank
                      holidays.
                    </li>
                    <li>
                      All hampers delivered via tracked courier will have to be
                      signed for and if the recipient is out then a card will be
                      left with instructions to contact the courier and
                      rearrange delivery.
                    </li>
                  </ul>
                </div>
              </div>
            </article>
            <article class="card" hidden="">
              <header class="card-header" role="tab">
                <h2 class="card-title">Privacy Policy</h2>
              </header>
              <div hidden="">
                <div class="card-block" role="tabpanel">
                  <p>
                    Here at Integrity Flowers, we understand how important your
                    personal data is. We have and always will be committed to
                    protecting your information, and we do this in compliance
                    with both the Data Protection Act and GDPR.
                  </p>
                  <div class="row">
                    <div class="col-12 col-md-9 vertical-align">
                      <span class="bold">To Deliver Happiness…</span>
                      <p>
                        We will only ever use your data to process your order,
                        to contact you regarding the order and to keep you up to
                        date with useful product information and offers. We
                        simply wish to provide you with the best possible
                        service and we only use the necessary data to deliver
                        happiness for you on every occasion.
                      </p>
                    </div>
                    <div class="col d-none d-md-block d-lg-block d-xl-block">
                      <img
                        alt="Flowers"
                        src="https://media.Integrity Flowers/flower-flatlay.jpg"
                        class="img-fluid"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col d-none d-md-block d-lg-block d-xl-block">
                      <img
                        alt="A rose"
                        src="https://media.Integrity Flowers/tape-rose.jpg"
                        class="img-fluid"
                      />
                    </div>
                    <div class="col-12 col-md-9 vertical-align">
                      <span class="bold">You're in Control…</span>
                      <p>
                        If you want us to keep in touch, we will. If not, that's
                        no problem. If you ever want to unsubscribe - this can
                        be done by simply clicking 'unsubscribe' on any of our
                        emails or via your online Integrity Flowers account
                        under 'edit your details'. Alternatively, you can email
                        us at flowers@Integrity Flowers.
                      </p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 col-md-9 vertical-align">
                      <span class="bold">Rest Assured…</span>
                      <p>
                        We store all your personal data securely, and we won't
                        hang onto your data any longer than needs be.
                      </p>
                    </div>
                    <div class="col d-none d-md-block d-lg-block d-xl-block">
                      <img
                        alt="Mixed stems"
                        src="https://media.Integrity Flowers/mixed-stems.jpg"
                        class="img-fluid"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col d-none d-md-block d-lg-block d-xl-block">
                      <img
                        alt="Petals"
                        src="https://media.Integrity Flowers/petals.jpg"
                        class="img-fluid"
                      />
                    </div>
                    <div class="col-12 col-md-9 vertical-align">
                      <span class="bold">Simple and Straightforward…</span>
                      <p>
                        We don't hide behind complex legal terms so if you have
                        any questions regarding our privacy policy, we have our
                        own data protection officer on site available to answer
                        any queries you may have.
                      </p>
                    </div>
                  </div>
                  <p class="text-center add-top-margin">
                    We are here for<strong> you</strong> and will look after
                    your personal data.
                  </p>
                  <span class="watercolor-background--green">
                    Click here to read our full Privacy Policy
                  </span>
                  <div hidden="">
                    <h4>How we use your data and why:</h4>
                    <ol>
                      <li>
                        <p>Name and contact details of you and recipients</p>
                        <ul>
                          <li>
                            To deliver your gifts for you - we need this
                            information to complete your order.
                          </li>
                          <li>
                            To keep in touch - if needs be - with regards to
                            your order, providing you with the best possible
                            service.
                          </li>
                          <li>
                            To keep you up to date on new ranges, our latest
                            gifts and discounts - unless of course, you choose
                            not to be included on our mailing lists.
                          </li>
                          <li>
                            To detect and prevent any potential fraudulent
                            activity - this is important to us, as we wish to
                            safeguard your data to the highest possible degree.
                          </li>
                          <li>
                            To allow you to sign in to your Integrity Flowers
                            account via Facebook.
                          </li>
                        </ul>
                      </li>
                      <li>
                        <p>Uploaded images</p>
                        <ul>
                          <li>
                            To fulfil your personalised card order – we need
                            these to print your greetings card
                          </li>
                        </ul>
                      </li>
                      <li>
                        <p>Your payment information</p>
                        <ul>
                          <li>
                            To take payment or refund you. We can't uphold our
                            contract with you without this information! However,
                            we do not store your card details. If you choose to
                            store your card details on your online Integrity
                            Flowers account, or you have a current subscription
                            with us, they will be stored with our secure payment
                            provider under contract with ourselves in compliance
                            with the Payment Card Industry Data Security
                            Standard (PCI DSS).
                          </li>
                          <li>
                            To detect and prevent any potential fraudulent
                            activity.
                          </li>
                        </ul>
                      </li>
                      <li>
                        <p>Your order history</p>
                        <ul>
                          <li>
                            To provide you with the best possible customer care
                            and support - our customer support team need this
                            information to help you with any order queries,
                            complaints and thank-yous!
                          </li>
                          <li>
                            We also use your order history for our reports to
                            continue to try and provide you with the best
                            products and service.
                          </li>
                        </ul>
                      </li>
                      <li>
                        <p>How you use our website</p>
                        <ul>
                          <li>
                            To improve your overall experience, we use a web
                            analytics function. This records device data i.e.
                            whether you are visiting our site using a computer,
                            phone or tablet; what operating system or browser
                            this employs; as well as device model. It also
                            enables us to see what pages you have visited and
                            where you've clicked, this is performed through the
                            use of cookies. We use this information solely to
                            improve your experience on our website. Please note
                            this information is completely anonymous.
                          </li>
                        </ul>
                      </li>
                      <li>
                        <p>
                          Your responses to competitions, promotions and surveys
                        </p>
                        <ul>
                          <li>
                            We need this information to carry out our contract
                            with you! - Without this information you wouldn't be
                            able to enter competitions or receive promotions
                            from us.
                          </li>
                        </ul>
                      </li>
                    </ol>
                    <p>
                      You are not obliged to provide us with your personal
                      information, however if you don't you may not be able to
                      complete your purchase with us. This may also impact your
                      overall customer experience. But, this is your choice and
                      we will always respect your decision.
                    </p>
                    <h4>Sharing your information</h4>
                    <p>
                      We value your data and would therefore never sell your
                      data to any third party. This includes all information you
                      provide us with. We simply aim to earn your trust and
                      maintain this throughout our consumer-customer
                      relationship. At Integrity Flowers we are like a family,
                      and with many of our staff also being customers too, we
                      all understand the importance of trust and privacy.
                    </p>
                    <p>
                      In the process of fulfilling your order or marketing
                      requests we do pass your data onto the following parties:
                    </p>
                    <ul>
                      <li>
                        Delivery companies including Royal Mail, Royal Mail
                        Special Delivery, DPD and DPD Local.
                      </li>
                      <li>
                        Our payment service providers: Braintree and Stripe.
                      </li>
                      <li>
                        Marketing distribution companies, including but not
                        limited to service review websites, print services, etc.
                      </li>
                      <li>
                        If you choose to sign in via Facebook, this information
                        is shared in order to link your Facebook account to your
                        Integrity Flowers account.
                      </li>
                    </ul>
                    <h4>Marketing messages</h4>
                    <p>
                      If you have purchased with us within the last 48 months we
                      will keep you up to date with marketing materials by email
                      or post, unless you have otherwise stated that you do not
                      wish to receive these. After this period, we will contact
                      you to ask if you would like to remain on our mailing
                      lists, and as always this is your decision and we will
                      respect that. We do this as you have expressed an interest
                      in our products and we like to warmly welcome you to the
                      Integrity Flowers family.
                    </p>
                    <p>
                      You can stop marketing materials being sent at
                      <span class="bold italic">any</span> time.
                    </p>
                    <p>You can do so…</p>
                    <ul>
                      <li>
                        Via your online Integrity Flowers account under
                        <span class="bold italic">'edit your details'</span>.
                      </li>
                      <li>
                        By clicking
                        <span class="bold italic">'unsubscribe'</span> at the
                        bottom of any one of our emails.
                      </li>
                      <li>
                        Or by contacting our customer support team by email at
                        <a href="mailto:flowers@Integrity Flowers">
                          flowers@Integrity Flowers
                        </a>
                        , or alternatively by phone on
                        <span class="bold">+44 20 8123 7938</span>.
                      </li>
                    </ul>
                    <p>
                      Once you have unsubscribed, this will be actioned within
                      <span class="bold italic">7 days</span>.
                    </p>
                    <p class="bold">
                      Stopping marketing messages will not stop order
                      confirmation emails and order updates from being received.
                    </p>
                    <p>
                      If you wish to amend any of your account details, this can
                      be done…
                    </p>
                    <ul>
                      <li>
                        Via your online Integrity Flowers account under
                        <span class="bold italic">'edit your details'</span>.
                      </li>
                      <li>
                        Or by contacting our customer support team by email at
                        <a href="mailto:flowers@Integrity Flowers">
                          flowers@Integrity Flowers
                        </a>
                        , or alternatively by phone on
                        <span class="bold">+44 20 8123 7938</span>.
                      </li>
                    </ul>
                    <p>
                      Any amendments will be made
                      <span class="bold italic">immediately</span> on your
                      account but may take up to{" "}
                      <span class="bold italic">7 days</span> for updates to be
                      made to our mailing lists.
                    </p>
                    <h4>Keeping your information</h4>
                    <p>
                      We will hold your data for as long as you have an account
                      with us; if you don't order with us for 48 months or more,
                      we will contact you to let you know we will no longer be
                      keeping in touch, but we will still keep your account in
                      case you ever want to come back! If you still wish to hear
                      from us this can be updated at any point by contacting our
                      customer support team by email at
                      <a href="mailto:flowers@Integrity Flowers">
                        flowers@Integrity Flowers
                      </a>
                      , or alternatively by phone on
                      <span class="bold">+44 20 8123 7938</span>.
                    </p>
                    <p>
                      If you don't have an account with us and use our guest
                      checkout feature, we will hold your data for as long as we
                      may need to be able to provide you with the highest degree
                      of customer care.
                    </p>
                    <p>
                      If you upload images for our personalised card service,
                      these images will be retained for 30 days from the date of
                      dispatch in order for us to fulfil your order and offer
                      the highest level of customer care should something go
                      wrong or you have any queries or amendments.
                    </p>
                    <p>
                      We keep records of your orders and account to meet legal
                      requirements, as well as for reporting purposes.
                    </p>
                    <p>
                      If you wish for us to anonymise your account, we can. Upon
                      anonymisation the account would have no indication that it
                      had once belonged to you.
                    </p>
                    <h4>Your rights</h4>
                    <p>
                      Here at Integrity Flowers we believe that it is of great
                      importance that you are aware of your rights and have the
                      amenities by which to exercise them.
                    </p>
                    <ul>
                      <li>
                        The right to access your personal data held by us.
                      </li>
                      <li>
                        The right to be informed about how we use your personal
                        data.
                      </li>
                      <li>
                        The right to request any incorrect data to be rectified
                        - this can be done via your online account or
                        alternatively by contacting our customer support team.
                      </li>
                      <li>
                        The right to request that we cease processing your data.
                      </li>
                      <li>
                        The right to request that we anonymise or delete your
                        data.
                      </li>
                      <li>
                        The right to stop direct marketing messages - this can
                        be done via your online account, by clicking unsubscribe
                        at the bottom of any marketing email or alternatively by
                        contacting our customer support team.
                      </li>
                      <li>
                        The right to request your data to be transferred to
                        either yourself or another service provider.
                      </li>
                      <li>The right to withdraw consent.</li>
                      <li>
                        The right to file a complaint with the Data Protection
                        Regulator - in the UK this is the Information
                        Commissioner's Office. Details of how to contact them
                        can be found at
                        <span class="bold">ICO.org.uk</span>.
                      </li>
                    </ul>
                    <p>
                      If you wish to exercise any of your rights, or have any
                      questions, our Data Protection officer will be more than
                      happy to help. Please send any queries to
                      <a href="mailto:dataprotection@Integrity Flowers">
                        dataprotection@Integrity Flowers
                      </a>
                      , or alternatively you you can ask to speak to our data
                      protection officer on{" "}
                      <span class="bold">+44 20 8123 7938</span>.
                    </p>
                    <h4>Updates to this notice</h4>
                    <p>
                      This information is subject to change as we often review
                      how we process your data.
                    </p>
                    <p>
                      If there are any significant alterations made, we will
                      contact you via email or post.
                    </p>
                    <p>This notice was last updated on the 06/05/2022.</p>
                  </div>
                </div>
              </div>
            </article>
            <article class="card" hidden="">
              <header class="card-header" role="tab">
                <h2 class="card-title">Cookie Policy</h2>
              </header>
              <div hidden="">
                <div class="card-block" role="tabpanel">
                  <section>
                    <h3 class="h3">Visitors to our Website</h3>
                    <p>
                      When someone visits <a href="/">www.Integrity Flowers</a>{" "}
                      we collect standard Internet log information and details
                      of visitor behaviour patterns. We do this to find out
                      things such as the number of visitors to the various parts
                      of the site.
                    </p>
                  </section>
                  <section>
                    <h3 class="h3">Use of Cookies</h3>
                    <p>
                      Cookies are small text files that are placed on your
                      computer by websites that you visit. They are widely used
                      in order to make websites work, or work more efficiently,
                      as well as to provide information to the owners of the
                      site.
                    </p>
                    <p>The table below explains the cookies we use and why.</p>
                    <table class="table table-striped">
                      <thead>
                        <tr>
                          <th>Cookie</th>
                          <th>Name</th>
                          <th>Purpose</th>
                          <th>More Information</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>PHP Session</td>
                          <td>PHPSESSID</td>
                          <td>
                            This cookie is used to maintain your session with
                            Integrity Flowers allowing you to place orders.
                          </td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>Integrity Flowers login details</td>
                          <td>
                            email
                            <br />
                            password
                          </td>
                          <td>
                            These cookies are optional. They are created when
                            ticking the "remember me" box when logging in. They
                            allow for Integrity Flowers to remember your login
                            details. The password cookie is encrypted to ensure
                            your password is safe.
                          </td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>Google Analytics</td>
                          <td>
                            <ul>
                              <li>_utma</li>
                              <li>_utmb</li>
                              <li>_utmc</li>
                              <li>_utmz</li>
                              <li>_atuvc</li>
                            </ul>
                          </td>
                          <td>
                            These cookies are used to collect information about
                            how visitors use our site. We use the information to
                            compile reports and to help us improve the site. The
                            cookies collect information in an anonymous form,
                            including the number of visitors to the site, where
                            visitors have come to the site from and the pages
                            they visited.
                          </td>
                          <td>
                            <a
                              href="https://www.google.co.uk/intl/en/analytics/privacyoverview.html"
                              rel="noopener noreferrer"
                              target="_blank"
                            >
                              Click here for an overview of privacy at Google
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>Criteo</td>
                          <td>
                            <ul>
                              <li>uid</li>
                              <li>uic</li>
                              <li>udc</li>
                              <li>udi</li>
                              <li>dis</li>
                            </ul>
                          </td>
                          <td>
                            These cookies are used to allow us to produce
                            personalised marketing in an
                            <strong>anonymous</strong> manner on partner
                            websites. You can opt out of Criteo cookies on their
                            <a
                              href="https://www.criteo.com/us/privacy-policy"
                              rel="noopener noreferrer"
                              target="_blank"
                            >
                              privacy policy page
                            </a>
                            .
                          </td>
                          <td>
                            <a
                              href="https://www.criteo.com/us/privacy-policy"
                              rel="noopener noreferrer"
                              target="_blank"
                            >
                              Click here for an overview of privacy at Criteo
                              and/or opt out.
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <p>
                      Most web browsers allow some control of most cookies
                      through the browser settings. To find out more about
                      cookies, including how to see what cookies have been set
                      and how to manage and delete them, visit
                      <a
                        href="https://www.allaboutcookies.org/"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        www.allaboutcookies.org
                      </a>
                      .
                    </p>
                    <p>
                      To opt out of being tracked by Google Analytics across all
                      websites visit
                      <a
                        href="https://tools.google.com/dlpage/gaoptout"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        https://tools.google.com/dlpage/gaoptout
                      </a>
                      .
                    </p>
                  </section>
                  <section>
                    <h3 class="h3">YouTube Cookies</h3>
                    <p>
                      We embed videos from our official YouTube channel using
                      YouTube's privacy-enhanced mode. This mode may set cookies
                      on your computer once you click on the YouTube video
                      player, but YouTube will not store personally-identifiable
                      cookie information for playbacks of embedded videos using
                      the privacy-enhanced mode.
                    </p>
                  </section>
                  <section>
                    <h3 class="h3">Payment Cookies</h3>
                    <p>
                      <em>Stripe</em> and <em>Braintree</em> use cookies to
                      store session details when handling payments. You can read
                      more about their cookie and privacy policies at the
                      following links:
                      <a
                        href="https://stripe.com/gb/privacy"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        Stripe Privacy Policy
                      </a>
                      ,
                      <a
                        href="https://www.paypal.com/uk/webapps/mpp/ua/privacy-full"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        Braintree (PayPal Inc.) Privacy Policy
                      </a>
                      .
                    </p>
                  </section>
                </div>
              </div>
            </article>
          </div>
        </section>
      </main>
    </main>
  );
};

export default Terms;
