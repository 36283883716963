import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  basket: [],
  basketCount: 0,
};

export const BasketSlice = createSlice({
  name: "Basket",
  initialState,
  reducers: {
    addBasket: (state, action) => {
      state.basket = action.payload;
      state.basketCount = action.payload.length;
    },
    addSingleItemToBasket: (state) => {
      state.basketCount = state.basket.length + 1;
    },
    removeItemFromBasket: (state, action) => {
      state.basket = state.basket.filter((item) => item.uid !== action.payload);
      state.basketCount = state.basket.length;
    },
    changeDeliveryDate: (state, action) => {
      state.basket = state.basket.map((item) => {
        if (item.id === action.payload.id) {
          return { ...item, delivery_date: action.payload.date };
        } else {
          return item;
        }
      });
    },
  },
});

export const {
  addBasket,
  removeItemFromBasket,
  addSingleItemToBasket,
  changeDeliveryDate,
} = BasketSlice.actions;

export default BasketSlice.reducer;
