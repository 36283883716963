import React, { useEffect, useState } from "react";
import ImageColorSetting from "./ImageColorSetting";
import ImageFontSizeSetting from "./ImageFontSizeSetting";
import FontFamilySetting from "./FontFamilySetting";
import TextAlignments from "./TextAlignments";
import Emojis from "./Emojis";
import TextEditor from "./TextEditor";

const SelectedCardFromCardModalSettings = ({
  nextPage,
  sendTextEditorTextToParent,
  onUserDataChange,
}) => {
  const [imageSelectedSetting, setImageSelectedSetting] = useState("colour");

  const [selectedBackgroundColor, setSelectedBackgroundColor] =
    useState("rgb(0, 0, 0)");

  const [selectedFontSize, setSelectedFontSize] = useState(22);

  const [selectedFontFamily, setSelectedFontFamily] = useState("inherit");
  const [selectedTextAlignment, setSelectedTextAlignment] = useState("center");
  const [selectedTextAlignmentHorizontal, setSelectedTextAlignmentHorizontal] =
    useState("center");
  const [selectedEmoji, setSelectedEmoji] = useState([]);

  const [textEditorText, setTextEditorText] = useState("");
  const [textEditorError, setTextEditorError] = useState("");

  useEffect(() => {
    sendTextEditorTextToParent(textEditorText);
  }, [textEditorText]);

  const handleSettingClick = (setting) => {
    setImageSelectedSetting(setting);
  };

  const changeSelectedColor = (color) => {
    setSelectedBackgroundColor(color);
  };

  const changeFontSize = (size) => {
    setSelectedFontSize(size);
  };

  const textEditorContent = (val) => {
    setTextEditorText(val);
    setTextEditorError("");
  };

  return (
    <section id="message" class="F8D7tEzAQUW7EsIPEric">
      <section class="sIZnl2NardRZSjksGDDe">
        <div class="PJwEH1ctiC0HjYSynpF_">
          <div class="CtHMVJG578y81hdHwSSi">
            <div class="" aria-hidden="false">
              {/* <div class="options">
                <div class="options-tabs">
                  <div
                    onClick={() => handleSettingClick("colour")}
                    class={`option color  ${
                      imageSelectedSetting === "colour" ? "active" : ""
                    }`}
                  >
                    <div
                      class="colour"
                      role="button"
                      aria-label="Colours"
                      style={{ backgroundColor: selectedBackgroundColor }}
                    ></div>
                  </div>
                  <div
                    onClick={() => handleSettingClick("size")}
                    class={`option fontSize  ${
                      imageSelectedSetting === "size" ? "active" : ""
                    }`}
                  >
                    <div role="button" aria-label="Font Size">
                      22
                    </div>
                  </div>
                  <div
                    onClick={() => handleSettingClick("fontFamily")}
                    class={`option fontFamily  ${
                      imageSelectedSetting === "fontFamily" ? "active" : ""
                    }`}
                  >
                    <div role="button" aria-label="Font Family">
                      Font
                    </div>
                  </div>
                  <div
                    onClick={() => handleSettingClick("alignment")}
                    class={`option textAlign  ${
                      imageSelectedSetting === "alignment" ? "active" : ""
                    }`}
                  >
                    <img
                      class=""
                      src="https://media.bunches.co.uk/icons/alignJustify.svg"
                      aria-label="Alignment"
                      alt="Alignment"
                    />
                  </div>
                  <div
                    onClick={() => handleSettingClick("emoji")}
                    class={`option emoji  ${
                      imageSelectedSetting === "emoji" ? "active" : ""
                    }`}
                  >
                    <div class="emojis" role="button" aria-label="Emojis">
                      😀
                    </div>
                  </div>
                </div>
                <div class="selected-option active">
                  {imageSelectedSetting === "colour" && (
                    <ImageColorSetting
                      changeSelectedColor={changeSelectedColor}
                    />
                  )}
                  {imageSelectedSetting === "size" && (
                    <ImageFontSizeSetting
                      selectedFontSize={selectedFontSize}
                      changeFontSize={changeFontSize}
                    />
                  )}
                  {imageSelectedSetting === "alignment" && (
                    <TextAlignments
                      selectedTextAlignment={selectedTextAlignment}
                      setSelectedTextAlignment={setSelectedTextAlignment}
                      selectedTextAlignmentHorizontal={
                        selectedTextAlignmentHorizontal
                      }
                      setSelectedTextAlignmentHorizontal={
                        setSelectedTextAlignmentHorizontal
                      }
                    />
                  )}
                  {imageSelectedSetting === "emoji" && (
                    <Emojis setSelectedEmoji={setSelectedEmoji} />
                  )}
                  {imageSelectedSetting === "fontFamily" && (
                    <FontFamilySetting
                      setSelectedFontFamily={setSelectedFontFamily}
                      selectedFontFamily={selectedFontFamily}
                    />
                  )}
                </div>
              </div> */}
            </div>
            <div class="ZYbAoLhWSq2WuHdZZjhn" role="dialog">
              <div class="_6PI1kBVGD8G2CRW5mUw" hidden="">
                <h2>Your gift message</h2>
                <button class="bn-button bn-button--primary" role="button">
                  Done
                </button>
              </div>
              <div class="" aria-hidden="false">
                <div class="options">
                  <div class="options-tabs">
                    <div class="option color active">
                      <div
                        class="colour"
                        role="button"
                        aria-label="Colours"
                        style={{ backgroundColor: "rgb(240, 77, 77)" }}
                      ></div>
                    </div>
                    <div class="option fontSize">
                      <div role="button" aria-label="Font Size">
                        22
                      </div>
                    </div>
                    <div class="option fontFamily">
                      <div role="button" aria-label="Font Family">
                        Font
                      </div>
                    </div>
                    <div class="option textAlign">
                      <img
                        class=""
                        src="https://media.bunches.co.uk/icons/alignJustify.svg"
                        aria-label="Alignment"
                        alt="Alignment"
                      />
                    </div>
                    <div class="option emoji">
                      <div class="emojis" role="button" aria-label="Emojis">
                        😀
                      </div>
                    </div>
                  </div>
                  <div class="selected-option active">
                    <div class="row-wrap">
                      <div
                        class="option colour active"
                        role="button"
                        aria-label="Red"
                      >
                        <div
                          class="swatch"
                          style={{ backgroundColor: "rgb(240, 77, 77)" }}
                        ></div>
                      </div>
                      <div
                        class="option colour"
                        role="button"
                        aria-label="Orange"
                      >
                        <div
                          class="swatch"
                          style={{ backgroundColor: "rgb(255, 187, 57)" }}
                        ></div>
                      </div>
                      <div
                        class="option colour"
                        role="button"
                        aria-label="Pink"
                      >
                        <div
                          class="swatch"
                          style={{ backgroundColor: "rgb(255, 157, 187)" }}
                        ></div>
                      </div>
                      <div
                        class="option colour"
                        role="button"
                        aria-label="Purple"
                      >
                        <div
                          class="swatch"
                          style={{ backgroundColor: "rgb(180, 118, 219)" }}
                        ></div>
                      </div>
                      <div
                        class="option colour"
                        role="button"
                        aria-label="Blue"
                      >
                        <div
                          class="swatch"
                          style={{ backgroundColor: "rgb(107, 118, 253)" }}
                        ></div>
                      </div>
                      <div
                        class="option colour"
                        role="button"
                        aria-label="Green"
                      >
                        <div
                          class="swatch"
                          style={{ backgroundColor: "rgb(106, 222, 91)" }}
                        ></div>
                      </div>
                      <div
                        class="option colour"
                        role="button"
                        aria-label="Turquoise"
                      >
                        <div
                          class="swatch"
                          style={{ backgroundColor: "rgb(0, 0, 0)" }}
                        ></div>
                      </div>
                      <div
                        class="option colour"
                        role="button"
                        aria-label="Black"
                      >
                        <div
                          class="swatch"
                          style={{ backgroundColor: "rgb(0, 0, 0)" }}
                        ></div>
                      </div>
                      <div
                        class="option colour"
                        role="button"
                        aria-label="Grey"
                      >
                        <div
                          class="swatch"
                          style={{ backgroundColor: "rgb(147, 147, 147)" }}
                        ></div>
                      </div>
                      <div
                        class="option colour"
                        role="button"
                        aria-label="White"
                      >
                        <div
                          class="swatch"
                          style={{ backgroundColor: "rgb(206, 206, 206)" }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="TuzwPLzoExlWErmMv5NM nWs1LnuN1aRkzFJZ1kdA"
                role="region"
              >
                {/* <div
                  style={{ height: "500px" }}
                  class="UiBbKV_IyNZJDQNgKzHD L1CXiSGhj_mYL2_hPb91"
                >
                  <img
                    alt="Front Cover of Card"
                    src="https://media.bunches.co.uk/products/570x570/k0300.jpg"
                  />
                </div> */}

                <div class="UiBbKV_IyNZJDQNgKzHD">
                  <div class="card-message-inside">
                    <div style={{ height: "500px" }} class="inside-right a5">
                      <TextEditor
                        selectedBackgroundColor={selectedBackgroundColor}
                        selectedFontSize={selectedFontSize}
                        selectedFontFamily={selectedFontFamily}
                        selectedTextAlignment={selectedTextAlignment}
                        selectedTextAlignmentHorizontal={
                          selectedTextAlignmentHorizontal
                        }
                        selectedEmoji={selectedEmoji}
                        textEditorContent={textEditorContent}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {textEditorError !== "" && (
              <div
                style={{
                  color: "red",
                  marginBottom: "20px",
                  textAlign: "center",
                }}
              >
                {textEditorError}
              </div>
            )}
          </div>
          <div
            class="JSiLEIi4jlu92xKCZdyu  E02GN3vPiDwi03VcE_Cc"
            role="alert"
            aria-hidden="true"
          >
            Oops! Please shorten your message.
          </div>
        </div>
        <div class="Pk8Gk7Eh2Z1XWGwOE_tu">
          <div class="j3umMoISiOt4lvqdaC0z ">
            <div class="SizvA696ao7Vyw_j9Usq">
              <i
                class="fa fa-angle-left XscVddje7Lu37HrhD7IB"
                aria-label="Go to Card Message"
              ></i>
            </div>
          </div>
          <div class="aQzYdxZVR09179cdhxDI">
            <div>
              <img
                src="https://media.bunches.co.uk/icons/card-front.svg"
                alt="Closed Card"
                height="44"
                width="44"
              />
            </div>
          </div>
          <div class="j3umMoISiOt4lvqdaC0z wTJVikTbHYZQmYbDvslp">
            <div class="SizvA696ao7Vyw_j9Usq">
              <i
                class="fa fa-angle-right cv8U1Nguxsu3zHRdOErS"
                aria-label="Go to Front Cover"
              ></i>
            </div>
          </div>
        </div>
        <div class="JAEPDEZzwI2TuauRHKyc">
          {/* <button class="EZvyCthqehqElYXVaAH9 YLxdcrrIEpFGSqqBDTXs">
            Change Card
          </button> */}
          <button
            onClick={() => {
              if (textEditorText !== "") {
                nextPage();
              } else {
                setTextEditorError(
                  "Please provide your message for the recipient."
                );
              }
            }}
            class="VRZl5T_cEakrM5LuPOBb YLxdcrrIEpFGSqqBDTXs"
            type="button"
          >
            Continue
          </button>
        </div>
        <div class="yGMHA_DiNq_qHew3dpoE">
          {/* <button class="EZvyCthqehqElYXVaAH9 YLxdcrrIEpFGSqqBDTXs">
            Change Card
          </button> */}
          <button
            onClick={() => {
              if (textEditorText !== "") {
                nextPage();
              } else {
                setTextEditorError(
                  "Please provide your message for the recipient."
                );
              }
            }}
            class="VRZl5T_cEakrM5LuPOBb YLxdcrrIEpFGSqqBDTXs"
            type="button"
          >
            Continue
          </button>
        </div>
      </section>
    </section>
  );
};

export default SelectedCardFromCardModalSettings;
